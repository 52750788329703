import React from "react";

const Breadcrumb = ({ icon, title }) => {
  return (
    <ul className="nav pt-3">
      <li className="nav-item text-primary nav-link ps-1">
        <h4>
          {icon} <strong>{title}</strong>
        </h4>
      </li>
    </ul>
  );
};

export default Breadcrumb;

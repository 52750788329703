import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  InputGroup,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import {
  FaEdit,
  FaHandshake,
  FaSave,
  FaSignOutAlt,
  FaThumbsDown,
  FaThumbsUp,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import Msg from "../../../components/errors/msg";
import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalNotifications,
} from "../../../components/modal";
import zModal from "../../../functions";
import { FormatarCpf } from "../../../functions/cpf";
import { dateToDate, dtToday, toDateBr } from "../../../functions/datetimes";
import FormatMoney from "../../../functions/formatmoney";
import { LeftPad } from "../../../functions/leftPad";
import { BrToDecimal, DecimalToBr, InputBr } from "../../../functions/money";
import { RequestPost } from "../../../requestApi";
import RequestApi from "../../../services";

const ManagementNewAgreementClose = (props) => {
  const [openNot, setOpenNot] = useState(false);
  const [txt, setTxt] = useState(null);
  const [msg, setMsg] = useState(null);
  const [idAcordo, setIdAcordo] = useState(0);
  const [err, setErr] = useState(true);
  const [dados, setDados] = useState({});
  const [changeParcela, setChangeParcela] = useState(false);
  const [idx, setIdx] = useState(0);
  const [vrPr, setVrPr] = useState(0);
  const [dtPr, setDtPr] = useState("");
  const [ds, setDs] = useState(false);
  const [idAc, setIdAc] = useState(0);

  let tf = false;

  const handleClose = () => {
    props.close(false);
  };

  const changeValor = () => {
    dados.Installments[idx].Value = BrToDecimal(vrPr);
    dados.Installments[idx].DtPaydue = dtPr + "T00:00:01";
    let t = (dados.TotalValueCalculatedNegotiated - dados.ValueEntry).toFixed(
      2
    );
    let p = dados?.Installments?.reduce(
      (total, parcela) => total + parcela.Value,
      0
    ).toFixed(2);
    tf = t === p ? false : true;
    setDs(tf);
    setChangeParcela(false);
  };

  const handleCloseParcela = () => {
    setChangeParcela(false);
  };

  const handleNewAcordoStepOne = async () => {
    setMsg(null);
    setOpenNot(true);
    setTxt("Carregando informações do Acordo");
    console.log(props.dados);

    const r = await RequestPost("Agreements/New/StepOne", props.dados);

    if (r.Status === 200) {
      setDados(r.Data);
      setOpenNot(false);
      setErr(false);
    } else {
      setErr(true);
      setMsg(<Msg dados={r.errors} />);
    }
  };

  const handleSaveNewAcordo = async () => {
    setMsg(null);
    setOpenNot(true);
    setTxt("Realizando o Acordo");

    let dts = dados;

    dts = { ...dts, debitos: props.dados.debitos };

    const r = await RequestPost("Agreements/Insert", dts);

    if (r.Status === 200) {
      setIdAc(r.Data);
      let oks = [r.Title, "", "Acordo nº: " + LeftPad(r.Data, 6)];
      setMsg(<Msg dados={oks} />);
      setErr(true);
    } else {
      setMsg(<Msg dados={r.errors} />);
    }
  };

  const handleCloseNot = () => {
    setOpenNot(false);
    if (err) {
      props.setIdAcordo(idAc);
      props.close(false);
    }
  };

  const [tl, setTl] = useState(1060);
  const [t2, setT2] = useState(1090);
  useEffect(() => {
    setTl(zModal());
  }, []);

  useEffect(() => {
    handleNewAcordoStepOne();
  }, []);

  return (
    <>
      <Modal
        show={props.show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="md"
        style={{ zIndex: tl }}
      >
        <ModalHeader>
          <FaHandshake /> Confirma Acordo?
        </ModalHeader>
        <ModalBody>
          {!err && (
            <>
              <Row className="mb-3">
                <Col sm={8} className="border-start">
                  <div className="text-gray-500">Responsável Contratante</div>
                  <div>
                    <strong>{dados.ResponsableName}</strong>
                  </div>
                </Col>
                <Col sm={4} className="border-start">
                  <div className="text-gray-500">CPF</div>
                  <div>
                    <strong>{FormatarCpf(dados.ResponsableId)}</strong>
                  </div>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col sm={4} className="border-start">
                  <div className="text-gray-500">Valor Débito</div>
                  <div>
                    <strong className="float-end">
                      {FormatMoney(dados.TotalValue)}
                    </strong>
                  </div>
                </Col>
                <Col sm={4} className="border-start">
                  <div className="text-gray-500">Valor Déb. Corrigido</div>
                  <div>
                    <strong className="float-end">
                      {FormatMoney(dados.TotalValueCalculated)}
                    </strong>
                  </div>
                </Col>
                <Col sm={4} className="border-start">
                  <div className="text-gray-500">Valor Negociado</div>
                  <div>
                    <strong className="float-end">
                      {FormatMoney(dados.TotalValueCalculatedNegotiated)}
                    </strong>
                  </div>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col sm={3} className="border-start">
                  <div className="text-gray-500">Valor Entrada</div>
                  <div>
                    <strong className="float-end">
                      {FormatMoney(dados.ValueEntry)}
                    </strong>
                  </div>
                </Col>
                <Col sm={3} className="border-start">
                  <div className="text-gray-500">Dt. Entrada</div>
                  <div>
                    <strong className="float-end">
                      {new Date(dados.DtEntry).toLocaleDateString("pt-BR")}
                    </strong>
                  </div>
                </Col>
                <Col sm={3} className="border-start">
                  <div className="text-gray-500">Parcelas</div>
                  <div>
                    <strong className="float-end">
                      {dados.QtyInstallments}
                    </strong>
                  </div>
                </Col>
                <Col sm={3} className="border-start">
                  <div className="text-gray-500">Dt. 1ª Parcela</div>
                  <div>
                    <strong className="float-end">
                      {new Date(dados.DtFirstInstallment).toLocaleDateString(
                        "pt-BR"
                      )}
                    </strong>
                  </div>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col sm={12}>
                  <div style={{ maxHeight: 250, overflowY: "auto" }}>
                    <Table striped hover size="sm">
                      <thead>
                        <tr>
                          {/* <th scope="col" className="ps-2">
                        Descrição
                      </th> */}
                          <th scope="col" className="text-center">
                            Parcela
                          </th>
                          <th scope="col" className="text-center">
                            Dt. Vencimento
                          </th>
                          <th scope="col">Valor</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {dados?.Installments?.map((p, index) => (
                          <tr key={index}>
                            {/* <td className="ps-2">{p.descricao}</td> */}
                            <td className="text-center">{p.Installment}</td>
                            <td className="text-center">
                              {new Date(p.DtPaydue).toLocaleDateString("pt-BR")}
                            </td>
                            <td align="right" className="me-5">
                              {FormatMoney(p.Value)}
                            </td>
                            <td className="text-end">
                              <Link
                                onClick={() => {
                                  setIdx(index);
                                  setVrPr(DecimalToBr(p.Value));
                                  setDtPr(dateToDate(p.DtPaydue));
                                  setT2(zModal());
                                  setChangeParcela(true);
                                }}
                                title="Editar Parcela"
                              >
                                <FaEdit color="#003768" className="mb-1" />
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={6} className="border-start">
                  <div className="text-gray-500">Valor Parcelado</div>
                  <div>
                    <strong className="float-end">
                      {FormatMoney(
                        dados.TotalValueCalculatedNegotiated - dados.ValueEntry
                      )}
                    </strong>
                  </div>
                </Col>
                <Col sm={6} className="border-start">
                  <div className="text-gray-500">Valor Total Parcelas</div>
                  <div>
                    <strong className="float-end">
                      {FormatMoney(
                        dados?.Installments?.reduce(
                          (total, parcela) => total + parcela.Value,
                          0
                        )
                      )}
                    </strong>
                  </div>
                </Col>
              </Row>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            variant="secondary"
            size="sm"
            onClick={handleClose}
            title="Cancelar"
          >
            <FaSignOutAlt className="mb-1" /> Cancelar
          </Button>
          <Button
            variant="primary"
            size="sm"
            onClick={handleSaveNewAcordo}
            title="Gravar Acordo"
            disabled={ds}
          >
            <FaSave className="mb-1" /> Gravar Acordo
          </Button>
        </ModalFooter>
      </Modal>
      {openNot ? (
        <ModalNotifications
          show={openNot}
          close={handleCloseNot}
          texto={txt}
          message={msg}
        />
      ) : null}
      {changeParcela && (
        <Modal
          show={props.show}
          onHide={handleCloseParcela}
          backdrop="static"
          keyboard={false}
          size="md"
          style={{ zIndex: t2 }}
        >
          <ModalHeader>
            <FaEdit /> Alterar Parcela
          </ModalHeader>
          <ModalBody>
            <Row className="mb-0">
              <Col sm={2}>
                <div className="text-gray-500">Parcela</div>
                <div className="text-center mt-1">
                  <strong>{idx + 1}</strong>
                </div>
              </Col>
              <Col sm={5}>
                <Form.Group controlId="dtVencimentoParcela">
                  <Form.Label className="mb-0">Dt. Vencimento</Form.Label>
                  <Form.Control
                    type="date"
                    min={dtToday()}
                    size="sm"
                    onChange={(e) => setDtPr(e.target.value)}
                    value={dtPr}
                  />
                </Form.Group>
              </Col>
              <Col sm={5}>
                <Form.Group controlId="descontoValor">
                  <Form.Label className="mb-0">Valor</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="money"
                      size="sm"
                      style={{ width: "100px" }}
                      className="text-end"
                      onChange={(e) => {
                        setVrPr(InputBr(e.target.value));
                      }}
                      value={vrPr}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button variant="secondary" size="sm" onClick={handleCloseParcela}>
              <FaSignOutAlt className="mb-1" /> Cancelar
            </Button>
            <Button variant="primary" size="sm" onClick={changeValor}>
              <FaSave className="mb-1" /> Salvar
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default ManagementNewAgreementClose;

import React, { useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { FaCheck } from "react-icons/fa";

import zModal from "../../functions";

const Jmodal = (props) => {
  const [tl, setTl] = useState(0);

  useEffect(() => {
    setTl(zModal());
  }, []);

  return (
    <>
      <Modal
        show={props.show}
        onHide={() => props.closeModal()}
        backdrop="static"
        keyboard={false}
        size="md"
        style={{ zIndex: tl }}
      >
        <Modal.Header
          className="pt-2 ps-4 pb-0 pe-4"
          closeButton={props.message === null ? false : true}
        >
          <Modal.Title className="h6">GBY&nbsp;</Modal.Title>
        </Modal.Header>
        <Modal.Body className="ps-4 pt-2 pe-4">
          {props.message === null ? (
            <>
              <Spinner
                animation="border"
                size="sm"
                className="float-start mt-2"
              />
              &nbsp;&nbsp;
              {props.texto === null ? "Enviando dados..." : props.texto}
            </>
          ) : (
            <>{props.message}</>
          )}
        </Modal.Body>
        <Modal.Footer className="ps-3 pb-1 pt-1">
          {props.message !== null && (
            <Button
              variant="primary"
              size="sm"
              onClick={() => props.closeModal()}
            >
              <FaCheck /> Ok
            </Button>
          )}
          &nbsp;
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default Jmodal;

const toDateBr = (v) => {
    //Datas no formato
    //yyyy-mm-ddThh:mm:ss
    //yyyy-mm-dd
    return new Date(v.length > 10 ? v : v + "T00:00:01").toLocaleDateString("pt-BR", { timeZone: 'America/Sao_Paulo' })
}

const dateToDate = (v) => {
    //Datas no formato
    //yyyy-mm-ddThh:mm:ss
    //para yyyy-mm-dd
    let dt = new Date(v);
    return dt.getFullYear() + "-" + (dt.getMonth() + 1 < 10 ? "0" + (dt.getMonth() + 1) : dt.getMonth() + 1) + "-" + (dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate())
}

const toDateTimeBr = (v) => {
    //Datas no formato
    //yyyy-mm-ddThh:mm:ss
    //yyyy-mm-dd
    return new Date(v.length > 10 ? v : v + "T00:00:01").toLocaleDateString("pt-BR", { timeZone: 'America/Sao_Paulo' }) + " " + new Date(v.length > 10 ? v : v + "T00:00:01").toLocaleTimeString("pt-BR", { timeZone: 'America/Sao_Paulo' })
}

const dtToday = () => {
    let dt = new Date();
    let hj =
        dt.getFullYear() +
        "-" +
        (dt.getMonth() + 1 < 10 ? "0" + (dt.getMonth() + 1) : dt.getMonth() + 1) +
        "-" +
        (dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate());
    return hj;
};

export { toDateBr, toDateTimeBr, dtToday, dateToDate }
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaArrowAltCircleRight, FaLessThanEqual } from "react-icons/fa";
import RequestApi from "../../services";

import "./cards.css";
import { Row, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { RequestGet } from "../../requestApi";

const Spin = () => {
  return <Spinner animation="border" role="status" />;
};

const Cards = () => {
  const [loading, setLoading] = useState(true);
  const [totalNegociado, setTotalNegociado] = useState(0);
  const [totalReceber, setTotalReceber] = useState(0);
  const [totalRecebido, setTotalRecebido] = useState(0);
  const [totalAtrasado, setTotalAtrasado] = useState(0);
  const [totalDebitos, setTotalDebitos] = useState(0);

  async function getDados() {
    setLoading(true);
    const r = await RequestGet("/Dashboard");

    if (r.status === 1) {
      toast.success(r.message);
      let d = r.data;
      setTotalNegociado(d.totalNegociado);
      setTotalReceber(d.totalReceber);
      setTotalRecebido(d.totalRecebido);
      setTotalAtrasado(d.totalAtrasado);
      setTotalDebitos(d.totalDebitos);
      setLoading(false);
    } else {
      setTotalNegociado(0);
      setTotalReceber(0);
      setTotalRecebido(0);
      setTotalAtrasado(0);
    }
  }

  useEffect(() => {
    getDados();
  }, []);

  return (
    <>
      <Row>
        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
          <div className="card shadow bg-light">
            <div className="card-body pb-0">
              <h6 className="card-title text-secondary pt-0 mt-0 mb-0 pb-0">
                <strong>Total Negociado</strong>
              </h6>
              <span className="card-text fs-3 float-end">
                {loading ? (
                  <Spinner />
                ) : (
                  new Intl.NumberFormat("pt-BR", {
                    minimumFractionDigits: 2,
                    currency: "BRL",
                  }).format(totalNegociado)
                )}
              </span>
            </div>
            <div className="card-footer text-center bg-light bg-opacity-75">
              <Link
                to="/Gestao/Acordos"
                title="Acordos"
                className="text-primary"
              >
                Visualizar{" "}
                <FaArrowAltCircleRight size={18} className="text-primary" />
              </Link>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
          <div className="card shadow bg-light">
            <div className="card-body pb-0">
              <h6 className="card-title text-secondary pt-0 mt-0 mb-0 pb-0">
                <strong>Total a Receber</strong>
              </h6>
              <span className="card-text fs-3 float-end">
                {loading ? (
                  <Spinner />
                ) : (
                  new Intl.NumberFormat("pt-BR", {
                    minimumFractionDigits: 2,
                    currency: "BRL",
                  }).format(totalReceber)
                )}
              </span>
            </div>
            <div className="card-footer text-center bg-light bg-opacity-75">
              <Link
                to="/Gestao/Acordos"
                title="Acordos"
                className="text-primary"
              >
                Visualizar{" "}
                <FaArrowAltCircleRight size={18} className="text-primary" />
              </Link>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
          <div className="card shadow bg-light">
            <div className="card-body pb-0">
              <h6 className="card-title text-secondary pt-0 mt-0 mb-0 pb-0">
                <strong>Total Recebido</strong>
              </h6>
              <span className="card-text fs-3 float-end">
                {loading ? (
                  <Spinner />
                ) : (
                  new Intl.NumberFormat("pt-BR", {
                    minimumFractionDigits: 2,
                    currency: "BRL",
                  }).format(totalRecebido)
                )}
              </span>
            </div>
            <div className="card-footer text-center bg-light bg-opacity-75">
              <Link
                to="/Gestao/Acordos"
                title="Acordos"
                className="text-primary"
              >
                Visualizar{" "}
                <FaArrowAltCircleRight size={18} className="text-primary" />
              </Link>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
          <div className="card shadow bg-light">
            <div className="card-body pb-0">
              <h6 className="card-title text-secondary pt-0 mt-0 mb-0 pb-0">
                <strong>Total em Atraso</strong>
              </h6>
              <span className="card-text fs-3 float-end">
                {loading ? (
                  <Spinner />
                ) : (
                  new Intl.NumberFormat("pt-BR", {
                    minimumFractionDigits: 2,
                    currency: "BRL",
                  }).format(totalAtrasado)
                )}
              </span>
            </div>
            <div className="card-footer text-center bg-light bg-opacity-75">
              <Link
                to="/Gestao/Acordos"
                title="Acordos"
                className="text-primary"
              >
                Visualizar{" "}
                <FaArrowAltCircleRight size={18} className="text-primary" />
              </Link>
            </div>
          </div>
        </div>
      </Row>
      <Row>
        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
          <div className="card shadow bg-light">
            <div className="card-body pb-0">
              <h6 className="card-title text-secondary pt-0 mt-0 mb-0 pb-0">
                <strong>Total Débitos</strong>
              </h6>
              <span className="card-text fs-3 float-end">
                {loading ? (
                  <Spinner />
                ) : (
                  new Intl.NumberFormat("pt-BR", {
                    minimumFractionDigits: 2,
                    currency: "BRL",
                  }).format(totalDebitos)
                )}
              </span>
            </div>
            <div className="card-footer text-center bg-light bg-opacity-75">
              <Link
                to="/Gestao/Acordos"
                title="Acordos"
                className="text-primary"
              >
                Visualizar{" "}
                <FaArrowAltCircleRight size={18} className="text-primary" />
              </Link>
            </div>
          </div>
        </div>
        {/* <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
        <div className="card shadow bg-light">
          <div className="card-body pb-0">
            <h6 className="card-title text-secondary pt-0 mt-0 mb-0 pb-0">
              <strong>Total a Receber</strong>
            </h6>
            <span className="card-text fs-3 float-end">
              {loading ? (
                <Spinner />
              ) : (
                new Intl.NumberFormat("pt-BR", {
                  minimumFractionDigits: 2,
                  currency: "BRL",
                }).format(totalReceber)
              )}
            </span>
          </div>
          <div className="card-footer text-center bg-light bg-opacity-75">
            <Link to="/Gestao/Acordos" title="Acordos" className="text-primary">
              Visualizar{" "}
              <FaArrowAltCircleRight size={18} className="text-primary" />
            </Link>
          </div>
        </div>
      </div>
      <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
        <div className="card shadow bg-light">
          <div className="card-body pb-0">
            <h6 className="card-title text-secondary pt-0 mt-0 mb-0 pb-0">
              <strong>Total Recebido</strong>
            </h6>
            <span className="card-text fs-3 float-end">
              {loading ? (
                <Spinner />
              ) : (
                new Intl.NumberFormat("pt-BR", {
                  minimumFractionDigits: 2,
                  currency: "BRL",
                }).format(totalRecebido)
              )}
            </span>
          </div>
          <div className="card-footer text-center bg-light bg-opacity-75">
            <Link to="/Gestao/Acordos" title="Acordos" className="text-primary">
              Visualizar{" "}
              <FaArrowAltCircleRight size={18} className="text-primary" />
            </Link>
          </div>
        </div>
      </div>
      <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
        <div className="card shadow bg-light">
          <div className="card-body pb-0">
            <h6 className="card-title text-secondary pt-0 mt-0 mb-0 pb-0">
              <strong>Total em Atraso</strong>
            </h6>
            <span className="card-text fs-3 float-end">
              {loading ? (
                <Spinner />
              ) : (
                new Intl.NumberFormat("pt-BR", {
                  minimumFractionDigits: 2,
                  currency: "BRL",
                }).format(totalAtrasado)
              )}
            </span>
          </div>
          <div className="card-footer text-center bg-light bg-opacity-75">
            <Link to="/Gestao/Acordos" title="Acordos" className="text-primary">
              Visualizar{" "}
              <FaArrowAltCircleRight size={18} className="text-primary" />
            </Link>
          </div>
        </div>
      </div> */}
      </Row>
    </>
  );
};

export default Cards;

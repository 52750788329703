import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Form,
  InputGroup,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { FaEye, FaMoneyBillWave, FaSave, FaSignOutAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Msg from "../../../components/errors/msg";
import {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalNotifications,
} from "../../../components/modal";
import zModal from "../../../functions";
import FormatMoney from "../../../functions/formatmoney";
import { BrToDecimal, DecimalToBr, InputBr } from "../../../functions/money";
import { RequestGet } from "../../../requestApi";
import RequestApi from "../../../services";
import NewEdit from "../../responsables/newedit";
import ManagementNewAgreementClose from "./newAgreementClose";
import { dateToDate, toDateBr } from "../../../functions/datetimes";

const today = () => {
  let dt = new Date();
  let hj =
    dt.getFullYear() +
    "-" +
    (dt.getMonth() + 1 < 10 ? "0" + (dt.getMonth() + 1) : dt.getMonth() + 1) +
    "-" +
    (dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate());
  return hj;
};

let dValue = 1;
let dFine = 1;
let dInterest = 1;
let dEntry = 1;

let dataAgreement = {};

const ManagementNewAgreement = (props) => {
  const [newResponsavel, setNewResponsavel] = useState(false);
  const [responsableId, setResponsableId] = useState(props.responsableId);
  const [newEdit, setNewEdit] = useState(false);
  const [openNot, setOpenNot] = useState(false);
  const [txt, setTxt] = useState(null);
  const [msg, setMsg] = useState(null);
  const [error, setError] = useState(false);
  const [debts, setDebts] = useState([]);
  const [responsableName, setResponsableName] = useState("");
  const [parameters, setParameters] = useState({});
  const [dados, setDados] = useState({});
  const [closeAcordo, setCloseAcordo] = useState(false);
  const [calc, setCalc] = useState(false);
  const [resp, setResp] = useState(false);
  const [managers, setManagers] = useState([]);

  //#region Campos
  const [descontoValor, setDescontoValor] = useState(FormatMoney(0.0));
  const [descontoMulta, setDescontoMulta] = useState(FormatMoney(0.0));
  const [descontoJuros, setDescontoJuros] = useState(FormatMoney(0.0));
  const [totalValueCalculated, setTotalValueCalculated] = useState(
    FormatMoney(0.0)
  );
  const [totalToInstallment, setTotalToInstallment] = useState(
    FormatMoney(0.0)
  );
  const [valueEntry, setValueEntry] = useState(FormatMoney(0.0));
  const [dtEntry, setDtEntry] = useState(today());
  const [dtFirstInstallment, setDtFirstInstallment] = useState(today());
  const [value, setValue] = useState(FormatMoney(0.0));
  const [valueFine, setValueFine] = useState(FormatMoney(0.0));
  const [valueInterest, setValueInterest] = useState(FormatMoney(0.0));
  const [totalValueEntry, setTotalValueEntry] = useState(FormatMoney(0.0));
  const [installments, setInstallments] = useState([
    { par: `1x - ${totalValueCalculated}` },
  ]);
  const [installment, setInstallment] = useState("");
  const [tpDescontoValor, setTpDescontoValor] = useState(1);
  const [tpDescontoMulta, setTpDescontoMulta] = useState(1);
  const [tpDescontoJuros, setTpDescontoJuros] = useState(1);
  const [tpValorEntrada, setTpValorEntrada] = useState(1);
  const [managerId, setManagerId] = useState(0);
  const [agreementId, setAgreementId] = useState(0);
  //#endregion

  const closeModal = () => props.close(false);

  const handleCloseNot = () => {
    if (error) closeModal();

    setOpenNot(false);
  };

  const loadDados = async () => {
    setOpenNot(true);
    setTxt("Carregando dados da Negociação");
    setError(false);

    let debs = [];

    const r = await RequestGet(
      "/Responsables/Debts/ToNegociate/" + props.responsableId
    );

    if (r.Status === 200) {
      setResponsableName(r.Data.ResponsableName);
      setParameters(r.Data.Parameters);
      setManagerId(r.Data.Parameters.ManagerId);
      setDebts(r.Data.Debts);
      setManagers(r.Data.Managers);
      dataAgreement.responsableId = props.responsableId;
      r.Data.Debts.map((d) => debs.push({ StudentDebtId: d.StudentDebtId }));
      setValue(r.Data.Debts.reduce((total, debt) => total + debt.Value, 0));
      setValueFine(r.Data.Debts.reduce((total, debt) => total + debt.Fine, 0));
      setValueInterest(
        r.Data.Debts.reduce((total, debt) => total + debt.Interest, 0)
      );
      setTotalValueCalculated(
        r.Data.Debts.reduce((total, debt) => total + debt.ValueCalculated, 0)
      );
      setTotalToInstallment(
        r.Data.Debts.reduce((total, debt) => total + debt.ValueCalculated, 0)
      );
      calcularParcelas(
        r.Data.Debts.reduce((total, debt) => total + debt.ValueCalculated, 0),
        r.Data.Parameters.MaxInstallment,
        r.Data.Parameters.MinValueInstallment
      );
      dataAgreement.totalValue = r.Data.Debts.reduce(
        (total, debt) => total + debt.Value,
        0
      );
      dataAgreement.totalFine = r.Data.Debts.reduce(
        (total, debt) => total + debt.Fine,
        0
      );
      dataAgreement.totalInterest = r.Data.Debts.reduce(
        (total, debito) => total + debito.Interest,
        0
      );
      dataAgreement.totalValueCalculated = r.Data.Debts.reduce(
        (total, debito) => total + debito.ValueCalculated,
        0
      );
      dataAgreement.totalValueNegotiated = 0.0;
      dataAgreement.totalFineNegotiated = 0.0;
      dataAgreement.totalInterestNegotiated = 0.0;
      dataAgreement.totalValueCalculatedNegotiated = 0.0;
      dataAgreement.ValueEntry = 0.0;
      //dataAgreement.dtEntry = r.Data.Parameters.diaEntrada;
      //dataAgreement.dt1Parcela = r.data.parametros.diaPrimeiraParcela;

      dataAgreement.debts = debs;
      dValue = 1;
      dFine = 1;
      dInterest = 1;
      dEntry = 1;
      setCalc(true);
      setDtEntry(dateToDate(r.Data.Parameters.DtEntry));
      setDtFirstInstallment(dateToDate(r.Data.Parameters.DtFirstInstallment));
      setTotalValueEntry(0.0);
      toast.success(r.Title);
      setOpenNot(false);
    } else {
      setError(true);
      setMsg(<Msg dados={r.errors} />);
    }
  };

  const handleNewResponsavel = (id) => {
    if (id !== "n" && id.length === 11) {
      setNewEdit(false);
      setResponsableId(id);
    } else {
      setNewResponsavel(false);
    }
    setResp(true);
  };

  const calcularParcelas = (tc, p = 0, v = 0) => {
    let prcs = [];

    let qtdep = p === 0 ? parameters.MaxInstallment : p;
    let vm = v === 0 ? parameters.MinValueInstallment : v;

    if (tc < vm) {
      setCalc(false);
      prcs.push({ par: "0x - 0,00" });
      toast.error(
        "O valor Total a Parcelar é menor que o valor minimo de parcela"
      );
    } else {
      for (var i = 1; i <= qtdep; i++) {
        let res = tc / i;
        if (res < vm) break;
        prcs.push({ par: i + "x - " + FormatMoney(res) });
        //setCalc(true);
      }
    }
    setInstallment(prcs[0].par);
    setInstallments(prcs);
  };

  const calculaDebito = () => {
    setCalc(true);
    let tv = debts.reduce((total, debt) => total + debt.Value, 0);
    let tm = debts.reduce((total, debt) => total + debt.Fine, 0);
    let tj = debts.reduce((total, debt) => total + debt.Interest, 0);
    let tt = tv + tm + tj;

    let dv = BrToDecimal(descontoValor);
    let dm = BrToDecimal(descontoMulta);
    let dj = BrToDecimal(descontoJuros);
    let ve = BrToDecimal(valueEntry);

    if (parseInt(dValue) === 0) dv = (tv * dv) / 100;
    if (parseInt(dFine) === 0) dm = (tm * dm) / 100;
    if (parseInt(dInterest) === 0) dj = (tj * dj) / 100;

    let ntt = tt - dv - dm - dj;

    let ttv = tv - dv;
    ttv = DecimalToBr(ttv) === "-0,00" ? 0.0 : ttv;

    let ttm = tm - dm;
    ttm = DecimalToBr(ttm) === "-0,00" ? 0.0 : ttm;

    let ttj = tj - dj;
    ttj = DecimalToBr(ttj) === "-0,00" ? 0.0 : ttj;

    setValue(ttv);
    setValueFine(ttm);
    setValueInterest(ttj);
    let ttp = ntt;
    ttp = DecimalToBr(ttp) === "-0,00" ? 0.0 : ttp;

    ntt = DecimalToBr(ntt) === "-0,00" ? 0.0 : ntt;

    if (parseInt(dEntry) === 0) ve = (ntt * ve) / 100;

    setTotalValueEntry(ve);

    setTotalValueCalculated(ntt);

    ttp = ntt - ve;

    setTotalToInstallment(ttp);

    calcularParcelas(ttp);

    dataAgreement.totalValueCalculated = debts.reduce(
      (total, debt) => total + debt.ValueCalculated,
      0
    );

    if (ttp <= 0) {
      setCalc(false);
      toast.error("Valor inválido para negociação");
    }

    if (ttv < 0) {
      setCalc(false);
      toast.error("Total Valor inválido");
    }
    if (ttm < 0) {
      setCalc(false);
      toast.error("Total Multa inválido");
    }
    if (ttj < 0) {
      setCalc(false);
      toast.error("Total Juros inválido");
    }
  };

  const saveAgreement = () => {
    let de = new Date(dtEntry + " 00:00:01").toLocaleDateString("pt-BR");
    let d1 = new Date(dtEntry + " 00:00:01").toLocaleDateString("pt-BR");

    if (de === "Invalid Date") {
      toast.error("Data de Entrada inválida");
      setCalc(false);
      return;
    }
    if (d1 === "Invalid Date") {
      toast.error("Data da 1ª Parcela inválida");
      setCalc(false);
      return;
    }

    dataAgreement.ResponsableId = props.responsableId;
    dataAgreement.totalValorNegociado = value;
    dataAgreement.totalMultaNegociado = valueFine;
    dataAgreement.totalJurosNegociado = valueInterest;
    dataAgreement.TotalValueCalculatedNegotiated = totalValueCalculated;
    dataAgreement.ValueEntry = totalValueEntry;
    dataAgreement.DtEntry = dtEntry;
    dataAgreement.DtFirstInstallment = dtFirstInstallment;
    dataAgreement.Installments = installment;
    dataAgreement.ManagerId = managerId;

    setCloseAcordo(true);
  };

  useEffect(() => {
    loadDados();
  }, [props.show]);

  useEffect(() => {
    if (agreementId > 0) {
      props.list();
      closeModal();
    }
  }, [agreementId]);

  const [tl, setTl] = useState(1060);
  useEffect(() => {
    setTl(zModal());
  }, []);
  return (
    <>
      <Modal
        show={props.show}
        onHide={closeModal}
        backdrop="static"
        keyboard={false}
        size="lg"
        style={{ zIndex: tl }}
      >
        <ModalHeader>
          <FaMoneyBillWave /> Negociar Débitos
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm={11} className="border-start">
              <div className="text-gray-500">Responsável Contratante</div>
              <div>
                <strong>{responsableName}</strong>
              </div>
            </Col>
            <Col sm={1}>
              <div className="text-gray-500">&nbsp;</div>
              <div>
                <Link
                  onClick={() => {
                    setResponsableId(props.responsableId);
                    setNewResponsavel(true);
                    setNewEdit(false);
                  }}
                  className="float-end"
                >
                  <FaEye color="#003768" />
                </Link>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12} className="border-start">
              <div className="text-gray-500">Débitos</div>
              <div>
                <Col
                  sm={12}
                  style={{
                    height: "287px",
                    overflowX: "hidden",
                    overflowY: "auto",
                    backgroundColor: "rgba(0, 0, 0, 0.02)",
                  }}
                >
                  <Table striped hover size="sm" className="mb-0">
                    <thead>
                      <tr>
                        <th className="border-top-0 pt-0 pb-0 text-center">
                          Col.
                        </th>
                        <th className="border-top-0 pt-0 pb-0 text-center">
                          Aluno
                        </th>
                        <th className="border-top-0 pt-0 pb-0">Nome</th>
                        <th className="border-top-0 pt-0 pb-0">Descrição</th>
                        <th className="border-top-0 pt-0 pb-0 text-center">
                          Par.
                        </th>
                        <th className="border-top-0 pt-0 pb-0 text-center">
                          Vencimento
                        </th>
                        <th className="border-top-0 pt-0 pb-0 text-center">
                          Dias
                        </th>
                        <th className="border-top-0 pt-0 pb-0 text-end">
                          Valor
                        </th>
                        <th className="border-top-0 pt-0 pb-0 text-end">
                          Multa
                        </th>
                        <th className="border-top-0 pt-0 pb-0 text-end">
                          Juros
                        </th>
                        <th className="border-top-0 pt-0 pb-0 text-end">
                          Corrigido
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {debts.map((d, index) => (
                        <tr key={index}>
                          <td className="p-0 mb-1 text-center">
                            {d.SchoolCode}
                          </td>
                          <td className="p-0 mb-1 text-center">
                            {d.StudentCode}
                          </td>
                          <td className="p-0 mb-1 text-start">
                            {d.StudentName}
                          </td>
                          <td className="p-0 mb-1 text-start">
                            {d.TypeDebtName}
                          </td>
                          <td className="p-0 mb-1 text-center">
                            {d.Installment}
                          </td>
                          <td className="p-0 mb-1 text-center">
                            {new Date(d.DtPaydue).toLocaleDateString("pt-BR")}
                          </td>
                          <td className="p-0 mb-1 text-center">{d.Days}</td>
                          <td className="p-0 mb-1 text-end">
                            {FormatMoney(d.Value)}
                          </td>
                          <td className="p-0 mb-1 text-end">
                            {FormatMoney(d.Fine)}
                          </td>
                          <td className="p-0 mb-1 text-end">
                            {FormatMoney(d.Interest)}
                          </td>
                          <td className="p-0 mb-1 text-end">
                            {FormatMoney(d.ValueCalculated)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={2} className="border-start">
              <div className="text-gray-500">Total Valor</div>
              <div className="float-end">
                {FormatMoney(
                  debts.reduce((total, debt) => total + debt.Value, 0)
                )}
              </div>
            </Col>
            <Col sm={2} className="border-start">
              <div className="text-gray-500">Total Multa</div>
              <div className="float-end">
                {FormatMoney(
                  debts.reduce((total, debt) => total + debt.Fine, 0)
                )}
              </div>
            </Col>
            <Col sm={2} className="border-start">
              <div className="text-gray-500">Total Juros</div>
              <div className="float-end">
                {FormatMoney(
                  debts.reduce((total, debt) => total + debt.Interest, 0)
                )}
              </div>
            </Col>
            <Col sm={2} className="border-start">
              <div className="text-gray-500">Total Corrigido</div>
              <div className="float-end">
                {FormatMoney(
                  debts.reduce((total, debt) => total + debt.ValueCalculated, 0)
                )}
              </div>
            </Col>
          </Row>
          <Row className="pb-3 border">
            <Col sm={3}>
              <Form.Group controlId="descontoValor">
                <Form.Label className="mb-0">Desconto Valor</Form.Label>
                <InputGroup>
                  <Form.Select
                    size="sm"
                    onChange={(e) => {
                      dValue = e.target.value;
                      setTpDescontoValor(e.target.value);
                      calculaDebito();
                    }}
                    value={tpDescontoValor}
                  >
                    <option value={1} className="text-muted">
                      $
                    </option>
                    <option value={0} className="text-muted">
                      %
                    </option>
                  </Form.Select>
                  <Form.Control
                    type="money"
                    size="sm"
                    style={{ width: "100px" }}
                    onChange={(e) => {
                      setDescontoValor(InputBr(e.target.value));
                    }}
                    onKeyUp={calculaDebito}
                    value={descontoValor}
                    className="text-end"
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col sm={3}>
              <Form.Group controlId="descontoMulta">
                <Form.Label className="mb-0">Desconto Multa</Form.Label>
                <InputGroup>
                  <Form.Select
                    size="sm"
                    onChange={(e) => {
                      dFine = e.target.value;
                      setTpDescontoMulta(e.target.value);
                      calculaDebito();
                    }}
                    value={tpDescontoMulta}
                    //onClick={calculaDebito}
                  >
                    <option value={1} className="text-muted">
                      $
                    </option>
                    <option value={0} className="text-muted">
                      %
                    </option>
                  </Form.Select>
                  <Form.Control
                    type="text"
                    size="sm"
                    style={{ width: "100px" }}
                    onChange={(e) => {
                      setDescontoMulta(InputBr(e.target.value));
                    }}
                    onKeyUp={calculaDebito}
                    value={descontoMulta}
                    className="text-end"
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col sm={3}>
              <Form.Group controlId="descontoJuros">
                <Form.Label className="mb-0">Desconto Juros</Form.Label>
                <InputGroup>
                  <Form.Select
                    size="sm"
                    onChange={(e) => {
                      dInterest = e.target.value;
                      setTpDescontoJuros(e.target.value);
                      calculaDebito();
                    }}
                    value={tpDescontoJuros}
                    //onClick={calculaDebito}
                  >
                    <option value={1} className="text-muted">
                      $
                    </option>
                    <option value={0} className="text-muted">
                      %
                    </option>
                  </Form.Select>
                  <Form.Control
                    type="text"
                    size="sm"
                    style={{ width: "100px" }}
                    onChange={(e) => {
                      setDescontoJuros(InputBr(e.target.value));
                    }}
                    onKeyUp={calculaDebito}
                    value={descontoJuros}
                    className="text-end"
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col sm={3}>
              <Form.Group controlId="descontoJuros">
                <Form.Label className="mb-0">Valor de Entrada</Form.Label>
                <InputGroup>
                  <Form.Select
                    size="sm"
                    onChange={(e) => {
                      dEntry = e.target.value;
                      setTpValorEntrada(e.target.value);
                      calculaDebito();
                    }}
                    value={tpValorEntrada}
                    //onClick={calculaDebito}
                  >
                    <option value={1} className="text-muted">
                      $
                    </option>
                    <option value={0} className="text-muted">
                      %
                    </option>
                  </Form.Select>
                  <Form.Control
                    type="text"
                    size="sm"
                    style={{ width: "100px" }}
                    onChange={(e) => {
                      setValueEntry(InputBr(e.target.value));
                    }}
                    onKeyUp={calculaDebito}
                    value={valueEntry}
                    className="text-end"
                  />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={2} className="border-start">
              <div className="text-gray-500">Total Valor</div>
              <div className="float-end">{FormatMoney(value)}</div>
            </Col>
            <Col sm={2} className="border-start">
              <div className="text-gray-500">Total Multa</div>
              <div className="float-end">{FormatMoney(valueFine)}</div>
            </Col>
            <Col sm={2} className="border-start">
              <div className="text-gray-500">Total Juros</div>
              <div className="float-end">{FormatMoney(valueInterest)}</div>
            </Col>
            <Col sm={2} className="border-start">
              <div className="text-gray-500">Total Corrigido</div>
              <div className="float-end">
                {FormatMoney(totalValueCalculated)}
              </div>
            </Col>
            <Col sm={2} className="border-start">
              <div className="text-gray-500">Valor de Entrada</div>
              <div className="float-end">{FormatMoney(totalValueEntry)}</div>
            </Col>
            <Col sm={2} className="border-start">
              <div className="text-gray-500">Total a Parcelar</div>
              <div className="float-end">
                <strong>{FormatMoney(totalToInstallment)}</strong>
              </div>
            </Col>
          </Row>
          <Row className="border pb-2 mb-0">
            <Col sm={2} className="col-6">
              <Form.Group controlId="dtEntrada">
                <Form.Label className="mb-0">Dt. Entrada</Form.Label>
                <Form.Control
                  type="date"
                  min={today()}
                  size="sm"
                  onChange={(e) => setDtEntry(e.target.value)}
                  value={dtEntry}
                />
              </Form.Group>
            </Col>
            <Col sm={2} className="col-6">
              <Form.Group controlId="dt1Parcela">
                <Form.Label className="mb-0">Dt. 1ª Parcela</Form.Label>
                <Form.Control
                  type="date"
                  min={today()}
                  size="sm"
                  onChange={(e) => setDtFirstInstallment(e.target.value)}
                  value={dtFirstInstallment}
                />
              </Form.Group>
            </Col>
            <Col sm={3}>
              <Form.Group controlId="parcelas">
                <Form.Label className="mb-0">Parcelas</Form.Label>
                <InputGroup size="sm">
                  <Form.Select
                    size="sm"
                    onChange={(e) => setInstallment(e.target.value)}
                    value={installment}
                  >
                    {installments.map((p, index) => (
                      <option key={index} value={p.par}>
                        {p.par}
                      </option>
                    ))}
                  </Form.Select>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col sm={5}>
              <div className="float-end">
                <Form.Group controlId="parcelas">
                  <Form.Label className="mb-0">Gestora</Form.Label>
                  <InputGroup size="sm">
                    <Form.Select
                      size="sm"
                      onChange={(e) => setManagerId(e.target.value)}
                      value={managerId}
                    >
                      <option value={0} disabled>
                        Selecione uma Gestora
                      </option>
                      {managers.map((p, index) => (
                        <option key={index} value={p.ManagerId}>
                          {p.Acronym}
                        </option>
                      ))}
                    </Form.Select>
                  </InputGroup>
                </Form.Group>
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button variant="secondary" size="sm" onClick={closeModal}>
            <FaSignOutAlt className="mb-1" /> Sair
          </Button>
          <Button
            variant="primary"
            size="sm"
            onClick={saveAgreement}
            disabled={calc && resp ? false : true}
          >
            <FaSave className="mb-1" /> Realizar Acordo
          </Button>
        </ModalFooter>
      </Modal>
      {openNot ? (
        <ModalNotifications
          show={openNot}
          close={handleCloseNot}
          texto={txt}
          message={msg}
        />
      ) : null}
      {newResponsavel ? (
        <NewEdit
          show={newResponsavel}
          new={newEdit}
          responsableId={responsableId}
          handleNewResponsavel={handleNewResponsavel}
          responsavel={setResponsableName}
        />
      ) : null}
      {closeAcordo ? (
        <ManagementNewAgreementClose
          show={closeAcordo}
          close={setCloseAcordo}
          dados={dataAgreement}
          setIdAcordo={setAgreementId}
        />
      ) : null}
    </>
  );
};

export default ManagementNewAgreement;

import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import { Prev } from "react-bootstrap/esm/PageItem";
import { FaEdit, FaHandHoldingUsd, FaSave, FaSignOutAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalNotifications,
} from "../../components/modal";
import zModal from "../../functions";
import FormatMoney from "../../functions/formatmoney";
import { toDateBr, toDateTimeBr } from "../../functions/datetimes";
import RequestApi from "../../services";
import { RequestGet, RequestPatch, RequestPost } from "../../requestApi";
import { LeftPad } from "../../functions/leftPad";
import Msg from "../../components/errors/msg";

let d = {
  ManagerId: "00000000-0000-0000-0000-000000000000",
  Name: "",
  Acronym: "",
};

const ManagersNewEdit = (props) => {
  const [openNot, setOpenNot] = useState(false);
  const [txt, setTxt] = useState(null);
  const [msg, setMsg] = useState(null);
  const [error, setError] = useState(false);
  const [title, setTitle] = useState("");
  const [dados, setDados] = useState(d);
  const [acordos, setAcordos] = useState([]);
  const [edit, setEdit] = useState(false);
  const [alterar, setAlterar] = useState(false);

  const loadDados = async () => {
    if (props.managerId !== "00000000-0000-0000-0000-000000000000") {
      setOpenNot(true);
      setTxt("Carregando dados da Gestora");
      setError(false);

      const r = await RequestGet("Managers/Details/" + props.managerId);

      if (r.Status === 200) {
        d = r.Data;
        //setAcordos(r.data.acordos);
        dLoad();
        setTitle("Gestora: " + d.Name);
        toast.success(r.Title);
        setOpenNot(false);
      } else {
        setError(true);
        setMsg(r.Title);
      }
    } else {
      setDados({
        ManagerId: "00000000-0000-0000-0000-000000000000",
        Name: "",
        Acronym: "",
      });
      setTitle("Nova Gestora");
    }
  };

  const dLoad = () => {
    setDados(d);
    setAlterar(true);
    setEdit(true);
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setDados({ ...dados, [name]: value.toUpperCase() });
  };

  const closeModal = () => props.close(false);

  const closeNot = () => {
    setOpenNot(false);
    if (error) props.close(false);
  };

  const saveDados = async () => {
    setOpenNot(true);
    setTxt("Enviando Dados");
    setError(false);

    console.log(dados);

    const r = await (props.managerId === "00000000-0000-0000-0000-000000000000"
      ? await RequestPost("Managers/Insert/", dados)
      : await RequestPost("Managers/UpDate/" + props.managerId, dados));

    if (r.Status === 1) {
      d = r.Data;
      setTitle("Gestora: " + d.Name);
      dLoad();
      props.setId(r.Data.ManagerId);
      setEdit(true);
      toast.success(r.Title);
      props.upList();
      setOpenNot(false);
    } else {
      setMsg(<Msg dados={r.errors} />);
    }
  };

  useEffect(() => {
    loadDados();
  }, [props.show]);

  const [tl, setTl] = useState(1060);
  useEffect(() => {
    setTl(zModal());
  }, []);

  return (
    <>
      <Modal
        show={props.show}
        onHide={closeModal}
        backdrop="static"
        keyboard={false}
        size="md"
        style={{ zIndex: tl }}
      >
        <ModalHeader>
          <FaHandHoldingUsd /> {title}
        </ModalHeader>
        <ModalBody>
          <div style={{ minHeight: edit ? "250px" : "110px" }}>
            <Tabs
              defaultActiveKey="home"
              id="uncontrolled-tab-example"
              className="mb-2 pb-0 pt-0"
            >
              <Tab eventKey="home" title="Dados">
                <Row>
                  <Col sm={12}>
                    <Form.Group>
                      <Form.Label className="mb-0">Nome</Form.Label>
                      <Form.Control
                        type="text"
                        size="sm"
                        name="Name"
                        value={dados.Name}
                        onChange={handleInput}
                        style={{ textTransformation: "uppercase" }}
                        disabled={alterar}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <Form.Group>
                      <Form.Label className="mb-0">Sigla</Form.Label>
                      <Form.Control
                        type="text"
                        size="sm"
                        name="Acronym"
                        value={dados.Acronym}
                        onChange={handleInput}
                        style={{ textTransformation: "uppercase" }}
                        disabled={alterar}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                {edit && (
                  <Row className="mt-3">
                    <Col sm={3} className="border-start ms-2">
                      <div className="text-gray-500">Qtde Acordos</div>
                      <div>{acordos.length}</div>
                    </Col>
                  </Row>
                )}
              </Tab>
              {edit && (
                <Tab
                  eventKey="acordos"
                  title="Acordos"
                  disabled={!alterar ? true : false}
                >
                  <Row>
                    <Col sm={12}>
                      <Table striped hover size="sm">
                        <thead>
                          <tr>
                            <th className="border-top-0 pt-0 pb-0">Acordo</th>
                            {/* <th className="border-top-0 pt-0 pb-0">Responsável</th> */}
                            <th className="border-top-0 pt-0 pb-0">
                              Dt. Acordo
                            </th>
                            <th className="border-top-0 pt-0 pb-0">Valor</th>
                            <th className="border-top-0 pt-0 pb-0 ms-2">
                              Status
                            </th>
                            <th className="border-top-0 pt-0 pb-0"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {acordos.length > 0 ? (
                            acordos.map((a, index) => (
                              <tr key={index}>
                                <td className="p-0 mb-1">{LeftPad(a.id, 6)}</td>
                                {/* <td className="p-0 mb-1">{a.responsavel}</td> */}
                                <td className="p-0 mb-1">
                                  {toDateTimeBr(a.dtAcordo)}
                                </td>
                                <td className="p-0 mb-1 text-end pe-2">
                                  {FormatMoney(a.valor)}
                                </td>
                                <td className="p-0 mb-1 ms-2">{a.status}</td>
                                <td className="p-0 mb-1 text-center"></td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={6} align="center">
                                Nenhum acordo encontrado
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </Tab>
              )}
            </Tabs>
          </div>
        </ModalBody>
        <ModalFooter>
          <>
            {!edit && (
              <>
                <Button variant="secondary" size="sm" onClick={closeModal}>
                  <FaSignOutAlt /> Sair
                </Button>
                <Button variant="primary" size="sm" onClick={saveDados}>
                  <FaSave /> Salvar
                </Button>
              </>
            )}
            {edit && (
              <>
                {alterar && (
                  <>
                    <Button variant="secondary" size="sm" onClick={closeModal}>
                      <FaSignOutAlt /> Sair
                    </Button>
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={() => setAlterar(false)}
                    >
                      <FaEdit /> Alterar
                    </Button>
                  </>
                )}
                {!alterar && (
                  <>
                    <Button variant="secondary" size="sm" onClick={dLoad}>
                      <FaSignOutAlt /> Cancelar
                    </Button>
                    <Button variant="primary" size="sm" onClick={saveDados}>
                      <FaEdit /> Salvar
                    </Button>
                  </>
                )}
              </>
            )}
          </>
        </ModalFooter>
      </Modal>
      {openNot ? (
        <ModalNotifications
          show={openNot}
          close={closeNot}
          texto={txt}
          message={msg}
        />
      ) : null}
    </>
  );
};

export default ManagersNewEdit;

import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  InputGroup,
  Modal,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { FaCheck, FaSearch, FaSignOutAlt, FaUserFriends } from "react-icons/fa";
import zModal from "../../functions";
import { Link } from "react-router-dom";
import RequestApi from "../../services";
import { RequestGet } from "../../requestApi";

const ListResponsaveis = (props) => {
  const [sending, setSending] = useState(false);
  const [search, setSearch] = useState("");
  const [msgErro, setMsgErro] = useState("");
  const [responsables, setResponsables] = useState([]);

  const handleClose = () => props.dados("s", {});

  const handleGetResponsavel = (id, name) => {
    props.dados(id, name);
  };

  const list = async () => {
    setMsgErro("");
    if (search.trim().length > 1) {
      setSending(true);

      const r = await RequestGet("/Responsables/List?search=" + search);

      if (r.Status === 200) {
        setMsgErro(r.Title);
      }
      setResponsables(r.Data);
      setSending(false);
      if (r.Data.length === 0) {
        setMsgErro("Nenhum Responsável encontrado");
      }
    }
  };

  const handleSearch = (e) => {
    //e.preventDefault();
    if (e.key === "Enter") {
      list();
    }
  };

  const [tl, setTl] = useState(0);
  useEffect(() => {
    setTl(zModal());
  }, []);

  return (
    <Modal
      show={props.show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="md"
      id="2"
      //backdropClassName={`stack-${backdrop + 2}`}
      //className={`stack-b-${backdrop + 2}`}
      style={{ zIndex: tl }}
    >
      <Modal.Header closeButton={true} className="pt-2 ps-4 pb-0 pe-4">
        <Modal.Title className="h6">
          <FaUserFriends /> Inserir Responsável&nbsp;
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="ps-4 pt-2 pe-4">
        <Row>
          <Col sm={12}>
            <InputGroup>
              <Form.Control
                type="text"
                placeholder="Buscar Responsável"
                aria-label="Buscar"
                size="sm"
                onKeyDown={handleSearch}
                onChange={(e) => setSearch(e.target.value.toUpperCase())}
                defaultValue={search}
                autoFocus={true}
                style={{ textTransform: "uppercase" }}
              />
              <Button variant="primary" size="sm" onClick={list}>
                <FaSearch />
              </Button>
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col className="mb-1 mt-0 col-12">
            {sending && (
              <Spinner
                animation="border"
                size="sm"
                className="float-start mt-2"
              />
            )}
            <span className="text-danger float-start mt-1">
              {msgErro}&nbsp;
            </span>
          </Col>
        </Row>
        <Row>
          <Col
            sm={12}
            style={{
              height: "203px",
              overflowX: "hidden",
              overflowY: "auto",
            }}
          >
            <Table striped hover size="sm">
              <thead>
                <tr>
                  <th className="border-top-0 pt-0 pb-0">Nome</th>
                  <th className="border-top-0 pt-0 pb-0">CPF</th>
                  <th className="border-top-0 pt-0 pb-0"></th>
                </tr>
              </thead>
              <tbody>
                {responsables.length > 0 &&
                  responsables.map((r, index) => (
                    <tr key={index}>
                      <td className="p-0 mb-1">{r.Name}</td>
                      <td className="p-0 mb-1 text-center">
                        {r.ResponsableId}
                      </td>
                      <td className="p-0 mb-1 text-center">
                        <Link
                          onClick={() =>
                            handleGetResponsavel(r.ResponsableId, r.Name)
                          }
                        >
                          <FaCheck />
                        </Link>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="ps-3 pb-1 pt-1">
        <>
          <Button
            variant="secondary"
            size="sm"
            onClick={handleClose}
            //disabled={sending}
          >
            <FaSignOutAlt /> Sair
          </Button>
          {/* <Button variant="primary" size="sm" onClick={handleSaveResponsavel}>
            Salvar
          </Button> */}
        </>
        &nbsp;
      </Modal.Footer>
    </Modal>
  );
};

export default ListResponsaveis;

export default function zModal() {
    const bdp = document.querySelectorAll(".fade.modal-backdrop.show");

    var totl = 1060 + 10 * bdp.length;

    const element = document.getElementsByClassName(
        "fade modal-backdrop show"
    );

    setTimeout(() => {
        element[bdp.length].style["z-index"] = totl - 1;
    }, -1000);

    return totl;
}
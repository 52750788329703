import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  InputGroup,
  Row,
  Form,
  Button,
  Table,
  Spinner,
} from "react-bootstrap";
import { FaSearch, FaEye, FaSchool } from "react-icons/fa";
import { Link } from "react-router-dom";
import View from "./view";
import { Breadcrumb } from "../../components";
import RequestApi from "../../services";
import Edit from "./edit";
import { RequestGet } from "../../requestApi";

const Schools = () => {
  const [schools, setSchools] = useState([]);
  const [viewSchool, setViewSchool] = useState(false);
  const [schoolId, setSchoolId] = useState(0);
  const [sending, setSending] = useState(true);

  const loadDados = async () => {
    setSending(true);
    const r = await RequestGet("/Schools/List");

    if (r.Status === 200) {
      setSchools(r.Data);
    }
    setSending(false);
  };

  useEffect(() => {
    loadDados();
  }, []);

  return (
    <>
      <Breadcrumb icon={<FaSchool />} title="Colégios" />
      <Row>
        <Col sm={12}>
          <Card className="shadow">
            <Card.Body>
              <Row>
                <Col sm={12}>
                  <Table striped hover size="sm">
                    <thead>
                      <tr>
                        <th scope="col" className="ps-2">
                          Cod.
                        </th>
                        <th scope="col">Nome</th>
                        <th scope="col">Cidade</th>
                        <th scope="col">Alunos</th>
                        <th scope="col">Acordos</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {sending && (
                        <tr>
                          <td colSpan={6}>
                            <Spinner
                              animation="border"
                              size="sm"
                              className="float-start mt-2"
                            />
                          </td>
                        </tr>
                      )}
                      {schools.map((c, index) => (
                        <tr key={index}>
                          <td className="ps-2">{c.Code}</td>
                          <td>{c.Name}</td>
                          <td>{c.City}</td>
                          <td>{c.QtyStudents}</td>
                          <td>{c.QtyAgreements}</td>
                          <td>
                            <Link
                              onClick={() => {
                                setSchoolId(c.SchoolId);
                                setViewSchool(true);
                              }}
                            >
                              <FaEye color="#003768" />
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {viewSchool ? (
        <Edit show={viewSchool} close={setViewSchool} schoolId={schoolId} />
      ) : null}
    </>
  );
};

export default Schools;

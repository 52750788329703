import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  InputGroup,
  Modal,
  Row,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import ReactInputMask from "react-input-mask";
import {
  FaEdit,
  FaEye,
  FaMoneyCheckAlt,
  FaSave,
  FaSearch,
  FaSignOutAlt,
  FaUserFriends,
  FaUserGraduate,
} from "react-icons/fa";
import Inresponsavel from "./inresponsavel";
import NewDebt from "./newdebt";
import Jmodal from "../../components/modal/jmodal";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { RequestGet, RequestPost } from "../../requestApi";
import Msg from "../../components/errors/msg";

var dLoad = {};

const NewEdit = (props) => {
  //#region Outros
  const [mensagem, setMensagem] = useState(null);
  const [txt, setTxt] = useState(null);
  const [openNot, setOpenNot] = useState(false);
  const [editar, setEditar] = useState(false);
  const [newCadastro, setNewCadastro] = useState(false);
  const [erroLoad, setErroLoad] = useState(false);
  const [responsableId, setResponsableId] = useState("n");
  const [nameR, setNameR] = useState("");

  const [titulo, setTitulo] = useState(" ");
  const [viewShow2, setViewShow2] = useState(false);
  const [openResponsavel, setOpenResponsavel] = useState(false);
  const [studentDebtId, setStudentDebtId] = useState(0);
  //#endregion

  //#region Dados cadastrais Alunos
  const [studentId, setStudentId] = useState(0);
  const [schoolId, setSchoolId] = useState(0);
  const [name, setName] = useState("");
  const [docCpf, setDocCpf] = useState("");
  const [rg, setRg] = useState("");
  const [gender, setGender] = useState("");
  const [idReligiao, setIdReligiao] = useState(null);
  const [zipCode, setZipCode] = useState("");
  const [address, setAddress] = useState("");
  const [number, setNumber] = useState("");
  const [complement, setComplement] = useState("");
  const [district, setDistrict] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [tel1, setTel1] = useState("");
  const [tel2, setTel2] = useState("");
  const [email, setEmail] = useState("");
  const [obs, setObs] = useState("");
  //#endregion

  //#region Tabelas
  const [schools, setSchools] = useState([]);
  const [genders, setGenders] = useState([]);
  const [religioes, setReligioes] = useState([]);
  const [ufs, setUfs] = useState([]);

  const [debts, setDebts] = useState([]);
  const [debito, setDebito] = useState({});

  const [responsables, setResponsables] = useState([]);
  const [responsavel, setResponsavel] = useState({});
  //#endregion

  const openMResp = (r, resps) => {
    setResponsableId(r);
    setOpenResponsavel(r);
    setResponsables(resps);
    //if (resps.length > 0) setResponsaveis(resps);
  };

  const handleClose = () => {
    props.handleNewStudent(0, 0);
  };

  const handleSalvar = async () => {
    setTxt("Enviando dados...");
    setOpenNot(true);
    setMensagem(null);

    let error = [];
    var c = 0;
    var cp = "";
    var ida = parseInt(studentId);
    var gnd = parseInt(gender);

    if (isNaN(ida)) ida = 0;

    if (docCpf === undefined || docCpf === null || docCpf.trim().length === 0) {
      cp = "";
    } else {
      let regex = /\d/g;
      cp = docCpf.trim().length > 0 ? docCpf.match(regex).join("") : docCpf;

      if (cp.trim().length < 11)
        error.push(
          <span key={c++}>
            CPF inválido
            <br key={1} />
          </span>
        );
    }

    if (ida === 0)
      error.push(
        <span key={c++}>
          Código do Aluno inválido
          <br />
        </span>
      );

    if (name === undefined || name.trim().length < 2)
      error.push(
        <span key={c++}>
          Nome deve ter ao menos 2 caracteres
          <br key={1} />
        </span>
      );

    if (gnd === 0)
      error.push(
        <span key={c++}>
          Sexo deve ser informado
          <br key={1} />
        </span>
      );

    //Se tem erros
    if (error.length > 0) {
      setMensagem(error);
      return;
    }

    const dados = {
      SchoolId: schoolId,
      StudentId: ida,
      name,
      DocCpf: cp,
      docRg: rg,
      GenderEnum: gender,
      idReligiao: idReligiao === null ? null : parseInt(idReligiao),
      zipCode,
      address,
      number,
      complement,
      district,
      city,
      state,
      tel1,
      tel2,
      email,
      obs,
    };

    const r = await RequestPost(
      "/Students/" +
        (props.studentId === 0 && props.schoolId === 0
          ? "Insert"
          : "UpDate/" + schoolId + "/" + studentId),
      dados
    );

    setMensagem(r.Title);
    if (r.Status === 200) {
      toast.success(r.Title);
      props.handleNewStudent(r.Data.StudentId, r.Data.SchoolId);
      setNewCadastro(false);
      setEditar(true);
      dLoad = r.Data;
      setSchools(r.Data.Schools);
      setGenders(r.Data.Genders);
      setOpenNot(false);
    } else {
      setMensagem(<Msg dados={r.errors} />);
      setErroLoad(false);
    }
  };

  const handleCancel = () => {
    setEditar(true);
    handledLoad();
  };

  const handledLoad = () => {
    setStudentId(dLoad.StudentId);
    setSchoolId(dLoad.SchoolId);
    setName(dLoad.Name);
    setDocCpf(dLoad.DocCpf);
    setRg(dLoad.DocRg);
    setGender(dLoad.GenderEnum);
    setIdReligiao(dLoad.ReligionId);
    setZipCode(dLoad.ZipCode);
    setAddress(dLoad.Address);
    setNumber(dLoad.Number);
    setComplement(dLoad.Complement);
    setDistrict(dLoad.District);
    setCity(dLoad.City);
    setState(dLoad.State);
    setTel1(dLoad.PhoneNumber1);
    setTel2(dLoad.PhoneNumber2);
    setEmail(dLoad.Email);
    setObs(dLoad.Obs);
    setResponsables(dLoad.Responsables);
    setDebts(dLoad.Debts);
    setTitulo(dLoad.Name === "" ? "Novo Aluno" : "Aluno: " + dLoad.Name);
  };

  const gResp = (id) => {
    setResponsableId(id);
  };

  async function getDados() {
    var url = "/New";
    setTxt("Carregando dados...");
    setOpenNot(true);
    setMensagem(null);

    if (props.show && props.new) {
      setNewCadastro(true);
      url = "/New";
    }
    //Altercao de Cadastro
    else if (
      props.show &&
      !props.new &&
      props.studentId > 0 &&
      props.schoolId != null
    ) {
      url = "/Details/" + props.schoolId + "/" + props.studentId;
      setNewCadastro(false);
      setEditar(true);
    } else {
      setErroLoad(true);
      setMensagem("Erro no carregamento de dados. Error: unknown");
      return;
    }

    const r = await RequestGet("/Students" + url);

    if (r.Status === 200) {
      toast.info(r.Title);
      dLoad = r.Data;
      setSchools(r.Data.Schools);
      setGenders(r.Data.Genders);
      //setReligioes(r.religioes);
      //setUfs(r.ufs);
      handledLoad();
      setOpenNot(false);
    } else {
      setErroLoad(true);
      setMensagem(
        "Erro no carregamento de dados. Data Server!. Error: " + r?.status
      );
    }
  }

  useEffect(() => {
    getDados();
    //inFocus();
  }, [props.show]);

  const closeModal = () => {
    setOpenNot(false);
    if ((erroLoad && newCadastro) || (erroLoad && !newCadastro && !editar))
      props.handleNewStudent(0, 0);
  };

  const handleNewDebt = (debit = null) => {
    if (debit !== null) {
      setDebts(debit);
      //setViewShow2(false);
      setDebito({});
    } else setViewShow2(false);
  };

  return (
    <>
      <Modal
        show={props.show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton className="pt-2 ps-4 pb-0 pe-4">
          <Modal.Title className="h6">
            <FaUserGraduate /> {titulo}&nbsp;
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="ps-4 pt-2 pe-4" style={{ minHeight: "487px" }}>
          <>
            <Tabs
              defaultActiveKey="home"
              id="uncontrolled-tab-example"
              className="mb-2 pb-0 pt-0"
            >
              <Tab eventKey="home" title="Dados">
                <Form>
                  <Row className="pb-2">
                    <Col sm={6} md={3} lg={2} className="col-6">
                      <Form.Group controlId="idColegio">
                        <Form.Label className="mb-0">Colégio</Form.Label>
                        <Form.Select
                          size="sm"
                          onChange={(e) => setSchoolId(e.target.value)}
                          value={schoolId}
                          disabled={newCadastro ? false : true}
                          className="text-center"
                        >
                          <option disabled value={0} className="text-muted">
                            Colégio
                          </option>
                          {schools.map((s, index) => (
                            <option key={index} value={s.Id}>
                              {s.Name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col sm={6} md={3} lg={2} className="col-6">
                      <Form.Group controlId="idAluno">
                        <Form.Label className="mb-0">Cod. Aluno</Form.Label>
                        <Form.Control
                          type="number"
                          size="sm"
                          min={0}
                          onChange={(e) =>
                            setStudentId(e.target.value.toUpperCase())
                          }
                          value={studentId}
                          style={{ textTransform: "uppercase" }}
                          disabled={newCadastro ? false : true}
                          className="text-center"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={12} md={6} lg={8}>
                      <Form.Group controlId="nome">
                        <Form.Label className="mb-0">Nome</Form.Label>
                        <Form.Control
                          type="text"
                          size="sm"
                          maxLength={50}
                          onChange={(e) =>
                            setName(e.target.value.toUpperCase())
                          }
                          value={name}
                          style={{ textTransform: "uppercase" }}
                          disabled={editar}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="pb-2">
                    <Col sm={4} lg={2} className="col-6">
                      <Form.Group controlId="cpf">
                        <Form.Label className="mb-0">CPF</Form.Label>
                        <Form.Control
                          as={ReactInputMask}
                          type="text"
                          size="sm"
                          onChange={(e) => setDocCpf(e.target.value)}
                          value={docCpf}
                          style={{ textTransform: "uppercase" }}
                          mask="999.999.999-99"
                          maskChar=""
                          disabled={editar}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={4} lg={2} className="col-6">
                      <Form.Group controlId="rg">
                        <Form.Label className="mb-0">RG</Form.Label>
                        <Form.Control
                          type="text"
                          size="sm"
                          maxLength={20}
                          onChange={(e) => setRg(e.target.value.toUpperCase())}
                          value={rg}
                          style={{ textTransform: "uppercase" }}
                          disabled={editar}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={4} lg={2} className="col-6">
                      <Form.Group controlId="sexo">
                        <Form.Label className="mb-0">Sexo</Form.Label>
                        <Form.Select
                          size="sm"
                          onChange={(e) =>
                            setGender(e.target.value.toUpperCase())
                          }
                          value={gender === "" ? 0 : gender}
                          disabled={editar}
                        >
                          <option disabled value={0} className="text-muted">
                            -- Sexo --
                          </option>
                          {genders.map((s, index) => (
                            <option key={index} value={s.Id}>
                              {s.Name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col sm={6} lg={4} className="col-6">
                      <Form.Group controlId="religiao">
                        <Form.Label className="mb-0">Religião</Form.Label>
                        <Form.Select
                          size="sm"
                          onChange={(e) => setIdReligiao(e.target.value)}
                          value={idReligiao === null ? 0 : idReligiao}
                          disabled={editar}
                        >
                          <option disabled value={0} className="text-muted">
                            -- Religião --
                          </option>
                          {religioes.map((r, index) => (
                            <option key={index} value={r.id}>
                              {r.nome.toUpperCase()}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col sm={6} lg={2}>
                      <Form.Group controlId="cep">
                        <Form.Label className="mb-0">CEP</Form.Label>
                        <InputGroup>
                          <Form.Control
                            as={ReactInputMask}
                            type="text"
                            size="sm"
                            onChange={(e) =>
                              setZipCode(e.target.value.toUpperCase())
                            }
                            value={zipCode}
                            style={{ textTransform: "uppercase" }}
                            mask="99999-999"
                            maskChar=""
                            disabled={editar}
                          />
                          <Button
                            variant="light"
                            size="sm"
                            onClick={() => alert("opa")}
                            className="border"
                            disabled={editar}
                          >
                            <FaSearch color="#babbbc" />
                          </Button>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="pb-2">
                    <Col sm={9} lg={6}>
                      <Form.Group controlId="endereco">
                        <Form.Label className="mb-0">Endereço</Form.Label>
                        <Form.Control
                          type="text"
                          size="sm"
                          maxLength={50}
                          onChange={(e) =>
                            setAddress(e.target.value.toUpperCase())
                          }
                          value={address}
                          style={{ textTransform: "uppercase" }}
                          disabled={editar}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={3} lg={2}>
                      <Form.Group controlId="num">
                        <Form.Label className="mb-0">Nº</Form.Label>
                        <Form.Control
                          type="text"
                          size="sm"
                          maxLength={10}
                          onChange={(e) =>
                            setNumber(e.target.value.toUpperCase())
                          }
                          value={number}
                          style={{ textTransform: "uppercase" }}
                          disabled={editar}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={12} lg={4}>
                      <Form.Group controlId="compl">
                        <Form.Label className="mb-0">Complemento</Form.Label>
                        <Form.Control
                          type="text"
                          size="sm"
                          maxLength={40}
                          onChange={(e) =>
                            setComplement(e.target.value.toUpperCase())
                          }
                          value={complement}
                          style={{ textTransform: "uppercase" }}
                          disabled={editar}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="pb-2">
                    <Col sm={12} lg={4}>
                      <Form.Group controlId="bairro">
                        <Form.Label className="mb-0">Bairro</Form.Label>
                        <Form.Control
                          type="text"
                          size="sm"
                          maxLength={40}
                          onChange={(e) =>
                            setDistrict(e.target.value.toUpperCase())
                          }
                          value={district}
                          style={{ textTransform: "uppercase" }}
                          disabled={editar}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={9} lg={6}>
                      <Form.Group controlId="cidade">
                        <Form.Label className="mb-0">Cidade</Form.Label>
                        <Form.Control
                          type="text"
                          size="sm"
                          maxLength={50}
                          onChange={(e) =>
                            setCity(e.target.value.toUpperCase())
                          }
                          value={city}
                          style={{ textTransform: "uppercase" }}
                          disabled={editar}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={3} lg={2}>
                      <Form.Group controlId="estado">
                        <Form.Label className="mb-0">Estado</Form.Label>
                        <Form.Select
                          size="sm"
                          onChange={(e) =>
                            setState(e.target.value.toUpperCase())
                          }
                          value={state === "" ? 0 : state}
                          disabled={editar}
                        >
                          <option disabled value={0} className="text-muted">
                            -- Estado --
                          </option>
                          {ufs.map((s, index) => (
                            <option
                              key={index}
                              value={s.id}
                              //selected={s.id === sexo ? true : false}
                            >
                              {s.id.toUpperCase()}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="pb-2">
                    <Col sm={3}>
                      <Form.Group controlId="tel1">
                        <Form.Label className="mb-0">Tel 1</Form.Label>
                        <Form.Control
                          as={ReactInputMask}
                          type="text"
                          size="sm"
                          onChange={(e) =>
                            setTel1(e.target.value.toUpperCase())
                          }
                          value={tel1}
                          style={{ textTransform: "uppercase" }}
                          mask="(99) 99999-9999"
                          disabled={editar}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group controlId="tel2">
                        <Form.Label className="mb-0">Tel 2</Form.Label>
                        <Form.Control
                          as={ReactInputMask}
                          type="text"
                          size="sm"
                          onChange={(e) =>
                            setTel2(e.target.value.toUpperCase())
                          }
                          value={tel2}
                          style={{ textTransform: "uppercase" }}
                          mask="(99) 99999-9999"
                          disabled={editar}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group controlId="email">
                        <Form.Label className="mb-0">Email</Form.Label>
                        <Form.Control
                          type="email"
                          size="sm"
                          maxLength={150}
                          onChange={(e) => {
                            setEmail(e.target.value.toLowerCase());
                          }}
                          value={email}
                          style={{ textTransform: "lowercase" }}
                          disabled={editar}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="pb-2">
                    <Col sm={12}>
                      <Form.Group controlId="email">
                        <Form.Label className="mb-0">Obs.</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          size="sm"
                          onChange={(e) => {
                            setObs(e.target.value.toUpperCase());
                          }}
                          value={obs}
                          style={{ textTransform: "uppercase" }}
                          disabled={editar}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      {!props.new && !editar && (
                        <Button
                          variant="secondary"
                          size="sm"
                          onClick={handleCancel}
                        >
                          <FaSignOutAlt /> Cancelar
                        </Button>
                      )}
                      {!props.new && !editar && (
                        <Button
                          variant="primary"
                          size="sm"
                          onClick={handleSalvar}
                          className="ms-2"
                        >
                          <FaSave /> Gravar
                        </Button>
                      )}
                      {!props.new && editar && (
                        <Button
                          variant="light"
                          size="sm"
                          onClick={() => setEditar(false)}
                        >
                          <FaEdit /> Alterar
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Form>
              </Tab>
              {!newCadastro && (
                <Tab
                  eventKey="financeiro"
                  title="Financeiro"
                  disabled={!editar}
                >
                  <Row>
                    <Col
                      sm={12}
                      style={{
                        height: "360px",
                        overflowX: "hidden",
                        overflowY: "auto",
                      }}
                    >
                      <Table striped hover size="sm">
                        <thead>
                          <tr>
                            <th className="border-top-0 pt-0 pb-0">
                              Descrição
                            </th>
                            <th className="border-top-0 pt-0 pb-0 text-center">
                              Parcela
                            </th>
                            <th className="border-top-0 pt-0 pb-0 text-center">
                              Vencimento
                            </th>
                            <th className="border-top-0 pt-0 pb-0 text-center">
                              Dias
                            </th>
                            <th className="border-top-0 pt-0 pb-0 text-end">
                              Valor
                            </th>
                            <th className="border-top-0 pt-0 pb-0 text-end">
                              Multa
                            </th>
                            <th className="border-top-0 pt-0 pb-0 text-end">
                              Juros
                            </th>
                            <th className="border-top-0 pt-0 pb-0 text-end">
                              Corrigido
                            </th>
                            <th className="border-top-0 pt-0 pb-0 text-center"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {debts?.map((d, index) => (
                            <tr key={index}>
                              <td className="p-0 mb-1">{d.TypeDebtEnumName}</td>
                              <td className="p-0 mb-1 text-center">
                                {d.Installment}
                              </td>
                              <td className="p-0 mb-1 text-center">
                                {new Date(d.DtPaydue).toLocaleDateString(
                                  "pt-BR"
                                )}
                              </td>
                              <td className="p-0 mb-1 text-center">{d.Days}</td>
                              <td className="p-0 mb-1 text-end">
                                {new Intl.NumberFormat("pt-BR", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                  currency: "BRL",
                                }).format(d.Value)}
                              </td>
                              <td className="p-0 mb-1 text-end">
                                {new Intl.NumberFormat("pt-BR", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                  currency: "BRL",
                                }).format(d.Fine)}
                              </td>
                              <td className="p-0 mb-1 text-end">
                                {new Intl.NumberFormat("pt-BR", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                  currency: "BRL",
                                }).format(d.Interest)}
                              </td>
                              <td className="p-0 mb-1 text-end">
                                {new Intl.NumberFormat("pt-BR", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                  currency: "BRL",
                                }).format(d.ValueCalculated)}
                              </td>
                              <td className="p-0 mb-1 text-center">
                                <Link
                                  onClick={() => {
                                    setStudentDebtId(d.StudentDebtId);
                                    setViewShow2(true);
                                  }}
                                  title="Visualizar Débito"
                                >
                                  <FaEye />
                                </Link>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={4} className="border-end">
                      <Button
                        size="sm"
                        variant="secondary"
                        onClick={() => {
                          setStudentDebtId(
                            "00000000-0000-0000-0000-000000000000"
                          );
                          setViewShow2(true);
                        }}
                      >
                        <FaMoneyCheckAlt /> Novo Débito
                      </Button>
                    </Col>
                    <Col sm={2} className="border-end">
                      <div className="text-gray-500">Total Débito</div>
                      <div className="float-end" id="TotalValor">
                        {debts?.length > 0 &&
                          new Intl.NumberFormat("pt-BR", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                            currency: "BRL",
                          }).format(
                            debts?.reduce(
                              (total, debito) => total + debito.Value,
                              0
                            )
                          )}
                      </div>
                    </Col>
                    <Col sm={2} className="border-end">
                      <div className="text-gray-500">Total Multa</div>
                      <div className="float-end" id="TotalValor">
                        {debts?.length > 0 &&
                          new Intl.NumberFormat("pt-BR", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                            currency: "BRL",
                          }).format(
                            debts?.reduce(
                              (total, debito) => total + debito.Fine,
                              0
                            )
                          )}
                      </div>
                    </Col>
                    <Col sm={2} className="border-end">
                      <div className="text-gray-500">Total Juros</div>
                      <div className="float-end" id="TotalValor">
                        {debts?.length > 0 &&
                          new Intl.NumberFormat("pt-BR", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                            currency: "BRL",
                          }).format(
                            debts?.reduce(
                              (total, debito) => total + debito.Interest,
                              0
                            )
                          )}
                      </div>
                    </Col>
                    <Col sm={2} className="border-end">
                      <div className="text-gray-500">Total Corrigido</div>
                      <div className="float-end" id="TotalValor">
                        {debts?.length > 0 &&
                          new Intl.NumberFormat("pt-BR", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                            currency: "BRL",
                          }).format(
                            debts?.reduce(
                              (total, debito) => total + debito.ValueCalculated,
                              0
                            )
                          )}
                      </div>
                    </Col>
                  </Row>
                </Tab>
              )}
              {!newCadastro && (
                <Tab
                  eventKey="responsavel"
                  title="Responsáveis"
                  disabled={!editar}
                >
                  <Row>
                    <Col
                      sm={9}
                      style={{
                        height: "360px",
                        overflowX: "hidden",
                        overflowY: "auto",
                      }}
                    >
                      <Table striped hover size="sm">
                        <thead>
                          <tr>
                            <th className="border-top-0 pt-0 pb-0">Nome</th>
                            <th className="border-top-0 pt-0 pb-0">CPF</th>
                            <th className="border-top-0 pt-0 pb-0"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {responsables?.length > 0 &&
                            responsables?.map((r, index) => (
                              <tr key={index}>
                                <td className="p-0 mb-1">{r.Name}</td>
                                <td className="p-0 mb-1">{r.ResponsableId}</td>
                                <td className="p-0 mb-1 text-center">
                                  <Link
                                    onClick={() => {
                                      setResponsableId(r.ResponsableId);
                                      setNameR(r.Name);
                                      setOpenResponsavel(true);
                                    }}
                                  >
                                    <FaEye color="#003768" />
                                  </Link>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </Col>
                    <Col sm={3}>
                      <Button
                        size="sm"
                        variant="secondary"
                        onClick={() => {
                          setResponsableId("n");
                          setOpenResponsavel(true);
                        }}
                      >
                        <FaUserFriends /> Inserir Responsável
                      </Button>
                    </Col>
                  </Row>
                </Tab>
              )}
            </Tabs>
          </>
        </Modal.Body>
        <Modal.Footer className="ps-3 pb-1 pt-1">
          <Button variant="secondary" size="sm" onClick={handleClose}>
            <FaSignOutAlt /> Sair
          </Button>
          {props.new && (
            <Button variant="primary" size="sm" onClick={handleSalvar}>
              <FaSave /> Salvar
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      {viewShow2 ? (
        <NewDebt
          show={viewShow2}
          close={setViewShow2}
          handleNewDebt={handleNewDebt}
          schoolId={props.schoolId}
          studentId={props.studentId}
          studentDebtId={studentDebtId}
        />
      ) : null}
      {openResponsavel ? (
        <Inresponsavel
          show={openResponsavel}
          close={setOpenResponsavel}
          dados={openMResp}
          gIdR={gResp}
          responsableId={responsableId}
          setResponsableId={setResponsableId}
          schoolId={props.schoolId}
          studentId={props.studentId}
          responsaveis={setResponsables}
          name={nameR}
          setName={setNameR}
        />
      ) : null}
      {openNot ? (
        <Jmodal
          show={openNot}
          //dados={handleNew}
          message={mensagem}
          closeModal={closeModal}
          texto={txt}
        />
      ) : null}
    </>
  );
};

export default NewEdit;

export default function FormatMoney(valor) {
    let nvalor = parseFloat(valor)

    if (isNaN(nvalor))
        nvalor = 0.00

    let vlr = new Intl.NumberFormat("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        currency: "BRL",
    }).format(nvalor)

    return vlr;
}
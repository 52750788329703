import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import { FaEdit, FaSave, FaSchool, FaSignOutAlt } from "react-icons/fa";
import {
  ModalBody,
  ModalHeader,
  ModalFooter,
  ModalNotifications,
} from "../../components/modal";
import zModal from "../../functions";
import RequestApi from "../../services";
import { toast } from "react-toastify";
import { RequestGet, RequestPatch, RequestPut } from "../../requestApi";
import { LeftPad } from "../../functions/leftPad";
import { toDateTimeBr } from "../../functions/datetimes";
import FormatMoney from "../../functions/formatmoney";

var d = {};

const Edit = (props) => {
  const [mensagem, setMensagem] = useState(null);
  const [txt, setTxt] = useState(null);
  const [openNot, setOpenNot] = useState(false);
  const [alterar, setAlterar] = useState(true);
  const [ufs, setUfs] = useState([]);
  const [error, setError] = useState(false);

  //#region Dados Colegio
  const [code, setCode] = useState();
  const [nome, setNome] = useState();
  const [cep, setCep] = useState();
  const [endereco, setEndereco] = useState();
  const [num, setNum] = useState();
  const [compl, setCompl] = useState();
  const [bairro, setBairro] = useState();
  const [cidade, setCidade] = useState();
  const [uf, setUf] = useState();
  const [obs, setObs] = useState();
  const [tel1, setTel1] = useState();
  const [tel2, setTel2] = useState();
  const [email, setEmail] = useState();
  const [diretor, setDiretor] = useState();
  const [tesoureiro, setTesoureiro] = useState();
  const [acordos, setAcordos] = useState([]);
  //#endregion
  const closeModal = () => props.close(false);

  const closeModalError = () => {
    setOpenNot(false);
    if (error) props.close(false);
  };

  const loadDados = async () => {
    setOpenNot(true);
    setMensagem(null);
    setTxt("Carregando dados do Colégio");
    setError(false);

    //if (props.id <= 0) setMensagem("Erro Id Colégio");

    const r = await RequestGet("/Schools/Details/" + props.schoolId);

    if (r.Status === 200) {
      toast.info(r.Title);
      d = r.Data;
      //setAcordos(r.colegio.acordos);
      //setUfs(r.ufs);
      handleLoad();
      setOpenNot(false);
    } else {
      setMensagem(r.message);
      setError(true);
    }
  };

  const saveDados = async () => {
    setOpenNot(true);
    setMensagem(null);
    setTxt("Carregando dados do Colégio");
    setError(false);

    if (props.id <= 0) setMensagem("Erro Id Colégio");

    let erros = [];

    if (nome.trim().length < 2)
      erros.push(<p>Nome deve ter ao menos 2 caracteres</p>);

    if (erros.length > 0) {
      setMensagem(erros);
      return;
    }

    let dados = {
      nome,
      cep,
      endereco,
      num,
      compl,
      bairro,
      cidade,
      uf,
      tel1,
      tel2,
      email,
      obs,
      tesoureiro,
      diretor,
    };

    const r = await RequestPut("/Colegios/UpDate/" + props.schoolId, dados);

    if (r.Status === 200) {
      toast.success(r.Title);
      d = r.Data;
      setAlterar(true);
      setOpenNot(false);
    } else {
      setMensagem(r.Title);
      //setError(true);
    }
  };

  const handleLoad = () => {
    setCode(d.Code);
    setNome(d.Name);
    setCep(d.ZipCode);
    setEndereco(d.Address);
    setNum(d.Number);
    setCompl(d.Complement);
    setBairro(d.District);
    setCidade(d.City);
    setUf(d.State);
    setObs(d.Obs);
    setTel1(d.PhoneNumber1);
    setTel2(d.PhoneNumber2);
    setEmail(d.Email);
    setDiretor(d.Director);
    setTesoureiro(d.Treasurer);
  };

  useEffect(() => {
    loadDados();
  }, [props.show]);

  const [tl, setTl] = useState(1060);
  useEffect(() => {
    setTl(zModal());
  }, []);
  return (
    <>
      <Modal
        show={props.show}
        onHide={closeModal}
        backdrop="static"
        keyboard={false}
        size="lg"
        style={{ zIndex: tl }}
      >
        <ModalHeader>
          <FaSchool /> Colégio: {nome}
        </ModalHeader>
        <ModalBody>
          <Tabs
            defaultActiveKey="home"
            id="uncontrolled-tab-example"
            className="mb-2 pb-0 pt-0"
          >
            <Tab eventKey="home" title="Dados">
              <Form>
                <Row className="pb-2">
                  <Col sm={2} className="pb-2 ">
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label className="mb-0">Código</Form.Label>
                      <Form.Control
                        type="string"
                        size="sm"
                        disabled={true}
                        value={code}
                        className="text-center"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={10} className="pb-2 ">
                    <Form.Group controlId="nome">
                      <Form.Label className="mb-0">Nome</Form.Label>
                      <Form.Control
                        type="text"
                        size="sm"
                        maxLength={50}
                        onChange={(e) => setNome(e.target.value.toUpperCase())}
                        value={nome}
                        style={{ textTransform: "uppercase" }}
                        disabled={alterar}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="pb-2">
                  <Col sm={2} className="pb-2 ">
                    <Form.Group controlId="cep">
                      <Form.Label className="mb-0">CEP</Form.Label>
                      <Form.Control
                        type="text"
                        size="sm"
                        maxLength={9}
                        onChange={(e) => setCep(e.target.value.toUpperCase())}
                        value={cep}
                        style={{ textTransform: "uppercase" }}
                        disabled={alterar}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={8} className="pb-2 ">
                    <Form.Group controlId="endereco">
                      <Form.Label className="mb-0">Endereço</Form.Label>
                      <Form.Control
                        type="text"
                        size="sm"
                        maxLength={50}
                        onChange={(e) =>
                          setEndereco(e.target.value.toUpperCase())
                        }
                        value={endereco}
                        style={{ textTransform: "uppercase" }}
                        disabled={alterar}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={2} className="pb-2 ">
                    <Form.Group controlId="num">
                      <Form.Label className="mb-0">Nº</Form.Label>
                      <Form.Control
                        type="text"
                        size="sm"
                        maxLength={10}
                        onChange={(e) => setNum(e.target.value.toUpperCase())}
                        value={num}
                        style={{ textTransform: "uppercase" }}
                        disabled={alterar}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="pb-2">
                  <Col sm={6} className="pb-2 ">
                    <Form.Group controlId="compl">
                      <Form.Label className="mb-0">Complemento</Form.Label>
                      <Form.Control
                        type="text"
                        size="sm"
                        maxLength={40}
                        onChange={(e) => setCompl(e.target.value.toUpperCase())}
                        value={compl}
                        style={{ textTransform: "uppercase" }}
                        disabled={alterar}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={6} className="pb-2 ">
                    <Form.Group controlId="bairro">
                      <Form.Label className="mb-0">Bairro</Form.Label>
                      <Form.Control
                        type="text"
                        size="sm"
                        maxLength={40}
                        onChange={(e) =>
                          setBairro(e.target.value.toUpperCase())
                        }
                        value={bairro}
                        style={{ textTransform: "uppercase" }}
                        disabled={alterar}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="pb-2">
                  <Col sm={10} className="pb-2 ">
                    <Form.Group controlId="cidade">
                      <Form.Label className="mb-0">Cidade</Form.Label>
                      <Form.Control
                        type="text"
                        size="sm"
                        maxLength={50}
                        onChange={(e) =>
                          setCidade(e.target.value.toUpperCase())
                        }
                        value={cidade}
                        style={{ textTransform: "uppercase" }}
                        disabled={alterar}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={2} className="pb-2 ">
                    <Form.Group controlId="estado">
                      <Form.Label className="mb-0">Estado</Form.Label>
                      <Form.Select
                        size="sm"
                        onChange={(e) => setUf(e.target.value.toUpperCase())}
                        value={uf}
                        disabled={alterar}
                      >
                        <option disabled value={0} className="text-muted">
                          -- Estado --
                        </option>
                        {ufs.map((s, index) => (
                          <option key={index} value={s.id}>
                            {s.id.toUpperCase()}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="pb-2">
                  <Col sm={3} className="pb-2 ">
                    <Form.Group controlId="tel">
                      <Form.Label className="mb-0">Tel 1</Form.Label>
                      <Form.Control
                        type="text"
                        size="sm"
                        maxLength={50}
                        onChange={(e) => setTel1(e.target.value.toUpperCase())}
                        value={tel1}
                        style={{ textTransform: "uppercase" }}
                        disabled={alterar}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={3} className="pb-2 ">
                    <Form.Group controlId="estado">
                      <Form.Label className="mb-0">Tel 2</Form.Label>
                      <Form.Control
                        type="text"
                        size="sm"
                        maxLength={2}
                        //value={nome}
                        onChange={(e) => setTel2(e.target.value.toUpperCase())}
                        value={tel2}
                        style={{ textTransform: "uppercase" }}
                        disabled={alterar}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={6} className="pb-2 ">
                    <Form.Group controlId="estado">
                      <Form.Label className="mb-0">E-mail</Form.Label>
                      <Form.Control
                        type="text"
                        size="sm"
                        maxLength={150}
                        //value={nome}
                        onChange={(e) => setEmail(e.target.value.toUpperCase())}
                        value={email}
                        style={{ textTransform: "lowercase" }}
                        disabled={alterar}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="pb-2">
                  <Col sm={6} className="pb-2 ">
                    <Form.Group controlId="diretor">
                      <Form.Label className="mb-0">Diretor</Form.Label>
                      <Form.Control
                        type="text"
                        size="sm"
                        maxLength={50}
                        onChange={(e) =>
                          setDiretor(e.target.value.toUpperCase())
                        }
                        value={diretor}
                        style={{ textTransform: "uppercase" }}
                        disabled={alterar}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={6} className="pb-2 ">
                    <Form.Group controlId="tesoureiro">
                      <Form.Label className="mb-0">Tesoureiro</Form.Label>
                      <Form.Control
                        type="text"
                        size="sm"
                        maxLength={2}
                        //value={nome}
                        onChange={(e) =>
                          setTesoureiro(e.target.value.toUpperCase())
                        }
                        value={tesoureiro}
                        style={{ textTransform: "uppercase" }}
                        disabled={alterar}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="pb-2">
                  <Col sm={12}>
                    <Form.Group controlId="obs">
                      <Form.Label className="mb-0">Obs.</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        size="sm"
                        onChange={(e) => {
                          setObs(e.target.value.toUpperCase());
                        }}
                        value={obs}
                        style={{ textTransform: "uppercase" }}
                        disabled={alterar}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Tab>
            <Tab eventKey="acordos" title="Acordos">
              <Row>
                <Col
                  sm={9}
                  style={{
                    height: "485px",
                    overflowX: "hidden",
                    overflowY: "auto",
                  }}
                >
                  <Table striped hover size="sm">
                    <thead>
                      <tr>
                        <th className="border-top-0 pt-0 pb-0">Nº Acordo</th>
                        <th className="border-top-0 pt-0 pb-0">Dt. Acordo</th>
                        <th className="border-top-0 pt-0 pb-0">Valor</th>
                        <th className="border-top-0 pt-0 pb-0">Status</th>
                        <th className="border-top-0 pt-0 pb-0"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {acordos.length > 0 ? (
                        acordos.map((a, index) => (
                          <tr key={index}>
                            <td className="p-0 mb-1">{LeftPad(a.id, 6)}</td>
                            <td className="p-0 mb-1">
                              {toDateTimeBr(a.dtAcordo)}
                            </td>
                            <td className="p-0 mb-1 text-end pe-3">
                              {FormatMoney(a.valor)}
                            </td>
                            <td className="p-0 mb-1">{a.status}</td>
                            <td className="p-0 mb-1 text-center"></td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={5} align="center">
                            Nenhum acordo encontrado
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </Col>
                <Col sm={3}>
                  <Row>
                    <Col sm={12}>
                      <div className="text-gray-500">
                        Valor Total de Acordos
                      </div>
                      <div className="text-black fw-bold text-end">
                        {FormatMoney(d.totalAcordos)}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <div className="text-gray-500">Total Recebido</div>
                      <div className="text-black fw-bold text-end">0,00</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <div className="text-gray-500">Total Em Atrasado</div>
                      <div className="text-black fw-bold text-end">0,00</div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Tab>
          </Tabs>
        </ModalBody>
        <ModalFooter>
          {alterar && (
            <Button variant="secondary" size="sm" onClick={closeModal}>
              <FaSignOutAlt /> Sair
            </Button>
          )}
          {alterar && (
            <Button
              variant="primary"
              size="sm"
              onClick={() => setAlterar(false)}
            >
              <FaEdit /> Editar
            </Button>
          )}
          {!alterar && (
            <Button
              variant="secondary"
              size="sm"
              onClick={() => {
                setAlterar(true);
                handleLoad();
              }}
            >
              <FaSignOutAlt /> Cancelar
            </Button>
          )}
          {!alterar && (
            <Button variant="primary" size="sm" onClick={saveDados}>
              <FaSave /> Salvar
            </Button>
          )}
        </ModalFooter>
      </Modal>
      {openNot ? (
        <ModalNotifications
          show={openNot}
          message={mensagem}
          close={closeModalError}
          texto={txt}
        />
      ) : null}
    </>
  );
};

export default Edit;

import React, { useEffect, useState } from "react";
import { Card, Col, Row, Spinner } from "react-bootstrap";
import { FaCogs, FaDonate, FaUserFriends } from "react-icons/fa";
import { Breadcrumb } from "../../components";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { ModalNotifications } from "../../components/modal";
import ParametersWitnessAlt from "./witness";
import ParametersFinancialAlt from "./financial";
import { RequestGet } from "../../requestApi";
import FormatCpf from "../../functions/formatCpf";
import FormatMoney from "../../functions/formatmoney";

const Parameters = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  //const [testemunha2, setTestemunha2] = useState([]);
  const [financeiro, setFinanceiro] = useState([]);
  const [openNot, setOpenNot] = useState(false);
  const [texto, setTexto] = useState(null);
  const [mensagem, setMensagem] = useState(null);
  const [editTestemunha, setEditTestemunha] = useState(false);
  const [editFinanceiro, setEditFinanceiro] = useState(false);
  const [id, setId] = useState(0);

  const loadDados = async () => {
    setLoading(true);
    setOpenNot(false);
    setMensagem(null);
    setTexto(null);
    const r = await RequestGet("/Entities/Parameters/Details");
    if (r.Status === 200) {
      toast.info(r.Title);
      setData(r.Data);
      //setFinanceiro(r.financeiro);
      setLoading(false);
    } else {
      setOpenNot(true);
      setMensagem("Erro carregamento dos dados. Error: " + r?.Status);
      setLoading(false);
    }
  };

  useEffect(() => {
    loadDados();
  }, []);

  return (
    <>
      <Breadcrumb icon={<FaCogs />} title="Parâmetros" />
      <Row>
        <Col sm={6} className="align-items-center mb-3">
          <Row className="mb-5">
            <h5>
              <FaUserFriends /> Testemunhas
            </h5>
            <div className="card-group">
              <Card>
                <div className="list-group list-group-flush">
                  <div className="list-group-item d-flex align-items-center">
                    <div className="flex-fill">
                      <div>Testemunha 1</div>
                      <div className="text-gray-600">
                        {loading ? <Spinner size="sm" /> : data.Witness1}
                        &nbsp;
                      </div>
                      <div className="text-gray-600">
                        {loading ? <Spinner size="sm" /> : data.Witness1DocRg}
                        &nbsp;
                      </div>
                      <div className="text-gray-600">
                        {loading ? (
                          <Spinner size="sm" />
                        ) : (
                          FormatCpf(data?.Witness1DocCpf)
                        )}
                        &nbsp;
                      </div>
                    </div>
                    <div className="w-100px">
                      {!loading &&
                        (data.Witness2 !== "Error" ? (
                          <Link
                            className="btn btn-light btn-sm border w-100px"
                            onClick={() => {
                              setId(1);
                              setEditTestemunha(true);
                            }}
                          >
                            Alterar
                          </Link>
                        ) : (
                          ""
                        ))}
                    </div>
                  </div>
                  <div className="list-group-item d-flex align-items-center">
                    <div className="flex-fill">
                      <div>Testemunha 2</div>
                      <div className="text-gray-600">
                        {loading ? <Spinner size="sm" /> : data.Witness2}
                        &nbsp;
                      </div>
                      <div className="text-gray-600">
                        {loading ? <Spinner size="sm" /> : data.Witness2DocRg}
                        &nbsp;
                      </div>
                      <div className="text-gray-600">
                        {loading ? (
                          <Spinner size="sm" />
                        ) : (
                          FormatCpf(data.Witness2DocCpf)
                        )}
                        &nbsp;
                      </div>
                    </div>
                    <div>
                      {!loading &&
                        (data.Witness2 !== "Error" ? (
                          <Link
                            className="btn btn-light btn-sm border w-100px"
                            onClick={() => {
                              setId(2);
                              setEditTestemunha(true);
                            }}
                          >
                            Alterar
                          </Link>
                        ) : (
                          ""
                        ))}
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </Row>
          <Row className="mb-5">
            <h5>
              <FaDonate /> Financeiro
            </h5>
            <div className="card-group">
              <Card>
                <div className="list-group list-group-flush">
                  <div className="list-group-item d-flex align-items-center">
                    <div className="flex-fill">
                      <div>Multa</div>
                      <div className="text-gray-600">
                        {loading ? (
                          <Spinner size="sm" />
                        ) : (
                          FormatMoney(data.Fine)
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="list-group-item d-flex align-items-center">
                    <div className="flex-fill">
                      <div>Desconto</div>
                      <div className="text-gray-600">
                        {loading ? (
                          <Spinner size="sm" />
                        ) : (
                          FormatMoney(data.Discount)
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="list-group-item d-flex align-items-center">
                    <div className="flex-fill">
                      <div>Máximo de Parcelas</div>
                      <div className="text-gray-600">
                        {loading ? <Spinner size="sm" /> : data.MaxInstallment}
                      </div>
                    </div>
                  </div>
                  <div className="list-group-item d-flex align-items-center">
                    <div className="flex-fill">
                      <div>Dias da Entrada</div>
                      <div className="text-gray-600">
                        {loading ? <Spinner size="sm" /> : data.DaysEntry}
                      </div>
                    </div>
                  </div>
                </div>
                {!loading && (
                  <div className="card-footer  text-end bg-white"></div>
                )}
              </Card>
              <Card>
                <div className="list-group list-group-flush">
                  <div className="list-group-item d-flex align-items-center">
                    <div className="flex-fill">
                      <div>Juros</div>
                      <div className="text-gray-600">
                        {loading ? (
                          <Spinner size="sm" />
                        ) : (
                          FormatMoney(data.Interest)
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="list-group-item d-flex align-items-center">
                    <div className="flex-fill">
                      <div>Desconto Dias</div>
                      <div className="text-gray-600">
                        {loading ? <Spinner size="sm" /> : data.DiscountDays}
                      </div>
                    </div>
                  </div>
                  <div className="list-group-item d-flex align-items-center">
                    <div className="flex-fill">
                      <div>Valor Minimo de Parcela</div>
                      <div className="text-gray-600">
                        {loading ? (
                          <Spinner size="sm" />
                        ) : (
                          FormatMoney(data.MinValueInstallment)
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="list-group-item d-flex align-items-center">
                    <div className="flex-fill">
                      <div>Valor Minimo de Entrada</div>
                      <div className="text-gray-600">
                        {loading ? (
                          <Spinner size="sm" />
                        ) : (
                          FormatMoney(data.MinValueEntry)
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {!loading && (
                  <div className="card-footer  text-end bg-white">
                    <Link
                      className="btn btn-light border btn-sm w-100px"
                      onClick={() => setEditFinanceiro(true)}
                    >
                      Alterar
                    </Link>
                  </div>
                )}
              </Card>
            </div>
          </Row>
        </Col>
      </Row>
      {editTestemunha ? (
        <ParametersWitnessAlt
          show={editTestemunha}
          close={setEditTestemunha}
          id={id}
          data={data}
          setData={setData}
        />
      ) : null}
      {editFinanceiro ? (
        <ParametersFinancialAlt
          show={editFinanceiro}
          close={setEditFinanceiro}
          data={data}
          setData={setData}
        />
      ) : null}
      {openNot ? (
        <ModalNotifications
          show={openNot}
          close={setOpenNot}
          message={mensagem}
          texto={texto}
        />
      ) : null}
    </>
  );
};

export default Parameters;

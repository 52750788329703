import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import { Header } from './components';
import { useStateContext } from './contexts';
import { Home, Students, Schools, Responsables, Parameters, Managers, Users, Error, ManagementDebtsToNegociate, ManagementAgreements } from './pages';

import Authorization from './Authorization';
import { DecriptToken } from './functions/decriptToken';
import Login from './pages/login';
import LoginOut from './pages/login/out';

const Private = ({ children }) => {
  const { logado, setLogado } = useStateContext();

  let currentDate = new Date();
  const r = localStorage.getItem("_token")

  const exp = r !== null ? DecriptToken(r).exp : "none";

  if (exp === "none" || exp * 1000 < currentDate.getTime()) {
    localStorage.removeItem("_token")
  }

  return logado ? children : <Navigate to="/login" replace={true} />
}

function App() {
  const { logado, setLogado } = useStateContext();
  const { backdrop, setBackdrop } = useStateContext();
  const getMode = () => {
    return JSON.parse(localStorage.getItem("mode"))
  }
  const [dark, setMode] = useState(getMode())

  useEffect(() => {
    localStorage.setItem("mode", JSON.stringify(dark))

  }, [dark])

  useEffect(() => {
    const bdp = document.querySelectorAll(".modal-backdrop.fade.show");
    setBackdrop(bdp.length);
  })
  useEffect(() => { !!localStorage.getItem("_token") ? setLogado(true) : setLogado(false) }, [])

  return (
    <>
      <div className={dark ? "app" : "light"}>
        <BrowserRouter>
          {logado &&
            <>
              <Header />
            </>
          }
          <Container>
            <Routes>
              <Route exact path='/' element={(<Private><Authorization role={["all"]} ><Home /></Authorization></Private>)} />
              <Route path='/Students' element={(<Private><Authorization role={["all"]} ><Students /></Authorization></Private>)} />
              <Route path='/Schools' element={(<Private><Authorization role={["all"]} ><Schools /></Authorization></Private>)} />
              <Route path='/Responsables' element={(<Private><Authorization role={["all"]} ><Responsables /></Authorization></Private>)} />
              <Route path='/Parameters' element={(<Private><Authorization role={["all"]} ><Parameters /></Authorization></Private>)} />
              <Route path='/Managers' element={(<Private><Authorization role={["all"]} ><Managers /></Authorization></Private>)} />
              <Route path='/Users' element={(<Private><Authorization role={["all"]} ><Users /></Authorization></Private>)} />
              <Route path='/Management/Debts' element={(<Private><Authorization role={["all"]} ><ManagementDebtsToNegociate /></Authorization></Private>)} />
              <Route path='/Management/Agreements' element={(<Private><Authorization role={["all"]} ><ManagementAgreements /></Authorization></Private>)} />
              <Route path='/Login' element={(<Login />)} />
              <Route path='/LogOut' element={(<LoginOut />)} />
              <Route path='*' element={(<Error />)} />
            </Routes>
          </Container>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import { FaSave, FaSignOutAlt, FaUser } from "react-icons/fa";
import {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalNotifications,
} from "../../components/modal";
import zModal from "../../functions";
import ReactInputMask from "react-input-mask";
import RequestApi from "../../services";
import { toast } from "react-toastify";
import { RequestGet, RequestPost } from "../../requestApi";
import Msg from "../../components/errors/msg";

let d = {
  Id: "",
  Name: "",
  Cpf: "",
  Email: "",
  TypeEntityEnum: 0,
  IsActive: true,
};

const UsersNewEdit = (props) => {
  const [openNot, setOpenNot] = useState(false);
  const [txt, setTxt] = useState(null);
  const [msg, setMsg] = useState(null);
  const [error, setError] = useState(false);
  const [editar, setEditar] = useState(false);
  const [btCancel, setBtCancel] = useState(false);
  const [nw, setNw] = useState(true);
  const [dados, setDados] = useState(d);
  const [typesEnum, setTypesEnum] = useState([]);
  const [acordos, setAcordos] = useState([]);

  const dLoad = () => setDados(d);

  const loadDados = async () => {
    setOpenNot(true);
    setTxt("Carregando dados");
    setError(false);

    let url = "/New";
    var ovv = 0;

    if (props.id !== 0) {
      ovv = 1;
      url = "/Details/" + props.id;
      setTxt("Carregando dados do Usuário");
      setEditar(true);
      setNw(false);
    }

    const r = await RequestGet("Users" + url);

    if (r.Status === 200) {
      toast.success(r.Title);
      if (ovv === 1) {
        d = r.Data;
        dLoad();
      } else
        setDados({
          Id: "",
          Name: "",
          Cpf: "",
          Email: "",
          TypeEntityEnum: 0,
          IsActive: true,
        });
      setTypesEnum(r.Data.TypesEnums);
      setOpenNot(false);
    } else {
      setError(true);
      setMsg(<Msg dados={r.errors} />);
    }
  };
  const retirar = (e) => {
    let lmp = e.target.value.trim();
    setDados({ ...dados, [e.target.name]: lmp });
  };
  const onChange = (e) => {
    const { name, value } = e.target;
    setDados({ ...dados, [name]: value });
  };

  const saveDados = async () => {
    setOpenNot(true);
    setTxt("Salvando dados do Usuário");
    setMsg(null);
    setError(false);

    let url = "/Insert";

    if (props.id !== 0) url = "/UpDate/" + props.id;

    const r = await RequestPost("Users" + url, dados);

    if (r.Status === 200) {
      toast.success(r.Title);
      d = r.Data;
      props.setId(d.id);
      props.list();
      setOpenNot(false);
      setNw(false);
      setBtCancel(false);
      setEditar(true);
    } else {
      setMsg(<Msg dados={r.errors} />);
    }
  };

  const closeModal = () => props.close(false);

  const closeNot = () => {
    setOpenNot(false);
    if (error) props.close(false);
  };

  useEffect(() => {
    loadDados();
  }, [props.show]);

  const [tl, setTl] = useState(1060);
  useEffect(() => {
    setTl(zModal());
  }, []);

  return (
    <>
      <Modal
        show={props.show}
        onHide={closeModal}
        backdrop="static"
        keyboard={false}
        size="lg"
        style={{ zIndex: tl }}
      >
        <ModalHeader>
          <FaUser /> {props.title} {dados.Name}
        </ModalHeader>
        <ModalBody>
          <>
            <div style={{ minHeight: "250px" }}>
              <Tabs
                defaultActiveKey="home"
                id="uncontrolled-tab-example"
                className="mb-2 pb-0 pt-0"
              >
                <Tab eventKey="home" title="Dados">
                  <Form>
                    <Row className="pb-2">
                      {/* <Col sm={3} md={6} lg={3}>
                        <Form.Group controlId="login">
                          <Form.Label className="mb-0">
                            Login do Usuário{" "}
                            <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            size="sm"
                            style={{ textTransform: "lowercase" }}
                            value={dados.UserName}
                            //maxLength={15}
                            name="login"
                            onChange={retirar}
                            disabled={nw ? false : true}
                          />
                        </Form.Group>
                      </Col> */}
                      <Col sm={6} md={6} lg={6}>
                        <Form.Group controlId="nome">
                          <Form.Label className="mb-0">
                            Nome do Usuário{" "}
                            <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            size="sm"
                            style={{ textTransform: "uppercase" }}
                            value={dados.Name}
                            name="Name"
                            maxLength={50}
                            onChange={onChange}
                            disabled={editar}
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={3} md={6} lg={3}>
                        <Form.Group controlId="cpf">
                          <Form.Label className="mb-0">
                            CPF <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            as={ReactInputMask}
                            type="text"
                            mask="999.999.999-99"
                            maskChar=""
                            size="sm"
                            style={{ textTransform: "uppercase" }}
                            value={dados.Cpf}
                            name="Cpf"
                            onChange={onChange}
                            disabled={editar}
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={3} md={6} lg={3}>
                        <Form.Group controlId="ativo">
                          <Form.Label className="mb-1">&nbsp;</Form.Label>
                          {/* <Form.Check
                            type="checkbox"
                            size="sm"
                            style={{ textTransform: "lowercase" }}
                            defaultChecked={dados.ativo}
                            name="ativo"
                            disabled={editar}
                            label="Ativo"
                            onChange={onChange}
                          /> */}
                          {dados.IsActive && (
                            <Form.Check
                              type="checkbox"
                              defaultChecked={true}
                              onChange={() => (dados.IsActive = false)}
                              disabled={editar}
                              label="Ativo"
                            />
                          )}
                          {!dados.IsActive && (
                            <Form.Check
                              type="checkbox"
                              defaultChecked={false}
                              onChange={() => (dados.IsActive = true)}
                              disabled={editar}
                              label="Ativo"
                            />
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={6} md={6} lg={6}>
                        <Form.Group controlId="email">
                          <Form.Label className="mb-0">
                            Email <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            size="sm"
                            maxLength={150}
                            style={{ textTransform: "lowercase" }}
                            value={dados.Email}
                            name="Email"
                            onChange={onChange}
                            disabled={editar}
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={3} lg={3}>
                        <Form.Group controlId="tipo">
                          <Form.Label className="mb-0">
                            Tipo de Acesso{" "}
                            <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Select
                            size="sm"
                            onChange={onChange}
                            value={dados.TypeEntityEnum}
                            name="TypeEntityEnum"
                            disabled={editar}
                          >
                            <option disabled value={0} className="text-muted">
                              -- Selecione --
                            </option>
                            {typesEnum.map((t, index) => (
                              <option key={index} value={t.Id}>
                                {t.Name}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>
                </Tab>
                {!nw && (
                  <Tab
                    eventKey="acordos"
                    title="Acordos Realizados"
                    disabled={!editar}
                  >
                    <Row>
                      <Col
                        sm={12}
                        style={{
                          height: "360px",
                          overflowX: "hidden",
                          overflowY: "auto",
                        }}
                      >
                        <Table striped hover size="sm">
                          <thead>
                            <tr>
                              <th className="border-top-0 pt-0 pb-0">Acordo</th>
                              <th className="border-top-0 pt-0 pb-0">
                                Responsável
                              </th>
                              <th className="border-top-0 pt-0 pb-0">
                                Dt. Acordo
                              </th>
                              <th className="border-top-0 pt-0 pb-0">Valor</th>
                              <th className="border-top-0 pt-0 pb-0">Status</th>
                              <th className="border-top-0 pt-0 pb-0"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {acordos.length > 0 ? (
                              acordos.map((a, index) => (
                                <tr key={index}>
                                  <td className="p-0 mb-1">{a.id}</td>
                                  <td className="p-0 mb-1">{a.responsavel}</td>
                                  <td className="p-0 mb-1 text-center">
                                    {a.dtInsert}
                                  </td>
                                  <td className="p-0 mb-1">{a.valor}</td>
                                  <td className="p-0 mb-1">{a.status}</td>
                                  <td className="p-0 mb-1 text-center"></td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan={6} align="center">
                                  Nenhum acordo encontrado
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </Col>
                      {/* <Col sm={3}>
                        <Button
                          size="sm"
                          variant="secondary"
                        >
                          <FaUserFriends /> Inserir Responsável
                        </Button>
                      </Col> */}
                    </Row>
                  </Tab>
                )}
              </Tabs>
            </div>
          </>
        </ModalBody>
        <ModalFooter>
          {nw && !editar && (
            <>
              <Button variant="secondary" size="sm" onClick={closeModal}>
                <FaSignOutAlt /> Sair
              </Button>

              <Button variant="primary" size="sm" onClick={saveDados}>
                <FaSave /> Salvar
              </Button>
            </>
          )}
          {!nw && (
            <>
              {btCancel ? (
                <>
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={() => {
                      setBtCancel(false);
                      setEditar(true);
                      setDados(d);
                    }}
                  >
                    <FaSignOutAlt /> Cancelar
                  </Button>
                  <Button variant="primary" size="sm" onClick={saveDados}>
                    <FaSave /> Salvar
                  </Button>
                </>
              ) : (
                <>
                  <Button variant="secondary" size="sm" onClick={closeModal}>
                    <FaSignOutAlt /> Sair
                  </Button>
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={() => {
                      setEditar(false);
                      setBtCancel(true);
                    }}
                  >
                    <FaSave /> Alterar
                  </Button>
                </>
              )}
            </>
          )}
        </ModalFooter>
      </Modal>
      {openNot ? (
        <ModalNotifications
          show={openNot}
          close={closeNot}
          texto={txt}
          message={msg}
        />
      ) : null}
    </>
  );
};

export default UsersNewEdit;

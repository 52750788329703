import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { FaEye, FaHandHoldingUsd, FaPlus, FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Breadcrumb } from "../../components";
import { RequestGet } from "../../requestApi";
import ManagersNewEdit from "./newedit";

const Managers = () => {
  const [managers, setManagers] = useState([]);
  const [id, setId] = useState(0);
  const [newEdit, setNewEdit] = useState(false);
  const [erroGet, setErroGet] = useState(false);
  const [msgErro, setMsgErro] = useState("");

  const [sending, setSending] = useState(false);
  const [search, setSearch] = useState("");

  const list = async () => {
    setErroGet(false);
    setMsgErro("");
    setMsgErro("");

    setSending(true);

    const r = await RequestGet("/Managers/List?search=" + search);

    if (r.Status === 0) {
      setErroGet(true);
      setMsgErro(r.Title);
    }
    setManagers(r.Data);
    setSending(false);
  };
  const handleSearch = (e) => {
    //e.preventDefault();
    if (e.key === "Enter") {
      list();
    }
  };

  useEffect(() => {
    list();
  }, []);

  return (
    <>
      <Breadcrumb icon={<FaHandHoldingUsd />} title="Gestoras" />
      <Row>
        <Col sm={12}>
          <Card className="shadow">
            <Card.Body>
              <Row>
                <Col sm={12}>
                  <Row>
                    <Col sm={4} className="mb-3">
                      <InputGroup>
                        <Form.Control
                          type="text"
                          placeholder="Buscar"
                          aria-label="Buscar"
                          size="sm"
                          onKeyDown={handleSearch}
                          onChange={(e) =>
                            setSearch(e.target.value.toUpperCase())
                          }
                          defaultValue={search}
                          autoFocus={true}
                          style={{ textTransform: "uppercase" }}
                        />
                        <Button variant="primary" size="sm" onClick={list}>
                          <FaSearch />
                        </Button>
                      </InputGroup>
                    </Col>
                    <Col sm={8} className="mb-3">
                      {sending && (
                        <Spinner
                          animation="border"
                          size="sm"
                          className="float-start mt-2"
                        />
                      )}
                      {erroGet && (
                        <span className="text-danger float-start mt-1">
                          {msgErro}
                        </span>
                      )}
                      <Button
                        variant="primary"
                        size="sm"
                        className="float-end"
                        onClick={() => {
                          setId("00000000-0000-0000-0000-000000000000");
                          setNewEdit(true);
                        }}
                      >
                        <FaPlus />
                        &nbsp;Nova Gestora
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <Table striped hover size="sm">
                    <thead>
                      <tr>
                        <th scope="col" className="ps-2">
                          Sigla
                        </th>
                        <th scope="col">Nome</th>
                        <th scope="col">Acordos</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {managers?.map((g, index) => (
                        <tr key={index}>
                          <td className="ps-2">{g.Acronym}</td>
                          <td>{g.Name}</td>
                          <td>{g.QtyAgreements}</td>
                          <td>
                            <Link
                              onClick={() => {
                                setId(g.ManagerId);
                                setNewEdit(true);
                              }}
                            >
                              <FaEye color="#003768" />
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {newEdit ? (
        <ManagersNewEdit
          show={newEdit}
          close={setNewEdit}
          managerId={id}
          setId={setId}
          upList={list}
        />
      ) : null}
    </>
  );
};

export default Managers;

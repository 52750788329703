import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { FaMoneyBillWave, FaMoneyCheckAlt, FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";
import { FormatarCpf } from "../../../functions/cpf";
import FormatMoney from "../../../functions/formatmoney";
import { RequestGet } from "../../../requestApi";
import ManagementNewAgreement from "./newAgreement";

const ManagementDebtsToNegociate = () => {
  const [newAgreement, setNewAgreement] = useState(false);
  const [cpf, setCpf] = useState(null);
  const [msgErro, setMsgErro] = useState("");
  const [debts, setDebts] = useState([]);
  const [sending, setSending] = useState(false);
  const [search, setSearch] = useState("");

  const list = async () => {
    setMsgErro("");
    if (search.trim().length > 1) {
      setSending(true);

      const r = await RequestGet(
        "/Responsables/Debts/List/ToNegociate?search=" + search
      );

      if (r.Status === 200) {
        setDebts(r.Data);
        if (r.Data?.length === 0) {
          setMsgErro(r.message);
        }
      } else {
        setMsgErro(r?.errors[0]);
      }
    }
    setSending(false);
  };

  const handleSearch = (e) => {
    //e.preventDefault();
    if (e.key === "Enter") {
      list();
    }
  };

  return (
    <>
      <ul className="nav pt-3">
        <li className="nav-item text-primary nav-link ps-1">
          <h4>
            <strong>
              <FaMoneyCheckAlt /> Negociar Débitos
            </strong>
          </h4>
        </li>
      </ul>
      <Row>
        <Col sm={12}>
          <Card className="shadow">
            <Card.Body>
              <Row>
                <Col sm={12}>
                  <Row>
                    <Col sm={4} className="mb-3">
                      <InputGroup>
                        <Form.Control
                          type="text"
                          placeholder="Buscar"
                          aria-label="Buscar"
                          size="sm"
                          onKeyDown={handleSearch}
                          onChange={(e) =>
                            setSearch(e.target.value.toUpperCase())
                          }
                          defaultValue={search}
                          autoFocus={true}
                          style={{ textTransform: "uppercase" }}
                        />
                        <Button variant="primary" size="sm" onClick={list}>
                          <FaSearch />
                        </Button>
                      </InputGroup>
                    </Col>
                    <Col sm={8} className="mb-3">
                      {sending && (
                        <Spinner
                          animation="border"
                          size="sm"
                          className="float-start mt-2"
                        />
                      )}
                      {msgErro !== "" && (
                        <span className="text-danger float-start mt-1">
                          {msgErro}
                        </span>
                      )}
                      {/* <Button
                        variant="primary"
                        size="sm"
                        className="float-end"
                        onClick={() => {
                          setIdColegio(0);
                          setCodAluno(0);
                          setNewAluno(true);
                          setNewEdit(true);
                        }}
                      >
                        <FaPlus />
                        &nbsp;Novo Aluno
                      </Button> */}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <Table striped hover size="sm">
                    <thead>
                      <tr>
                        <th scope="col" className="ps-2">
                          Responsável
                        </th>
                        <th scope="col">CPF</th>
                        <th scope="col" className="text-center">
                          Dt. Vencimento
                        </th>
                        <th scope="col">Valor</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {debts.map((d, index) => (
                        <tr key={index}>
                          <td className="ps-2">{d.ResponsableName}</td>
                          <td>{FormatarCpf(d.ResponsableId)}</td>
                          <td className="text-center">
                            {new Date(d.DtPaydue).toLocaleDateString("pt-BR")}
                          </td>
                          <td align="right" className="me-5">
                            {FormatMoney(d.Value)}
                          </td>
                          <td className="ms-5">
                            &nbsp; &nbsp;
                            <Link
                              onClick={() => {
                                setNewAgreement(true);
                                setCpf(d.ResponsableId);
                              }}
                              title="Negociar Débito"
                            >
                              <FaMoneyBillWave color="#003768" />
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {newAgreement ? (
        <ManagementNewAgreement
          show={newAgreement}
          close={setNewAgreement}
          new={newAgreement}
          responsableId={cpf}
          list={list}
        />
      ) : null}
    </>
  );
};

export default ManagementDebtsToNegociate;

import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { FaEye, FaPlus, FaSearch, FaUserFriends } from "react-icons/fa";
import { Link } from "react-router-dom";
import { RequestGet } from "../../requestApi";
import RequestApi from "../../services";
import NewEdit from "./newedit";
import FormatCpf from "../../functions/formatCpf";

const Responsables = () => {
  const [newResponsavel, setNewResponsavel] = useState(false);
  const [responsaveis, setResponsaveis] = useState([]);
  const [idResponsavel, setIdResponsavel] = useState("n");
  const [newEdit, setNewEdit] = useState(false);
  const [erroGet, setErroGet] = useState(false);
  const [msgErro, setMsgErro] = useState("");

  const [sending, setSending] = useState(false);

  const [search, setSearch] = useState("");

  const list = async () => {
    setErroGet(false);
    setMsgErro("");
    setMsgErro("");
    if (search.trim().length > 1) {
      setSending(true);

      const r = await RequestGet("/Responsables/List?search=" + search);

      if (r.Status === 200) {
        setErroGet(true);
        setMsgErro(r.Title);
      }
      setResponsaveis(r.Data);
      setSending(false);
      if (r.Data?.length === 0) {
        setErroGet(true);
        setMsgErro("Nenhum resultado encontrado");
      }
    }
  };
  const handleSearch = (e) => {
    //e.preventDefault();
    if (e.key === "Enter") {
      list();
    }
  };
  const handleNewResponsavel = (id) => {
    //setNewResponsavel(false);

    if (id !== "n" && id.length === 11) {
      setNewEdit(false);
      setIdResponsavel(id);
      list();
      //setNewResponsavel(true);
    } else {
      setNewResponsavel(false);
    }
  };
  return (
    <>
      <ul className="nav pt-3">
        <li className="nav-item text-primary nav-link ps-1">
          <h4>
            <strong>
              <FaUserFriends /> Responsáveis
            </strong>
          </h4>
        </li>
      </ul>
      <Row>
        <Col sm={12}>
          <Card className="shadow">
            <Card.Body>
              <Row>
                <Col sm={12}>
                  <Row>
                    <Col sm={4} className="mb-3">
                      <InputGroup>
                        <Form.Control
                          type="text"
                          placeholder="Buscar"
                          aria-label="Buscar"
                          size="sm"
                          onKeyDown={handleSearch}
                          onChange={(e) =>
                            setSearch(e.target.value.toUpperCase())
                          }
                          defaultValue={search}
                          autoFocus={true}
                          style={{ textTransform: "uppercase" }}
                        />
                        <Button variant="primary" size="sm" onClick={list}>
                          <FaSearch />
                        </Button>
                      </InputGroup>
                    </Col>
                    <Col sm={8} className="mb-3">
                      {sending && (
                        <Spinner
                          animation="border"
                          size="sm"
                          className="float-start mt-2"
                        />
                      )}
                      {erroGet && (
                        <span className="text-danger float-start mt-1">
                          {msgErro}
                        </span>
                      )}
                      <Button
                        variant="primary"
                        size="sm"
                        className="float-end"
                        onClick={() => {
                          setIdResponsavel("n");
                          setNewResponsavel(true);
                          setNewEdit(true);
                        }}
                      >
                        <FaPlus />
                        &nbsp;Novo Responsável
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <Table striped hover size="sm">
                    <thead>
                      <tr>
                        <th scope="col" className="ps-2">
                          Nome
                        </th>
                        <th scope="col">CPF</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {responsaveis?.map((r, index) => (
                        <tr key={index}>
                          <td className="ps-2">{r.Name}</td>
                          <td>{FormatCpf(r.ResponsableId)}</td>
                          <td>
                            <Link
                              onClick={() => {
                                setIdResponsavel(r.ResponsableId);
                                setNewResponsavel(true);
                                setNewEdit(false);
                              }}
                            >
                              <FaEye color="#003768" />
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {newResponsavel ? (
        <NewEdit
          show={newResponsavel}
          new={newEdit}
          responsableId={idResponsavel}
          handleNewResponsavel={handleNewResponsavel}
        />
      ) : null}
    </>
  );
};

export default Responsables;


const DecimalToBr = (v) => {
    let nv = parseFloat(v)

    if (isNaN(nv))
        nv = 0.00

    let vlr = new Intl.NumberFormat("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        currency: "BRL",
    }).format(nv)

    return vlr;
}

const BrToDecimal = (v) => {
    let pv = v.replace(".", "")

    let nv = parseFloat(pv.replace(",", "."))

    if (isNaN(nv))
        nv = 0.00

    return nv;
}

const InputBr = (i) => {
    var v = i.replace(/[\D]+/g, "");
    v = (v / 100).toFixed(2) + '';
    v = v.replace(".", ",");
    v = v.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    return v
}

export { DecimalToBr, BrToDecimal, InputBr }
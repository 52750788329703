import React from "react";
import { Modal } from "react-bootstrap";

const ModalHeader = ({ children }) => {
  return (
    // <div class="pt-2 ps-4 pb-0 pe-4 modal-header">
    //   <div class="h6 modal-title h4">Novo Aluno&nbsp;</div>
    //   <button type="button" class="btn-close" aria-label="Close"></button>
    // </div>
    <Modal.Header closeButton className="pt-2 ps-4 pb-0 pe-4">
      <Modal.Title className="h6">{children}&nbsp;</Modal.Title>
    </Modal.Header>
  );
};

export default ModalHeader;

import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  InputGroup,
  Modal,
  Row,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import {
  FaEdit,
  FaSave,
  FaSearch,
  FaSignOutAlt,
  FaUserFriends,
} from "react-icons/fa";
import ReactInputMask from "react-input-mask";
import { toast } from "react-toastify";
import Msg from "../../components/errors/msg";
//import InputMask, { ReactInputMask } from "react-input-mask";
import Jmodal from "../../components/modal/jmodal";
import zModal from "../../functions";
import { toDateTimeBr } from "../../functions/datetimes";
import FormatMoney from "../../functions/formatmoney";
import { LeftPad } from "../../functions/leftPad";
import { RequestGet, RequestPost } from "../../requestApi";

var dLoad = {};

const NewEdit = (props) => {
  const [mensagem, setMensagem] = useState(null);
  const [txt, setTxt] = useState(null);
  const [openNot, setOpenNot] = useState(false);
  const [editar, setEditar] = useState(false);
  const [newCadastro, setNewCadastro] = useState(false);
  const [debts, setDebts] = useState([]);
  const [erroLoad, setErroLoad] = useState(false);
  const [titulo, setTitulo] = useState("");

  //#region Dados Responsavel
  const [docCpf, setDocCpf] = useState("");
  const [name, setName] = useState("");
  const [docRg, setDocRg] = useState("");
  const [gender, setGender] = useState("");
  const [maritalStatusEnum, setMaritalStatusEnum] = useState(null);
  const [religionId, setReligionId] = useState(null);
  const [zipCode, setZipCode] = useState("");
  const [address, setAddress] = useState("");
  const [number, setNumber] = useState("");
  const [complement, setComplement] = useState("");
  const [district, setDistrict] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [phoneNumber1, setPhoneNumber1] = useState("");
  const [phoneNumber2, setPhoneNumber2] = useState("");
  const [email, setEmail] = useState("");
  const [obs, setObs] = useState("");
  const [agreements, setAgreements] = useState([]);
  const [genders, setGenders] = useState([]);
  const [maritalStatus, setMaritalStatus] = useState([]);
  const [religions, setReligions] = useState([]);
  const [ufs, setUfs] = useState([]);
  //#endregion

  const closeModal = () => {
    setOpenNot(false);
    if (erroLoad && newCadastro) props.handleNewResponsavel("n");
  };

  const handleCancel = () => {
    setEditar(true);
    handledLoad();
  };

  const handledLoad = () => {
    //setIdResponsavel(dLoad.id);
    setDocCpf(dLoad.ResponsableId);
    setName(dLoad.Name);
    setDocRg(dLoad.DocRg);
    setGender(dLoad.GenderEnum);
    setMaritalStatusEnum(dLoad.MaritalStatusEnum);
    setReligionId(dLoad.ReligionId);
    setZipCode(dLoad.ZipCode);
    setAddress(dLoad.Address);
    setNumber(dLoad.Number);
    setComplement(dLoad.Complement);
    setDistrict(dLoad.District);
    setCity(dLoad.City);
    setState(dLoad.State);
    setPhoneNumber1(dLoad.PhoneNumber1);
    setPhoneNumber2(dLoad.PhoneNumber2);
    setEmail(dLoad.Email);
    setObs(dLoad.Obs);
    setDebts(dLoad.Debts);
  };

  const handleToSave = async () => {
    setTxt("Enviando dados...");
    setOpenNot(true);
    setMensagem(null);

    let error = [];
    var c = 0;
    var cp = "";

    if (docCpf === null)
      error.push(
        <span key={c++}>
          CPF inválido
          <br key={1} />
        </span>
      );
    else {
      let regex = /\d/g;
      cp = docCpf?.trim().length > 0 ? docCpf?.match(regex).join("") : docCpf;

      if (cp?.trim().length < 11)
        error.push(
          <span key={c++}>
            CPF inválido
            <br key={1} />
          </span>
        );
    }

    if (name?.trim().length < 2 || name === undefined || name === null)
      error.push(
        <span key={c++}>
          Nome deve ter ao menos 2 caracteres
          <br key={1} />
        </span>
      );

    if (gender === 0)
      error.push(
        <span key={c++}>
          Sexo deve ser informado
          <br key={1} />
        </span>
      );

    //Se tem erros
    if (error.length > 0) {
      setMensagem(error);
      return;
    }

    //Tudo ok vamos gravar

    const dados = {
      name,
      ResponsableId: cp,
      docRg,
      GenderEnum: gender,
      MaritalStatusEnum:
        maritalStatusEnum === null ? null : parseInt(maritalStatusEnum),
      ReligionId: religionId === null ? null : parseInt(religionId),
      zipCode,
      address,
      number,
      complement,
      district,
      city,
      state,
      phoneNumber1,
      phoneNumber2,
      email,
      obs,
    };

    //se props.responsableId for igual a n cadastra um novo se for diferente entende como alteracao de dados
    const r = await RequestPost(
      "/Responsables/" +
        (props.responsableId === "n"
          ? "Insert"
          : "UpDate/" + dados.ResponsableId),
      dados
    );

    setMensagem(r.Title);
    if (r.Status === 200) {
      props.handleNewResponsavel(dados.ResponsableId);
      if (props.responsavel) props.responsavel(dados.name);

      setNewCadastro(false);
      setEditar(true);
      dLoad = r.Data;
      setOpenNot(false);
    } else {
      setMensagem(<Msg dados={r.errors} />);
      setErroLoad(false);
    }
  };

  const handleClose = () => {
    props.handleNewResponsavel("n");
  };

  async function getDados() {
    var url = "/New";
    setTxt("Carregando dados...");
    setOpenNot(true);
    setMensagem(null);
    var ovv = 0;

    if (props.show && props.new) {
      setNewCadastro(true);
      url = "/New";
    }
    //Altercao de Cadastro
    else if (props.show && !props.new && props.responsableId.length > 0) {
      url = "/Details/" + props.responsableId;
      setNewCadastro(false);
      setEditar(true);
      ovv = 1;
    }

    const r = await RequestGet("/Responsables" + url);
    toast.info(r.Title);
    if (r.Status === 200) {
      if (ovv === 1) {
        dLoad = r.Data;
        setTitulo("Responsável: " + r.Data.Name);
      } else {
        setTitulo("Novo Responsável");
      }
      setAgreements(r.Data.Agreements);
      setGenders(r.Data.Genders);
      //setReligioes(r.religioes);
      setMaritalStatus(r.Data.MaritalStatus);
      //setUfs(r.ufs);
      handledLoad();
      setOpenNot(false);
    } else {
      setMensagem(<Msg dados={r.errors} />);
      setErroLoad(true);
    }
  }

  useEffect(() => {
    getDados();
  }, [props.show]);

  const [tl, setTl] = useState(1060);

  useEffect(() => {
    setTl(zModal());
  }, []);

  return (
    <>
      <Modal
        show={props.show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        style={{ zIndex: tl }}
      >
        <Modal.Header closeButton className="pt-2 ps-4 pb-0 pe-4">
          <Modal.Title className="h6">
            <FaUserFriends /> {titulo}&nbsp;
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="ps-4 pt-2 pe-4" style={{ minHeight: "487px" }}>
          <>
            <Tabs
              defaultActiveKey="home"
              id="uncontrolled-tab-example"
              className="mb-2 pb-0 pt-0"
            >
              <Tab eventKey="home" title="Dados" style={{ minHeight: "360px" }}>
                <Form>
                  <Row className="pb-2">
                    <Col sm={3}>
                      <Form.Group controlId="cpf">
                        <Form.Label className="mb-0">
                          CPF <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          as={ReactInputMask}
                          type="text"
                          size="sm"
                          onChange={(e) =>
                            setDocCpf(e.target.value.toUpperCase())
                          }
                          value={docCpf}
                          style={{ textTransform: "uppercase" }}
                          mask="999.999.999-99"
                          maskChar=""
                          disabled={newCadastro ? false : true}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group controlId="nome">
                        <Form.Label className="mb-0">
                          Nome <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          size="sm"
                          maxLength={50}
                          // onChange={(e) =>
                          //   (dados.nome = e.target.value.toUpperCase())
                          // }
                          onChange={(e) =>
                            setName(e.target.value.toUpperCase())
                          }
                          value={name}
                          style={{ textTransform: "uppercase" }}
                          disabled={editar}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group controlId="rg">
                        <Form.Label className="mb-0">RG</Form.Label>
                        <Form.Control
                          type="text"
                          size="sm"
                          maxLength={20}
                          onChange={(e) => {
                            setDocRg(e.target.value.toUpperCase());
                          }}
                          value={docRg}
                          style={{ textTransform: "uppercase" }}
                          disabled={editar}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="pb-2">
                    <Col sm={2}>
                      <Form.Group controlId="sexo">
                        <Form.Label className="mb-0">
                          Sexo <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          size="sm"
                          onChange={(e) =>
                            setGender(e.target.value.toUpperCase())
                          }
                          value={gender === "" ? 0 : gender}
                          disabled={editar}
                        >
                          <option disabled value={0} className="text-muted">
                            -- Sexo --
                          </option>
                          {genders.map((s, index) => (
                            <option
                              key={index}
                              value={s.Id}
                              //selected={s.id === sexo ? true : false}
                            >
                              {s.Name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group controlId="estadoCivil">
                        <Form.Label className="mb-0">Estado Civil</Form.Label>
                        <Form.Select
                          size="sm"
                          onChange={(e) => setMaritalStatusEnum(e.target.value)}
                          value={
                            maritalStatusEnum === null ? 0 : maritalStatusEnum
                          }
                          disabled={editar}
                        >
                          <option
                            disabled
                            value={0}
                            //selected={idEstadoCivil === null ? true : false}
                            className="text-muted"
                          >
                            -- Estado Civil --
                          </option>
                          {maritalStatus.map((ec, index) => (
                            <option
                              key={index}
                              value={ec.Id}
                              //selected={s.id === idEstadoCivil ? true : false}
                            >
                              {ec.Name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col sm={4}>
                      <Form.Group controlId="religiao">
                        <Form.Label className="mb-0">Religião</Form.Label>
                        <Form.Select
                          size="sm"
                          onChange={(e) => setReligionId(e.target.value)}
                          value={religionId === null ? 0 : religionId}
                          disabled={editar}
                        >
                          <option disabled value={0} className="text-muted">
                            -- Religião --
                          </option>
                          {religions.map((r, index) => (
                            <option
                              key={index}
                              value={r.id}
                              //selected={s.id === idReligiao ? true : false}
                            >
                              {r.nome.toUpperCase()}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group controlId="cep">
                        <Form.Label className="mb-0">CEP</Form.Label>
                        <InputGroup>
                          <Form.Control
                            as={ReactInputMask}
                            type="text"
                            size="sm"
                            onChange={(e) =>
                              setZipCode(e.target.value.toUpperCase())
                            }
                            value={zipCode}
                            style={{ textTransform: "uppercase" }}
                            mask="99999-999"
                            maskChar=""
                            disabled={editar}
                          />
                          <Button
                            variant="light"
                            size="sm"
                            onClick={() => alert("opa")}
                            className="border"
                            disabled={editar}
                          >
                            <FaSearch color="#babbbc" />
                          </Button>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="pb-2">
                    <Col sm={6}>
                      <Form.Group controlId="endereco">
                        <Form.Label className="mb-0">Endereço</Form.Label>
                        <Form.Control
                          type="text"
                          size="sm"
                          maxLength={50}
                          onChange={(e) => {
                            setAddress(e.target.value.toUpperCase());
                          }}
                          value={address}
                          style={{ textTransform: "uppercase" }}
                          disabled={editar}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={2}>
                      <Form.Group controlId="num">
                        <Form.Label className="mb-0">Nº</Form.Label>
                        <Form.Control
                          type="text"
                          size="sm"
                          maxLength={10}
                          onChange={(e) => {
                            setNumber(e.target.value.toUpperCase());
                          }}
                          value={number}
                          style={{ textTransform: "uppercase" }}
                          disabled={editar}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={4}>
                      <Form.Group controlId="compl">
                        <Form.Label className="mb-0">Complemento</Form.Label>
                        <Form.Control
                          type="text"
                          size="sm"
                          maxLength={40}
                          onChange={(e) =>
                            setComplement(e.target.value.toUpperCase())
                          }
                          value={complement}
                          style={{ textTransform: "uppercase" }}
                          disabled={editar}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="pb-2">
                    <Col sm={4}>
                      <Form.Group controlId="bairro">
                        <Form.Label className="mb-0">Bairro</Form.Label>
                        <Form.Control
                          type="text"
                          size="sm"
                          maxLength={40}
                          onChange={(e) => {
                            setDistrict(e.target.value.toUpperCase());
                          }}
                          value={district}
                          style={{ textTransform: "uppercase" }}
                          disabled={editar}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group controlId="cidade">
                        <Form.Label className="mb-0">Cidade</Form.Label>
                        <Form.Control
                          type="text"
                          size="sm"
                          maxLength={50}
                          onChange={(e) =>
                            setCity(e.target.value.toUpperCase())
                          }
                          value={city}
                          style={{ textTransform: "uppercase" }}
                          disabled={editar}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={2}>
                      <Form.Group controlId="estado">
                        <Form.Label className="mb-0">Estado</Form.Label>
                        <Form.Select
                          size="sm"
                          onChange={(e) =>
                            setState(e.target.value.toUpperCase())
                          }
                          value={state === "" ? 0 : state}
                          disabled={editar}
                        >
                          <option disabled value={0} className="text-muted">
                            -- Estado --
                          </option>
                          {ufs.map((s, index) => (
                            <option
                              key={index}
                              value={s.id}
                              //selected={s.id === sexo ? true : false}
                            >
                              {s.id.toUpperCase()}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="pb-2">
                    <Col sm={3}>
                      <Form.Group controlId="tel1">
                        <Form.Label className="mb-0">Tel 1</Form.Label>
                        <Form.Control
                          as={ReactInputMask}
                          type="text"
                          size="sm"
                          onChange={(e) =>
                            setPhoneNumber1(e.target.value.toUpperCase())
                          }
                          value={phoneNumber1}
                          style={{ textTransform: "uppercase" }}
                          mask="(99) 999999999"
                          maskChar=""
                          disabled={editar}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group controlId="tel2">
                        <Form.Label className="mb-0">Tel 2</Form.Label>
                        <Form.Control
                          as={ReactInputMask}
                          type="text"
                          size="sm"
                          onChange={(e) =>
                            setPhoneNumber2(e.target.value.toUpperCase())
                          }
                          value={phoneNumber2}
                          style={{ textTransform: "uppercase" }}
                          mask="(99) 999999999"
                          maskChar=""
                          disabled={editar}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group controlId="email">
                        <Form.Label className="mb-0">Email</Form.Label>
                        <Form.Control
                          type="email"
                          size="sm"
                          maxLength={150}
                          onChange={(e) => {
                            setEmail(e.target.value.toLowerCase());
                          }}
                          value={email}
                          style={{ textTransform: "lowercase" }}
                          disabled={editar}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="pb-2">
                    <Col sm={12}>
                      <Form.Group controlId="email">
                        <Form.Label className="mb-0">Obs.</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          size="sm"
                          onChange={(e) => {
                            setObs(e.target.value.toUpperCase());
                          }}
                          value={obs}
                          style={{ textTransform: "uppercase" }}
                          disabled={editar}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      {!props.new && !editar && (
                        <Button
                          variant="secondary"
                          size="sm"
                          onClick={handleCancel}
                        >
                          <FaSignOutAlt /> Cancelar
                        </Button>
                      )}
                      {!props.new && !editar && (
                        <Button
                          variant="primary"
                          size="sm"
                          onClick={handleToSave}
                          className="ms-2"
                        >
                          <FaSave /> Gravar
                        </Button>
                      )}
                      {!props.new && editar && (
                        <Button
                          variant="light"
                          size="sm"
                          onClick={() => setEditar(false)}
                        >
                          <FaEdit /> Alterar
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Form>
              </Tab>
              {!newCadastro && (
                <Tab
                  eventKey="financeiro"
                  title="Financeiro"
                  disabled={!editar}
                >
                  <Row>
                    <Col
                      sm={12}
                      style={{
                        height: "360px",
                        overflowX: "hidden",
                        overflowY: "auto",
                      }}
                    >
                      <Table striped hover size="sm">
                        <thead>
                          <tr>
                            <th className="border-top-0 pt-0 pb-0 text-center">
                              Col.
                            </th>
                            <th className="border-top-0 pt-0 pb-0 text-center">
                              Aluno
                            </th>
                            <th className="border-top-0 pt-0 pb-0">Nome</th>
                            <th className="border-top-0 pt-0 pb-0">
                              Descrição
                            </th>
                            <th className="border-top-0 pt-0 pb-0 text-center">
                              Par.
                            </th>
                            <th className="border-top-0 pt-0 pb-0 text-center">
                              Vencimento
                            </th>
                            <th className="border-top-0 pt-0 pb-0 text-center">
                              Dias
                            </th>
                            <th className="border-top-0 pt-0 pb-0 text-end">
                              Valor
                            </th>
                            <th className="border-top-0 pt-0 pb-0 text-end">
                              Multa
                            </th>
                            <th className="border-top-0 pt-0 pb-0 text-end">
                              Juros
                            </th>
                            <th className="border-top-0 pt-0 pb-0 text-end">
                              Corrigido
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {debts?.length > 0 ? (
                            debts.map((d, index) => (
                              <tr key={index}>
                                <td className="p-0 mb-1 text-center">
                                  {d.SchoolCode}
                                </td>
                                <td className="p-0 mb-1 text-center">
                                  {d.StudentCode}
                                </td>
                                <td className="p-0 mb-1 text-start">
                                  {d.StudentName}
                                </td>
                                <td className="p-0 mb-1 text-start">
                                  {d.TypeDebtName}
                                </td>
                                <td className="p-0 mb-1 text-center">
                                  {d.Installment}
                                </td>
                                <td className="p-0 mb-1 text-center">
                                  {new Date(d.DtPaydue).toLocaleDateString(
                                    "pt-BR"
                                  )}
                                </td>
                                <td className="p-0 mb-1 text-center">
                                  {d.Days}
                                </td>
                                <td className="p-0 mb-1 text-end">
                                  {new Intl.NumberFormat("pt-BR", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                    currency: "BRL",
                                  }).format(d.Value)}
                                </td>
                                <td className="p-0 mb-1 text-end">
                                  {new Intl.NumberFormat("pt-BR", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                    currency: "BRL",
                                  }).format(d.Fine)}
                                </td>
                                <td className="p-0 mb-1 text-end">
                                  {new Intl.NumberFormat("pt-BR", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                    currency: "BRL",
                                  }).format(d.Interest)}
                                </td>
                                <td className="p-0 mb-1 text-end">
                                  {new Intl.NumberFormat("pt-BR", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                    currency: "BRL",
                                  }).format(d.ValueCalculated)}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={11} align="center">
                                Nenhum débito encontrado
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={4} className="border-end">
                      &nbsp;
                    </Col>
                    <Col sm={2} className="border-end">
                      <div className="text-gray-500">Total Débito</div>
                      <div className="float-end" id="TotalValor">
                        {debts?.length > 0 &&
                          new Intl.NumberFormat("pt-BR", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                            currency: "BRL",
                          }).format(
                            debts.reduce(
                              (total, debito) => total + debito.Value,
                              0
                            )
                          )}
                        {debts?.length === 0 && "0,00"}
                      </div>
                    </Col>
                    <Col sm={2} className="border-end">
                      <div className="text-gray-500">Total Multa</div>
                      <div className="float-end" id="TotalValor">
                        {debts?.length > 0 &&
                          new Intl.NumberFormat("pt-BR", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                            currency: "BRL",
                          }).format(
                            debts.reduce(
                              (total, debito) => total + debito.Fine,
                              0
                            )
                          )}
                        {debts?.length === 0 && "0,00"}
                      </div>
                    </Col>
                    <Col sm={2} className="border-end">
                      <div className="text-gray-500">Total Juros</div>
                      <div className="float-end" id="TotalValor">
                        {debts?.length > 0 &&
                          new Intl.NumberFormat("pt-BR", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                            currency: "BRL",
                          }).format(
                            debts.reduce(
                              (total, debito) => total + debito.Interest,
                              0
                            )
                          )}
                        {debts?.length === 0 && "0,00"}
                      </div>
                    </Col>
                    <Col sm={2} className="border-end">
                      <div className="text-gray-500">Total Corrigido</div>
                      <div className="float-end" id="TotalValor">
                        {debts?.length > 0 &&
                          new Intl.NumberFormat("pt-BR", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                            currency: "BRL",
                          }).format(
                            debts.reduce(
                              (total, debito) => total + debito.ValueCalculated,
                              0
                            )
                          )}
                        {debts?.length === 0 && "0,00"}
                      </div>
                    </Col>
                  </Row>
                </Tab>
              )}
              {!newCadastro && (
                <Tab eventKey="acordos" title="Acordos" disabled={!editar}>
                  <Row>
                    <Col
                      sm={10}
                      style={{
                        height: "360px",
                        overflowX: "hidden",
                        overflowY: "auto",
                      }}
                    >
                      <Table striped hover size="sm">
                        <thead>
                          <tr>
                            <th className="border-top-0 pt-0 pb-0">
                              Nº Acordo
                            </th>
                            <th className="border-top-0 pt-0 pb-0">
                              Dt. Acordo
                            </th>
                            <th className="border-top-0 pt-0 pb-0">Valor</th>
                            <th className="border-top-0 pt-0 pb-0">Status</th>
                            <th className="border-top-0 pt-0 pb-0">Gestora</th>
                            <th className="border-top-0 pt-0 pb-0"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {agreements.length > 0 ? (
                            agreements.map((a, index) => (
                              <tr key={index}>
                                <td className="p-0 mb-1">
                                  {LeftPad(a.AgreementId, 6)}
                                </td>
                                <td className="p-0 mb-1">
                                  {toDateTimeBr(a.DtInsert)}
                                </td>
                                <td className="p-0 mb-1 text-end pe-3">
                                  {FormatMoney(a.TotalNegotiated)}
                                </td>
                                <td className="p-0 mb-1">{a.StatusEnumName}</td>
                                <td className="p-0 mb-1">{a.ManagerName}</td>
                                <td className="p-0 mb-1 text-center"></td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={5} align="center">
                                Nenhum acordo encontrado
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </Col>
                    {/* <Col sm={3}>
                        <Button
                          size="sm"
                          variant="secondary"
                        >
                          <FaUserFriends /> Inserir Responsável
                        </Button>
                      </Col> */}
                  </Row>
                </Tab>
              )}
            </Tabs>
          </>
        </Modal.Body>
        <Modal.Footer className="ps-3 pb-1 pt-1">
          <Button variant="secondary" size="sm" onClick={handleClose}>
            <FaSignOutAlt /> Sair
          </Button>
          {props.new && (
            <Button variant="primary" size="sm" onClick={handleToSave}>
              <FaSave /> Salvar
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      {openNot ? (
        <Jmodal
          show={openNot}
          //dados={handleNew}
          message={mensagem}
          closeModal={closeModal}
          texto={txt}
        />
      ) : null}
    </>
  );
};

export default NewEdit;

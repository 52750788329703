import React from "react";
import { Link } from "react-router-dom";
import { Breadcrumb } from "../../components";
import { TbFaceIdError } from "react-icons/tb";

const Error = () => {
  return (
    <>
      <Breadcrumb icon={<TbFaceIdError />} title="Error Page" />
      <div className="m-auto">
        <div className="row">
          <div className="col-sm-7 col-md-5 col-lg-4 col-9 m-auto">
            <div className="card text-center">
              <div className="card-body">
                <h5 className="card-title">Opssss</h5>
                <p className="card-text">
                  Página não encontrada ou usuário não tem permissão para
                  acessar
                </p>
              </div>
            </div>
            <div className="card-footer text-muted text-center">
              <Link to="/" title="Dashboard">
                Dashboard
              </Link>{" "}
              |{" "}
              <Link to="/Login" title="Dashboard">
                Login
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Error;

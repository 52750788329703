import React from "react";
import { Button, Modal } from "react-bootstrap";
import { FaThumbsDown, FaThumbsUp, FaTrash } from "react-icons/fa";
import ModalBody from "../../components/modal/modalbody";
import ModalFooter from "../../components/modal/modalfooter";
import ModalHeader from "../../components/modal/modalheader";
import zModal from "../../functions";
import { toDateBr } from "../../functions/datetimes";

const DeleteDebito = (props) => {
  const handleClose = () => {
    props.setDelit(false);
  };

  const handleDelete = () => {
    props.handleDelete();
  };

  return (
    <Modal
      show={props.show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="md"
      style={{ zIndex: zModal() }}
    >
      <ModalHeader>
        <FaTrash /> Excluir Débito de Aluno
      </ModalHeader>
      <ModalBody>
        Confirma a exclusão do Débito?
        <br />
        Débito: {props.debito}
        <br />
        Parcela: {props.parcela}
        <br />
        Vencimento: {toDateBr(props.vencimento)}
        <br />
        Valor: R$ {props.valor}
      </ModalBody>
      <ModalFooter>
        <Button variant="secondary" size="sm" onClick={handleClose}>
          <FaThumbsDown /> Não
        </Button>
        <Button variant="primary" size="sm" onClick={handleDelete}>
          <FaThumbsUp /> Sim
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteDebito;

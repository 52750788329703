import React from "react";
import { Link } from "react-router-dom";
import { Nav, NavDropdown, Container, Navbar } from "react-bootstrap";
import { AiFillHome } from "react-icons/ai";
import "./header.css";
import logo from "../../assets/imgs/logo.png";

const Header = () => {
  return (
    <>
      <div className="header navbar navbar-expand-lg navbar-inverse navbar-fixed-top">
        <div className="container">
          <Link to="/" title="Home" className="navbar-brand">
            <img
              src={logo}
              alt="gecob-logo"
              className="d-inline-block align-text-middle"
            />
          </Link>
        </div>
      </div>
      <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Link to="/" title="Home" className="nav-link">
                <AiFillHome size={24} className="text-primary" />
              </Link>
              <NavDropdown
                title="Cadastros"
                id="basic-nav-dropdown"
                className="me-3"
              >
                <NavDropdown.Item as={Link} to="/Students" title="Alunos">
                  Alunos
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/Responsables"
                  title="Responsáveis"
                >
                  Responsáveis
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Schools" title="Colégios">
                  Colégios
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Managers" title="Gestoras">
                  Gestoras
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Parameters" title="Parâmetros">
                  Parâmetros
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Users" title="Usuários">
                  Usuários
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item as={Link} to="/LogOut" title="Sair">
                  Sair
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Gestão" id="basic-nav-dropdown">
                <NavDropdown.Item
                  as={Link}
                  to="/Management/Debts"
                  title="Negociar Débitos"
                >
                  Negociar Débitos
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/Management/Agreements"
                  title="Acordos"
                >
                  Acordos
                </NavDropdown.Item>
                <Link
                  to="/Gestao/Cobradoras"
                  title="Cobradoras"
                  className="dropdown-item"
                >
                  Gestoras
                </Link>
                <Link
                  to="/Gestao/Boletos"
                  title="Usuários"
                  className="dropdown-item"
                >
                  Boletos
                </Link>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;

import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import { DecriptToken } from "./functions/decriptToken";
import { Error } from "./pages";

const Authorization = ({ role, children }) => {
    let currentDate = new Date();
    const r = localStorage.getItem("_token")

    const roles = r !== null ? DecriptToken(r).role : "none";

    const exp = r !== null ? DecriptToken(r).exp : "none";

    if (exp === "none" || exp * 1000 < currentDate.getTime()) {
        localStorage.removeItem("_token")
        return <Navigate to="/login" replace={true} />
    }

    if (role.filter(w => w === "all").length === 1)
        return children
    else if (roles.toLowerCase() === "super")
        return children
    else if (roles.toLowerCase() === "administrador") {
        if (role.filter(w => w === "super").length === 0)
            return children
    }
    else if (roles.toLowerCase() === "usuario" && role.filter(w => w === "all").length === 1)
        return children

    return <Error />
}

export default Authorization
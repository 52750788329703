import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css'
import { ContextProvider } from './contexts';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <ContextProvider>
    <ToastContainer position='top-right' autoClose={2500} theme="colored" hideProgressBar />
    <App />
  </ContextProvider>
  // </React.StrictMode>
);

import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Form,
  InputGroup,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { useStateContext } from "../../contexts";
import { FaSave, FaSearch, FaSignOutAlt, FaUserFriends } from "react-icons/fa";
import ListResponsaveis from "./listresponsaveis";
import zModal from "../../functions";
import RequestApi from "../../services";
import Jmodal from "../../components/modal/jmodal";
import { toast } from "react-toastify";
import { RequestGet, RequestPost } from "../../requestApi";

const Inresponsavel = (props) => {
  const [idR, setIdR] = useState(props.responsableId);
  const [mensagem, setMensagem] = useState(null);
  const [txt, setTxt] = useState(null);
  const [openNot, setOpenNot] = useState(false);
  const [erroLoad, setErroLoad] = useState(false);
  const [openGetResponsavel, setOpenGetResponsavel] = useState(false);
  const [idresponsavel, setIdResponsavel] = useState(0);
  const [nomeR, setNomeR] = useState("");
  const [cpf, setCpf] = useState("");
  const [debitos, setDebitos] = useState([]);
  const [change, setChange] = useState(0);
  const [slv, setSlv] = useState([]);

  const handleClose = () => props.close(false);

  const handleSaveResponsavel = async () => {
    var url = "/Debts/Insert";

    setTxt("Enviando dados...");
    setOpenNot(true);
    setMensagem(null);

    let dbs = [];

    debitos.filter((d) => d.IsSelected).map((d) => dbs.push(d.StudentDebtId));

    var datas = {
      StudentId: props.studentId,
      SchoolId: props.schoolId,
      ResponsableId: props.responsableId,
      Debts: dbs,
    };

    const r = await RequestPost("/Responsables" + url, datas);

    if (r.Status === 200) {
      setMensagem(r.Title);
      props.responsaveis(r.Data);
      handleClose();
      toast.success(r.Title);
      //props.dados(false, r.data.responsaveis);
      //setOpenNot(false);
    } else {
      setSlv([]);
      setErroLoad(true);
      setMensagem(
        "Erro no carregamento de dados. Data Server!. Error: " + r?.status
      );
    }
    //props.dados("n", { nome: nomeR, cpf });
  };

  const handleGetResponsavel = (id, name) => {
    props.setResponsableId(id);
    props.gIdR(id);
    props.setName(name);
    setNomeR(name);

    setChange(change + 1);

    if (id.length === 11) {
      loadDebitosResponsavel();
    }
    setOpenGetResponsavel(false);
  };

  const loadDebitosResponsavel = async () => {
    var url =
      "/Debts/Responsables/" +
      props.schoolId +
      "/" +
      props.studentId +
      "/" +
      (props.responsableId === "n" ? "" : props.responsableId);
    setTxt("Carregando dados...");
    setOpenNot(true);
    setMensagem(null);

    const r = await RequestGet("/Students" + url);

    if (r.Status === 200) {
      setDebitos(r.Data);
      //setNomeR(r.responsavel.nome);
      //setCpf(r.responsavel.cpf);
      setOpenNot(false);
    } else {
      setMensagem(r.message);
      setErroLoad(true);
    }
  };

  const closeModal = () => {
    setOpenNot(false);
    if (slv.length > 0) {
      props.dados(false, slv);
      //setOpenNot(false);
    }
    // if ((erroLoad && newCadastro) || (erroLoad && !newCadastro && !editar))
    //   props.handleNewAluno(0, 0);
  };

  const sairModal = () => setOpenNot(false);

  useEffect(() => {
    setNomeR(props.name);
    if (props.responsableId !== "s") loadDebitosResponsavel();
  }, [props.show, change]);

  const [tl, setTl] = useState(0);
  useEffect(() => {
    setTl(zModal());
  }, []);

  return (
    <>
      <Modal
        show={props.show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="md"
        id="2"
        style={{ zIndex: tl }}
      >
        <Modal.Header closeButton={true} className="pt-2 ps-4 pb-0 pe-4">
          <Modal.Title className="h6">
            <FaUserFriends /> Inserir Responsável&nbsp;
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="ps-4 pt-2 pe-4">
          {/* {errorDebito && (
      <Row className="pb-0">
        <Col sm={12} className="pb-0 ">
          <Alert
            key="danger"
            variant="danger"
            size="sm"
            className="ps-2 p-1"
          >
            {errorDebitoMessage}
          </Alert>
        </Col>
      </Row>
    )} */}
          <Row>
            <Col sm={12} className="pb-3">
              <InputGroup>
                <Button
                  variant="primary"
                  size="sm"
                  onClick={() => setOpenGetResponsavel(true)}
                >
                  <FaSearch />
                </Button>
                <Form.Control
                  type="text"
                  placeholder="Selecione o Responsável"
                  aria-label="Buscar"
                  size="sm"
                  value={nomeR == null ? "" : nomeR}
                  style={{ textTransform: "uppercase" }}
                  disabled
                />
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col
              sm={12}
              style={{
                height: "303px",
                overflowX: "hidden",
                overflowY: "auto",
              }}
            >
              <Table striped hover size="sm">
                <thead>
                  <tr>
                    <th className="border-top-0 pt-0 pb-0"></th>
                    <th className="border-top-0 pt-0 pb-0">Descrição</th>
                    <th className="border-top-0 pt-0 pb-0">Parcela</th>
                    <th className="border-top-0 pt-0 pb-0">Vencimento</th>
                    <th className="border-top-0 pt-0 pb-0">Valor</th>
                  </tr>
                </thead>
                <tbody>
                  {debitos.map((d, index) => (
                    <tr key={index}>
                      <td className="p-0 mb-1">
                        {d.IsSelected && (
                          <Form.Check
                            type="checkbox"
                            defaultChecked={true}
                            onChange={() => (debitos[index].IsSelected = false)}
                          />
                        )}
                        {!d.IsSelected && (
                          <Form.Check
                            type="checkbox"
                            defaultChecked={false}
                            onChange={() => (debitos[index].IsSelected = true)}
                          />
                        )}
                      </td>
                      <td className="p-0 mb-1">{d.TypeDebtName}</td>
                      <td className="p-0 mb-1 text-center">{d.Installment}</td>
                      <td className="p-0 mb-1 text-center">
                        {new Date(d.DtPaydue).toLocaleDateString("pt-BR")}
                      </td>
                      <td className="p-0 mb-1 text-end">
                        {new Intl.NumberFormat("pt-BR", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                          currency: "BRL",
                        }).format(d.Value)}
                      </td>
                    </tr>
                  ))}
                  {debitos.length === 0 && (
                    <tr>
                      <td className="p-0 mb-1" colSpan={5}>
                        Nenhum débito encontrado
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="ps-3 pb-1 pt-1">
          <>
            <Button variant="secondary" size="sm" onClick={handleClose}>
              <FaSignOutAlt /> Sair
            </Button>
            <Button variant="primary" size="sm" onClick={handleSaveResponsavel}>
              <FaSave /> Salvar
            </Button>
          </>
          &nbsp;
        </Modal.Footer>
      </Modal>
      {openGetResponsavel ? (
        <ListResponsaveis
          show={openGetResponsavel}
          dados={handleGetResponsavel}
        />
      ) : null}
      {openNot ? (
        <Jmodal
          show={openNot}
          //dados={handleNew}
          message={mensagem}
          closeModal={closeModal}
          texto={txt}
        />
      ) : null}
    </>
  );
};

export default Inresponsavel;

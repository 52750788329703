import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { FaDonate, FaSave, FaSignOutAlt } from "react-icons/fa";
import {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalNotifications,
} from "../../components/modal";
import RequestApi from "../../services";
import { toast } from "react-toastify";
import zModal from "../../functions";
import { BrToDecimal, DecimalToBr, InputBr } from "../../functions/money";
import { RequestGet, RequestPatch, RequestPost } from "../../requestApi";
import Msg from "../../components/errors/msg";

const ParametersFinancialAlt = (props) => {
  const [fine, setFine] = useState(0);
  const [interest, setInterest] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [discountDays, setDiscountDays] = useState(0);
  const [daysEntry, setDaysEntry] = useState(0);
  const [maxInstallment, setMaxInstallment] = useState(0);
  const [minValueInstallment, setMinValueInstallment] = useState(0);
  const [minValueEntry, setMinValueEntry] = useState(0);
  const [openNot, setOpenNot] = useState(false);
  const [mensagem, setMensagem] = useState(null);
  const [txt, setTxt] = useState(null);
  const [error, setError] = useState(false);

  const closeModal = () => props.close(false);

  const closeNot = () => {
    if (error) closeModal();

    setOpenNot(false);
  };

  const saveDados = async () => {
    setOpenNot(true);
    setMensagem(null);
    setTxt("Salvando dados Financeiro ");
    console.log(fine);
    console.log(interest);
    console.log(discount);

    let erros = [];

    var c = 0;

    let mult = BrToDecimal(fine);
    let juro = BrToDecimal(interest);
    let descont = BrToDecimal(discount);
    let descontoDia = parseInt(discountDays);
    let diasPrimeiraParcel = parseInt(daysEntry);
    let maximoParcel = parseInt(maxInstallment);
    let valorMinimoEntrad = BrToDecimal(minValueEntry);
    let valorMinimoParcel = BrToDecimal(minValueInstallment);

    if (isNaN(mult))
      erros.push(
        <span key={c++}>
          Multa inválida
          <br />
        </span>
      );

    if (isNaN(juro))
      erros.push(
        <span key={c++}>
          Juros inválido
          <br />
        </span>
      );

    if (isNaN(descont))
      erros.push(
        <span key={c++}>
          Desconto inválido
          <br />
        </span>
      );

    if (isNaN(descontoDia))
      erros.push(
        <span key={c++}>
          Desconto Dias inválido
          <br />
        </span>
      );

    if (isNaN(maximoParcel))
      erros.push(
        <span key={c++}>
          Máximo de Parcelas inválido
          <br />
        </span>
      );
    else if (maximoParcel < 1)
      erros.push(
        <span key={c++}>Máximo de Parcela deve ser igual ou maior que 1</span>
      );

    if (isNaN(valorMinimoParcel))
      erros.push(
        <span key={c++}>
          Valor Minimo de Parcela inválido
          <br />
        </span>
      );
    else if (valorMinimoParcel <= 0)
      erros.push(
        <span key={c++}>
          Valor Minimo de Parcela deve ser igual ou maior que 1<br />
        </span>
      );

    if (isNaN(diasPrimeiraParcel))
      erros.push(
        <span key={c++}>
          Dias da Entrada inválido
          <br />
        </span>
      );

    if (isNaN(valorMinimoEntrad))
      erros.push(
        <span key={c++}>
          Valor Minimo de Entrada inválido
          <br />
        </span>
      );
    else if (valorMinimoEntrad < 0)
      erros.push(
        <span key={c++}>
          Valor Minimo de Entrada não pode ser menor que 0<br />
        </span>
      );

    console.log(erros);

    if (erros.length > 0) {
      setMensagem(erros);
      return;
    }

    let dados = {
      Fine: mult,
      Interest: juro,
      Discount: descont,
      DiscountDays: descontoDia,
      DaysEntry: diasPrimeiraParcel,
      MaxInstallment: maximoParcel,
      MinValueEntry: valorMinimoEntrad,
      MinValueInstallment: valorMinimoParcel,
    };

    const r = await RequestPost("/Entities/Parameters/Financial/UpDate", dados);

    setMensagem(r.Title);
    if (r.Status === 200) {
      toast.success(r.Title);
      props.setData({
        ...props.data,
        Fine: mult,
        Interest: juro,
        Discount: descont,
        DiscountDays: descontoDia,
        DaysEntry: diasPrimeiraParcel,
        MaxInstallment: maximoParcel,
        MinValueEntry: valorMinimoEntrad,
        MinValueInstallment: valorMinimoParcel,
      });
      setOpenNot(false);
      props.close(false);
    } else {
      toast.error(<Msg dados={r.errors} />);
    }
  };

  const loadDados = async () => {
    setOpenNot(true);
    setMensagem(null);
    setTxt("Carregando dados Financeiro ");
    const r = await RequestGet("/Entities/Parameters/Financial");

    setMensagem(r.Title);
    if (r.Status === 200) {
      let f = r.Data;
      toast.info(r.Title);
      setFine(DecimalToBr(f.Fine));
      setInterest(DecimalToBr(f.Interest));
      setDiscount(DecimalToBr(f.Discount));
      setDiscountDays(f.DiscountDays);
      setDaysEntry(f.DaysEntry);
      setMaxInstallment(f.MaxInstallment);
      setMinValueEntry(DecimalToBr(f.MinValueEntry));
      setMinValueInstallment(DecimalToBr(f.MinValueInstallment));
      setOpenNot(false);
    } else {
      toast.error(r.Title);
    }
  };

  useEffect(() => {
    loadDados();
  }, [props.show]);

  const [tl, setTl] = useState(1060);
  useEffect(() => {
    setTl(zModal());
  }, []);

  return (
    <>
      <Modal
        show={props.show}
        onHide={closeModal}
        backdrop="static"
        keyboard={false}
        size="md"
        style={{ zIndex: tl }}
      >
        <ModalHeader>
          <FaDonate /> Financeiro
        </ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col sm={6} className="pb-2 ">
                <Form.Group controlId="multa">
                  <Form.Label className="mb-0">Multa %</Form.Label>
                  <Form.Control
                    type="currency"
                    size="sm"
                    onChange={(e) => setFine(InputBr(e.target.value))}
                    value={fine}
                    className="text-end"
                    style={{ textTransform: "uppercase" }}
                  />
                </Form.Group>
              </Col>
              <Col sm={6} className="pb-2 ">
                <Form.Group controlId="juros">
                  <Form.Label className="mb-0">Juros % (a.m.)</Form.Label>
                  <Form.Control
                    type="currency"
                    size="sm"
                    onChange={(e) => setInterest(InputBr(e.target.value))}
                    value={interest}
                    className="text-end"
                    style={{ textTransform: "uppercase" }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6} className="pb-2 ">
                <Form.Group controlId="rg">
                  <Form.Label className="mb-0">Desconto %</Form.Label>
                  <Form.Control
                    type="currency"
                    size="sm"
                    onChange={(e) => setDiscount(InputBr(e.target.value))}
                    value={discount}
                    className="text-end"
                    style={{ textTransform: "uppercase" }}
                  />
                </Form.Group>
              </Col>
              <Col sm={6} className="pb-2 ">
                <Form.Group controlId="descontoDias">
                  <Form.Label className="mb-0">Desconto Dias </Form.Label>
                  <Form.Control
                    type="number"
                    size="sm"
                    min={0}
                    onChange={(e) =>
                      setDiscountDays(e.target.value.toUpperCase())
                    }
                    value={discountDays}
                    className="text-end"
                    style={{ textTransform: "uppercase" }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6} className="pb-2 ">
                <Form.Group controlId="maximoParcelas">
                  <Form.Label className="mb-0">Máximo de Parcelas</Form.Label>
                  <Form.Control
                    type="number"
                    size="sm"
                    min={1}
                    onChange={(e) =>
                      setMaxInstallment(e.target.value.toUpperCase())
                    }
                    value={maxInstallment}
                    className="text-end"
                    style={{ textTransform: "uppercase" }}
                  />
                </Form.Group>
              </Col>
              <Col sm={6} className="pb-2 ">
                <Form.Group controlId="valorMinimoParcela">
                  <Form.Label className="mb-0">
                    Valor Mínimo de Parcela{" "}
                  </Form.Label>
                  <Form.Control
                    type="currency"
                    size="sm"
                    onChange={(e) =>
                      setMinValueInstallment(InputBr(e.target.value))
                    }
                    value={minValueInstallment}
                    className="text-end"
                    style={{ textTransform: "uppercase" }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6} className="pb-2 ">
                <Form.Group controlId="diasEntrada">
                  <Form.Label className="mb-0">Dias da Entrada</Form.Label>
                  <Form.Control
                    type="number"
                    size="sm"
                    min={0}
                    onChange={(e) => setDaysEntry(e.target.value.toUpperCase())}
                    value={daysEntry}
                    className="text-end"
                    style={{ textTransform: "uppercase" }}
                  />
                </Form.Group>
              </Col>
              <Col sm={6} className="pb-2 ">
                <Form.Group controlId="valorMinimoEntrada">
                  <Form.Label className="mb-0">
                    Valor Mínimo de Entrada{" "}
                  </Form.Label>
                  <Form.Control
                    type="currency"
                    size="sm"
                    onChange={(e) => setMinValueEntry(InputBr(e.target.value))}
                    value={minValueEntry}
                    className="text-end"
                    style={{ textTransform: "uppercase" }}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button variant="secondary" size="sm" onClick={closeModal}>
            <FaSignOutAlt /> Sair
          </Button>
          <Button variant="primary" size="sm" onClick={saveDados}>
            <FaSave /> Salvar
          </Button>
        </ModalFooter>
      </Modal>
      {openNot ? (
        <ModalNotifications
          show={openNot}
          message={mensagem}
          close={closeNot}
          texto={txt}
        />
      ) : null}
    </>
  );
};

export default ParametersFinancialAlt;

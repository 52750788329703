import React, { useEffect, useState } from 'react'
import { FloatingLabel, Form } from 'react-bootstrap';
import { FaFacebookF, FaGoogle, FaLinkedinIn, FaTwitter } from 'react-icons/fa';
import { Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Msg from '../../components/errors/msg';
import ModalLoading from '../../components/modals/loading';
import { useStateContext } from '../../contexts';
import { RequestPost } from '../../requestApi';
import './login.css'

const Login = () => {
    const { logado, setLogado } = useStateContext();

    const [dados, setDados] = useState({ username: "", password: "" })
    const [loading, setLoading] = useState(false);
    const onChange = (e) => {
        const { name, value } = e.target;
        setDados({ ...dados, [name]: value });
    };
    const login = async () => {
        toast.dismiss()
        setLoading(true)
        const id = toast.loading(" Verificando Login...")

        if (dados.username.length > 0) {
            const r = await RequestPost("/Login", dados)
            console.log(r.Status)

            if (r.Status === 200) {
                toast.update(id, { render: r.Message, type: "success", isLoading: false, autoClose: true, position: toast.POSITION.TOP_RIGHT });
                localStorage.setItem("_token", r.Data.Token);
                //window.location.replace("/")
                setLogado(true)
            }
            else {
                toast.dismiss()
                toast.update(id, { render: <Msg dados={r.errors} />, type: "error", isLoading: false, autoClose: false, position: toast.POSITION.TOP_CENTER, closeButton: true });
                setLoading(false)
            }
        }
    }
    useEffect(() => { !!localStorage.getItem("_token") ? setLogado(true) : setLogado(false) }, [])
    return (
        <>
            {logado ?
                <Navigate to="/" replace={true} />
                :
                <>
                    <section className="vh-100">
                        <div className="container-fluid h-custom">
                            <div className="row d-flex justify-content-center align-items-center h-100">
                                {/* <div className="col-md-9 col-lg-6 col-xl-5">
                                <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp" className="img-fluid" alt="Logo" />
                            </div> */}
                                <div className="col-md-8 col-lg-6 col-xl-4">
                                    <form>
                                        <div className="d-flex flex-row align-items-center justify-content-center justify-content-lg-start">
                                            <p className="lead fw-normal mb-0 me-3">Acesso ao Gecob</p>
                                            {/* <button type="button" className="btn btn-primary btn-floating mx-1">
                                            <FaFacebookF />
                                        </button>

                                        <button type="button" className="btn btn-primary btn-floating mx-1">
                                            <FaTwitter />
                                        </button>

                                        <button type="button" className="btn btn-primary btn-floating mx-1">
                                            <FaLinkedinIn />
                                        </button> */}
                                        </div>

                                        <div className="divider d-flex align-items-center my-2">
                                            <p className="text-center fw-bold mx-3 mb-0"></p>
                                        </div>
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Usuário"
                                            className="mb-3"
                                        >
                                            <Form.Control type="text" placeholder="Usuario" name='username' size={'sm'} onChange={onChange} />
                                        </FloatingLabel>
                                        <FloatingLabel controlId="floatingPassword" label="Senha">
                                            <Form.Control type="password" placeholder="Senha" name='password' size={'sm'} onChange={onChange} />
                                        </FloatingLabel>

                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="form-check mb-0">
                                            </div>
                                            {/* <a href="#!" className="text-body">Esqueceu a senha?</a> */}
                                        </div>
                                        <div className="text-center text-lg-start mt-2 pt-2">
                                            <button type="button" className="btn btn-primary btn-sm" style={{ paddingLeft: '2.5rem', paddingRight: '2.5rem' }} onClick={login} >Login</button>
                                            {/* <p className="small fw-bold mt-2 pt-1 mb-0">Don't have an account? <a href="#!"
                                            className="link-danger">Register</a></p> */}
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                        <div
                            className="d-flex flex-column flex-md-row text-center text-md-start justify-content-between py-4 px-4 px-xl-5 bg-primary">
                            <div className="text-white mb-3 mb-md-0">
                                Copyright © 2020. All rights reserved. Henro Brasil
                            </div>
                            <div>
                                <a href="#!" className="text-white me-4">
                                    <i className="fab fa-facebook-f"></i>
                                </a>
                                <a href="#!" className="text-white me-4">
                                    <FaTwitter />
                                </a>
                                <a href="#!" className="text-white me-4">
                                    <FaGoogle />
                                </a>
                                <a href="#!" className="text-white">
                                    <FaLinkedinIn />
                                </a>
                            </div>
                        </div>
                    </section>
                    {loading ? <ModalLoading show={loading} /> : null}
                </>
            }
        </>
    )
}

export default Login

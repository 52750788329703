import React, { createContext, useContext, useState } from 'react';

const StateContext = createContext();

//style="background: #ebeef4" 
export const ContextProvider = ({ children }) => {
    const [sideBarMobile, setSideBarMobile] = useState('app');
    const [title, setTitle] = useState("");
    const [modalShow, setModalShow] = useState(false);
    const [viewShow, setViewShow] = useState(false);
    const [backdrop, setBackdrop] = useState();
    const [alterar, setAlterar] = useState(false);
    const [jModal, setJModal] = useState({ responsavel: "false" });
    const [id, setId] = useState(-1);
    const [logado, setLogado] = useState(!!localStorage.getItem("_token"));

    return (
        <StateContext.Provider value={{ sideBarMobile, setSideBarMobile, title, setTitle, modalShow, setModalShow, alterar, setAlterar, id, setId, viewShow, setViewShow, backdrop, setBackdrop, jModal, setJModal, logado, setLogado }}>
            {children}
        </StateContext.Provider>
    );
};

export const useStateContext = () => useContext(StateContext);
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Row, Col, Card, InputGroup, Form, Button, Spinner, Table } from 'react-bootstrap';
import { FaEye, FaHandshake, FaMoneyBillWave, FaSearch } from 'react-icons/fa';
import { RequestGet } from '../../../requestApi';
import './agreements.css'
import FormatCpf from '../../../functions/formatCpf';
import FormatMoney from '../../../functions/formatmoney';
import { Link } from 'react-router-dom';
import { LeftPad } from '../../../functions/leftPad';
import { toDateTimeBr } from '../../../functions/datetimes';

const ManagementAgreements = () => {
    const [msgErro, setMsgErro] = useState("");
    const [agreements, setAgreements] = useState([]);
    const [sending, setSending] = useState(false);
    const [search, setSearch] = useState("");
    const [agreementId, setAgreementId] = useState("")
    const [typeSearch, setTypeSearch] = useState(1)
    const [idStatus, setIdStatus] = useState(0)
    const [status, setStatus] = useState([])

    const list = async () => {
        setMsgErro("");
        var tf = false;

        let query = "?type=" + typeSearch
        let src = "&search="

        if (typeSearch === 1) src += agreementId
        if (typeSearch === 2) src += search
        if (typeSearch === 3) src += idStatus

        if (typeSearch === 1 && src.trim().length > 8) tf = true;
        if (typeSearch === 2 && src.trim().length > 9) tf = true;
        if (typeSearch === 3) tf = true;

        if (tf) {
            setSending(true);

            const r = await RequestGet("/Agreements/List" + query + src);

            if (r.Status === 200) {
                setAgreements(r.Data);
            } else {
                setMsgErro(r?.errors[0]);
            }
        }
        setSending(false);
    };
    const handleSearch = (e) => {
        //e.preventDefault();
        if (e.key === "Enter") {
            list();
        }
    };

    useEffect(() => {
        const getDados = async () => {
            const r = await RequestGet("Agreements")
            if (r.Status === 200) {
                setStatus(r.Data)
            }
        }
        getDados();
    }, [])

    return (
        <>
            <ul className="nav pt-3">
                <li className="nav-item text-primary nav-link ps-1">
                    <h4>
                        <strong>
                            <FaHandshake className='mb-1' /> Acordos
                        </strong>
                    </h4>
                </li>
            </ul>
            <Row>
                <Col sm={12}>
                    <Card className="shadow">
                        <Card.Body>
                            <Row className="mb-2">
                                <Col sm={12}>
                                    <Form.Group controlId="formBasicEmail">
                                        <div className="text-gray-500 mb-2">Escolha a Opção</div>
                                        <Form.Check
                                            inline
                                            label="Acordo"
                                            name="tpSearch"
                                            type="radio"
                                            id="inline-radio-1"
                                            value={1}
                                            defaultChecked={typeSearch}
                                            onChange={() => setTypeSearch(1)}
                                        />
                                        <Form.Check
                                            inline
                                            label="Nome Responsável"
                                            name="tpSearch"
                                            type="radio"
                                            id="inline-radio-2"
                                            value={2}
                                            onChange={() => setTypeSearch(2)}
                                        />
                                        <Form.Check
                                            inline
                                            label="Status do Acordo"
                                            name="tpSearch"
                                            type="radio"
                                            id="inline-radio-3"
                                            value={3}
                                            onChange={() => setTypeSearch(3)}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <Row>
                                        <Col sm={4} className="mb-3">
                                            <InputGroup>
                                                {typeSearch === 1 &&
                                                    <Form.Control
                                                        type="number"
                                                        min={1}
                                                        placeholder="Nº Acordo"
                                                        aria-label="Buscar"
                                                        size="sm"
                                                        onKeyDown={handleSearch}
                                                        onChange={(e) =>
                                                            setAgreementId(e.target.value)
                                                        }
                                                        defaultValue={agreementId}
                                                        autoFocus={true}
                                                        className='no-spin'
                                                    />
                                                }
                                                {typeSearch === 2 &&
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Nome do Responsável"
                                                        aria-label="Buscar"
                                                        size="sm"
                                                        onKeyDown={handleSearch}
                                                        onChange={(e) =>
                                                            setSearch(e.target.value.toUpperCase())
                                                        }
                                                        defaultValue={search}
                                                        autoFocus={true}
                                                        style={{ textTransform: "uppercase" }}
                                                    />
                                                }
                                                {typeSearch === 3 &&
                                                    <Form.Select
                                                        size="sm"
                                                        onChange={(e) => setIdStatus(e.target.value)}
                                                        value={idStatus}
                                                    >
                                                        <option disabled value={0} className="text-muted">
                                                            TODOS
                                                        </option>
                                                        {status.map((s, index) => (
                                                            <option key={index} value={s.Id}>
                                                                {s.Name}
                                                            </option>
                                                        ))}
                                                    </Form.Select>
                                                }
                                                <Button variant="primary" size="sm" onClick={list}>
                                                    <FaSearch />
                                                </Button>
                                            </InputGroup>
                                        </Col>
                                        <Col sm={8} className="mb-3">
                                            {sending && (
                                                <Spinner
                                                    animation="border"
                                                    size="sm"
                                                    className="float-start mt-2"
                                                />
                                            )}
                                            {msgErro !== "" && (
                                                <span className="text-danger float-start mt-1">
                                                    {msgErro}
                                                </span>
                                            )}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <Table striped hover size="sm">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="ps-2">Acordo</th>
                                                <th scope="col">Responsável</th>
                                                <th scope="col">CPF</th>
                                                <th scope="col">Dt. Acordo</th>
                                                <th scope="col">Valor</th>
                                                <th scope="col" className="ps-2">Status</th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {agreements.length > 0 ? (
                                                agreements.map((d, index) => (
                                                    <tr key={index}>
                                                        <td className="ps-2">{LeftPad(d.AgreementId, 6)}</td>
                                                        <td>{d.ResponsableName}</td>
                                                        <td>{FormatCpf(d.ResponsableId)}</td>
                                                        <td>
                                                            {toDateTimeBr(d.DtInsert)}
                                                        </td>
                                                        <td align="right" className="me-5">
                                                            {FormatMoney(d.TotalNegotiated)}
                                                        </td>
                                                        <td className="ps-2">{d.StatusEnumName}</td>
                                                        <td className="ms-5">
                                                            &nbsp; &nbsp;
                                                            <Link
                                                                // onClick={() => {
                                                                //     setNewAcordo(true);
                                                                //     setCpf(d.cpf);
                                                                // }}
                                                                title="Visualizar Acordo"
                                                            >
                                                                <FaEye color="#003768" />
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={7} className="text-center">Nenhum Acordo encontrado</td>
                                                </tr>
                                            )}

                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default ManagementAgreements

import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { FaTrash, FaUserGraduate } from "react-icons/fa";
import Jmodal from "../../components/modal/jmodal";
import zModal from "../../functions";
import validator from "validator";
import { Link } from "react-router-dom";
import ModalConfirm from "../../components/modal/modalconfirm";
import DeleteDebito from "./deletedebito";
import { toast } from "react-toastify";
import { BrToDecimal, DecimalToBr, InputBr } from "../../functions/money";
import { RequestDelete, RequestGet, RequestPost } from "../../requestApi";
import Msg from "../../components/errors/msg";
import { dateToDate, toDateTimeBr } from "../../functions/datetimes";

const NewDebt = (props) => {
  const [debito, setDebito] = useState({});
  const [errorDebito, setErrorDebito] = useState(false);
  const [errorDebitoMessage, setErrorDebitoMessage] = useState("");
  const [mensagem, setMensagem] = useState(null);
  const [txt, setTxt] = useState(null);
  const [openNot, setOpenNot] = useState(false);
  const [erroLoad, setErroLoad] = useState(false);
  const [er, setEr] = useState(false);

  const [typeDebts, setTypeDebts] = useState([]);
  const [typeDebtEnum, setTypeDebtEnum] = useState(0);
  const [typeDebtEnumName, setTypeDebtEnumName] = useState("");
  const [installment, setInstallment] = useState(0);
  const [dtPaydue, setDtPaydue] = useState(Date);
  const [valor, setValor] = useState();

  const [delit, setDelit] = useState(false);

  const closeNot = () => {
    er(false);
    setOpenNot(false);
  };

  const handleNot = (op) => {
    if (op === 1) {
      setEr(false);
      setOpenNot(true);
      setTxt("Enviando Dados");
      setMensagem(null);
    }
  };

  const handleDelete = async () => {
    setDelit(false);
    handleNot(1);
    const r = await RequestDelete(
      "/Students/Debts/Delete/" +
        props.schoolId +
        "/" +
        props.studentId +
        "/" +
        props.studentDebtId,
      {
        data: {},
      }
    );

    setMensagem(r.Title);
    if (r.Status === 200) {
      toast.success(r.Title);
      setMensagem(r.Title);
      props.handleNewDebt(r.Data);
      props.close(false);
      //setOpenNot(false);
    } else {
      setMensagem(<Msg dados={r.errors} />);
      setErroLoad(false);
    }
  };

  const handleSaveDebt = async () => {
    //setErrorDebito(true);
    setEr(false);
    setOpenNot(true);
    setTxt("Enviando Dados");
    setMensagem(null);

    var c = 0;
    let error = [];
    //#region Verificacoes
    if (typeDebtEnum === 0)
      error.push(
        <span key={c++}>
          Débito deve ser informado
          <br />
        </span>
      );

    if (installment === undefined || installment.length === 0) {
      error.push(
        <span key={c++}>
          Parcela deve ser informada
          <br />
        </span>
      );
    } else if (installment < 0) {
      error.push(
        <span key={c++}>
          Parcela inválida
          <br />
        </span>
      );
    }
    if (dtPaydue === undefined) {
      error.push(
        <span key={c++}>
          Vencimento deve ser informado
          <br />
        </span>
      );
    } else if (!validator.isDate(dtPaydue)) {
      error.push(
        <span key={c++}>
          Dt. vencimento inválido
          <br />
        </span>
      );
    }
    if (valor === undefined) {
      error.push(
        <span key={c++}>
          Valor deve ser informado
          <br />
        </span>
      );
    } else {
      if (BrToDecimal(valor) < 0.01) {
        error.push(
          <span key={c++}>
            Valor informado é inválido
            <br />
          </span>
        );
      } else if (valor <= 0.01) {
        error.push(
          <span key={c++}>
            Valor deve ser maior que 0,00
            <br />
          </span>
        );
      }
    }
    //#endregion

    //Se tem erros
    if (error.length > 0) {
      setEr(true);
      setMensagem(error);
      return;
    }
    var prc = parseInt(installment);

    if (isNaN(prc)) prc = 0;

    const dados = {
      SchoolId: props.schoolId,
      StudentId: props.studentId,
      StudentDebtId: props.studentDebtId,
      TypeDebtEnum: typeDebtEnum,
      Installment: prc,
      DtPaydue: dtPaydue,
      Value: BrToDecimal(valor),
    };

    //tudo certo faz o envio do debito e retorna todos os debitos para atualizar a lista de debitos do aluno
    var url = "/Insert";

    if (props.studentDebtId !== "00000000-0000-0000-0000-000000000000")
      url = "/UpDate/" + props.studentDebtId;

    const r = await RequestPost("/Students/Debts" + url, dados);

    if (r.Status === 200) {
      toast.success(r.Title);
      props.handleNewDebt(r.Data);
      props.close(false);
      setOpenNot(false);
    } else {
      setMensagem(<Msg dados={r.errors} />);
      setErroLoad(false);
    }
  };

  const loadDados = async () => {
    var url = "/Debts/New";
    setTxt("Carregando dados...");
    setOpenNot(true);
    setMensagem(null);

    if (props.studentDebtId !== "00000000-0000-0000-0000-000000000000")
      url =
        "/Debts/Details/" +
        props.schoolId +
        "/" +
        props.studentId +
        "/" +
        props.studentDebtId;

    const r = await RequestGet("/Students" + url);

    if (r.Status === 200) {
      if (props.studentDebtId !== "00000000-0000-0000-0000-000000000000") {
        toast.success(r.Title);
        var d = r.Data;
        setTypeDebtEnum(d.TypeDebtEnum);
        setInstallment(d.Installment);
        setDtPaydue(dateToDate(d.DtPaydue));
        setValor(DecimalToBr(d.Value));
        setTypeDebts(r.Data.TypesDebts);

        const selectedTypeDebt = r.Data.TypesDebts.find(
          (typeDebt) => typeDebt.Id === d.TypeDebtEnum
        );
        setTypeDebtEnumName(selectedTypeDebt.Name);
      } else setTypeDebts(r.Data);
      setOpenNot(false);
    } else {
      setMensagem(<Msg dados={r.errors} />);
      setErroLoad(true);
    }
  };

  const closeModal = () => {
    setOpenNot(false);
    if (erroLoad) props.close(false);
  };

  useEffect(() => {
    loadDados();
  }, [props.show]);

  const [tl, setTl] = useState(0);
  useEffect(() => {
    setTl(zModal());
  }, []);

  return (
    <>
      <Modal
        show={props.show}
        onHide={() => props.handleNewDebt(null)}
        backdrop="static"
        keyboard={false}
        size="lg"
        id="2"
        //backdropClassName={`stack-${backdrop + 1}`}
        //className={`stack-b-${backdrop + 1}`}
        style={{ zIndex: tl }}
      >
        <Modal.Header closeButton className="pt-2 ps-4 pb-0 pe-4">
          <Modal.Title className="h6">
            <FaUserGraduate /> Novo Débito de Aluno&nbsp;
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="ps-4 pt-2 pe-4">
          <Row className="pb-2">
            <Col sm={4} className="col-6">
              <Form.Group controlId="idColegio">
                <Form.Label className="mb-0">Débito</Form.Label>
                <Form.Select
                  size="sm"
                  name=""
                  onChange={(e) => {
                    setTypeDebtEnum(e.target.value);
                    setTypeDebtEnumName(
                      e.target.options[e.target.selectedIndex].text
                    );
                  }}
                  value={typeDebtEnum}
                >
                  <option disabled value={0} className="text-muted">
                    -- Informe o Debito --
                  </option>
                  {typeDebts.map((h, index) => (
                    <option key={index} value={h.Id}>
                      {h.Name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col sm={2} className="col-6">
              <Form.Group controlId="codAluno">
                <Form.Label className="mb-0">Parcela</Form.Label>
                <Form.Control
                  type="number"
                  size="sm"
                  max={99}
                  min={0}
                  onChange={(e) => setInstallment(e.target.value)}
                  style={{ textTransform: "uppercase" }}
                  value={installment}
                />
              </Form.Group>
            </Col>
            <Col sm={3} className="col-6">
              <Form.Group controlId="codAluno">
                <Form.Label className="mb-0">Vencimento</Form.Label>
                <Form.Control
                  type="date"
                  size="sm"
                  onChange={(e) => setDtPaydue(e.target.value)}
                  style={{ textTransform: "uppercase" }}
                  value={dtPaydue}
                />
              </Form.Group>
            </Col>
            <Col sm={3}>
              <Form.Group controlId="nome">
                <Form.Label className="mb-0">Valor</Form.Label>
                <Form.Control
                  type="currency"
                  size="sm"
                  onChange={(e) => setValor(InputBr(e.target.value))}
                  value={valor}
                  className="text-end"
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="ps-3 pb-1 pt-1">
          <>
            {props.studentDebtId !== "00000000-0000-0000-0000-000000000000" && (
              <Link
                onClick={() => setDelit(true)}
                title="Excluir débito"
                className="float-start me-auto"
              >
                <FaTrash color="red" />
              </Link>
            )}
            <Button
              variant="secondary"
              size="sm"
              onClick={() => props.handleNewDebt(null)}
            >
              Sair
            </Button>
            <Button variant="primary" size="sm" onClick={handleSaveDebt}>
              Salvar
            </Button>
          </>
          &nbsp;
        </Modal.Footer>
      </Modal>
      {errorDebito ? (
        <Jmodal
          show={errorDebito}
          //dados={handleNew}
          message={errorDebitoMessage}
          closeModal={closeNot}
        />
      ) : null}
      {openNot ? (
        <Jmodal
          show={openNot}
          //dados={handleNew}
          message={mensagem}
          closeModal={closeModal}
          texto={txt}
        />
      ) : null}
      {delit ? (
        <DeleteDebito
          show={delit}
          idDebito={props.idDebito}
          debito={typeDebtEnumName}
          parcela={installment}
          vencimento={dtPaydue}
          valor={valor}
          setDelit={setDelit}
          handleDelete={handleDelete}
        />
      ) : null}
    </>
  );
};

export default NewDebt;

import React from "react";
import { Breadcrumb, Cards } from "../../components";

const Home = () => {
  return (
    <>
      <Breadcrumb title="Dashboard" />
      <Cards />
    </>
  );
};

export default Home;

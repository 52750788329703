import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Modal,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { FaSearch, FaEye, FaUserGraduate, FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import NewEdit from "./newedit";
import { Breadcrumb } from "../../components";
import RequestApi from "../../services";
import Jmodal from "../../components/modal/jmodal";
import { RequestGet } from "../../requestApi";
import Msg from "../../components/errors/msg";

const Students = () => {
  const [newStudent, setNewStudent] = useState(false);
  const [students, setStudents] = useState([]);
  const [studentId, setStudentId] = useState(0);
  const [schoolId, setSchoolId] = useState(0);
  const [newEdit, setNewEdit] = useState(false);
  const [msgErro, setMsgErro] = useState("");

  const [sending, setSending] = useState(false);
  const [search, setSearch] = useState("");

  const list = async () => {
    setMsgErro("");
    if (search.trim().length > 1) {
      setSending(true);

      const r = await RequestGet("/Students/List?search=" + search);

      if (r.Status === 200) {
        setStudents(r.Data);
        setSending(false);
        if (r.Data.length === 0) {
          setMsgErro(r.Message);
        }
      } else {
        setMsgErro(<Msg dados={r.errors} />);
      }
    }
  };

  const handleSearch = (e) => {
    //e.preventDefault();
    if (e.key === "Enter") {
      list();
    }
  };

  const handleNewStudent = (codA, idC) => {
    if (codA > 0 && idC !== "00000000-0000-0000-0000-000000000000") {
      setNewEdit(false);
      setSchoolId(idC);
      setStudentId(codA);
    } else setNewStudent(false);
    list();
  };

  return (
    <>
      <ul className="nav pt-3">
        <li className="nav-item text-primary nav-link ps-1">
          <h4>
            <strong>
              <FaUserGraduate /> Alunos
            </strong>
          </h4>
        </li>
      </ul>
      <Row>
        <Col sm={12}>
          <Card className="shadow">
            <Card.Body>
              <Row>
                <Col sm={12}>
                  <Row>
                    <Col sm={4} className="mb-3">
                      <InputGroup>
                        <Form.Control
                          type="text"
                          placeholder="Buscar"
                          aria-label="Buscar"
                          size="sm"
                          onKeyDown={handleSearch}
                          onChange={(e) =>
                            setSearch(e.target.value.toUpperCase())
                          }
                          value={search}
                          style={{ textTransform: "uppercase" }}
                          autoFocus
                        />
                        <Button variant="primary" size="sm" onClick={list}>
                          <FaSearch />
                        </Button>
                      </InputGroup>
                    </Col>
                    <Col sm={8} className="mb-3">
                      {sending && (
                        <Spinner
                          animation="border"
                          size="sm"
                          className="float-start mt-2"
                        />
                      )}
                      {msgErro !== "" && (
                        <span className="text-danger float-start mt-1">
                          {msgErro}
                        </span>
                      )}
                      <Button
                        variant="primary"
                        size="sm"
                        className="float-end"
                        onClick={() => {
                          setSchoolId(0);
                          setStudentId(0);
                          setNewStudent(true);
                          setNewEdit(true);
                        }}
                      >
                        <FaPlus />
                        &nbsp;Novo Aluno
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <Table striped hover size="sm">
                    <thead>
                      <tr>
                        <th scope="col" className="ps-2">
                          Colégio
                        </th>
                        <th scope="col">Cod. Aluno</th>
                        <th scope="col">Nome</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {students.map((a, index) => (
                        <tr key={index}>
                          <td className="ps-2">{a.SchoolCode}</td>
                          <td>{a.StudentId}</td>
                          <td>{a.Name}</td>
                          <td>
                            <Link
                              onClick={() => {
                                setSchoolId(a.SchoolId);
                                setStudentId(a.StudentId);
                                setNewStudent(true);
                                setNewEdit(false);
                              }}
                            >
                              <FaEye color="#003768" />
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {newStudent ? (
        <NewEdit
          show={newStudent}
          new={newEdit}
          studentId={studentId}
          schoolId={schoolId}
          handleNewStudent={handleNewStudent}
        />
      ) : null}
    </>
  );
};

export default Students;

import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { FaSave, FaSignOutAlt, FaUserFriends } from "react-icons/fa";
import {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalNotifications,
} from "../../components/modal";
import { toast } from "react-toastify";
import zModal from "../../functions";
import ReactInputMask from "react-input-mask";
import { RequestGet, RequestPost } from "../../requestApi";

const ParametersWitnessAlt = (props) => {
  const [name, setName] = useState("");
  const [docRg, setDocRg] = useState("");
  const [docCpf, setDocCpf] = useState("");
  const [openNot, setOpenNot] = useState(false);
  const [mensagem, setMensagem] = useState(null);
  const [txt, setTxt] = useState(null);
  const [error, setError] = useState(false);

  const closeModal = () => props.close(false);

  const closeNot = () => {
    if (error) closeModal();

    setOpenNot(false);
  };

  const saveDados = async () => {
    setOpenNot(true);
    setMensagem(null);
    setTxt("Salvando dados da Testemunha " + props.id);

    let erros = [];

    var c = 0;

    if (name.trim().length > 0) {
      if (name.trim().length < 2)
        erros.push(<p key={c++}>Nome deve ter ao menos 2 caracteres</p>);

      if (erros.length > 0) {
        setMensagem(erros);
        return;
      }
    }

    let dados = {
      Name: name,
      DocRg: docRg,
      DocCpf: docCpf,
    };

    const r = await RequestPost(
      "/Entities/Parameters/Witness/UpDate/" + props.id,
      dados
    );

    setMensagem(r.Title);
    if (r.Status === 200) {
      if (props.id === 1) {
        props.setData({
          ...props.data,
          Witness1: name,
          Witness1DocRg: docRg,
          Witness1DocCpf: docCpf.replace(/\D/g, ""),
        });
      } else if (props.id === 2) {
        props.setData({
          ...props.data,
          Witness2: name,
          Witness2DocRg: docRg,
          Witness2DocCpf: docCpf.replace(/\D/g, ""),
        });
      }
      toast.success(r.Title);
      setOpenNot(false);
      props.close(false);
    } else {
      toast.error(r.Title);
    }
  };

  const loadDados = async () => {
    setOpenNot(true);
    setMensagem(null);
    setTxt("Carregando dados da Testemunha " + props.id);
    const r = await RequestGet("/Entities/Parameters/Witness/" + props.id);

    setMensagem(r.Title);
    if (r.Status === 200) {
      toast.info(r.Title);
      setName(r.Data.Name);
      setDocRg(r.Data.DocRg);
      setDocCpf(r.Data.DocCpf);
      setOpenNot(false);
    } else {
      toast.error(r.message);
    }
  };

  useEffect(() => {
    loadDados();
  }, [props.show]);

  const [tl, setTl] = useState(1060);
  useEffect(() => {
    setTl(zModal());
  }, []);

  return (
    <>
      <Modal
        show={props.show}
        onHide={closeModal}
        backdrop="static"
        keyboard={false}
        size="md"
        style={{ zIndex: tl }}
      >
        <ModalHeader>
          <FaUserFriends /> Testemunha {props.id}: {name}
        </ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col sm={12} className="pb-2 ">
                <Form.Group controlId="nome">
                  <Form.Label className="mb-0">Nome</Form.Label>
                  <Form.Control
                    type="text"
                    size="sm"
                    maxLength={50}
                    value={name}
                    onChange={(e) => setName(e.target.value.toUpperCase())}
                    style={{ textTransform: "uppercase" }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6} className="pb-2 ">
                <Form.Group controlId="rg">
                  <Form.Label className="mb-0">RG</Form.Label>
                  <Form.Control
                    type="text"
                    size="sm"
                    maxLength={20}
                    onChange={(e) => setDocRg(e.target.value.toUpperCase())}
                    value={docRg}
                    style={{ textTransform: "uppercase" }}
                  />
                </Form.Group>
              </Col>
              <Col sm={6} className="pb-2 ">
                <Form.Group controlId="cpf">
                  <Form.Label className="mb-0">CPF </Form.Label>
                  <Form.Control
                    as={ReactInputMask}
                    type="text"
                    size="sm"
                    onChange={(e) => setDocCpf(e.target.value.toUpperCase())}
                    value={docCpf}
                    mask="999.999.999-99"
                    maskChar=""
                    style={{ textTransform: "uppercase" }}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button variant="secondary" size="sm" onClick={closeModal}>
            <FaSignOutAlt /> Sair
          </Button>
          <Button variant="primary" size="sm" onClick={saveDados}>
            <FaSave /> Salvar
          </Button>
        </ModalFooter>
      </Modal>
      {openNot ? (
        <ModalNotifications
          show={openNot}
          message={mensagem}
          close={closeNot}
          texto={txt}
        />
      ) : null}
    </>
  );
};

export default ParametersWitnessAlt;

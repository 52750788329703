import React, { useEffect, useState } from "react";
import { Breadcrumb } from "../../components";
import {
  Badge,
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { FaEye, FaSearch, FaUser, FaUserPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import UsersNewEdit from "./newedit";
import { RequestGet } from "../../requestApi";

const Users = () => {
  const [openNot, setOpenNot] = useState(false);
  const [txt, setTxt] = useState(null);
  const [msg, setMsg] = useState(null);
  const [title, setTitle] = useState("Novo Usuário");
  const [sending, setSending] = useState(false);
  const [search, setSearch] = useState("");
  const [usuarios, setUsuarios] = useState([]);
  const [newEdit, setNewEdit] = useState(false);
  const [id, setId] = useState(0);

  const list = async () => {
    setMsg(null);
    setSending(true);

    const r = await RequestGet("/Users/List?search=" + search);

    if (r.Status === 200) {
      setSending(false);
      setUsuarios(r.Data);
      // if (r.data.usuarios.length === 0) {
      //   setMsg(r.data.message);
      // }
    } else {
      setSending(false);
      setMsg("Erro: " + r?.status);
    }
    // if (search.trim().length > 1) {
    //   setSending(true);

    //   const r = await RequestApi.get("/Usuarios/List?search=" + search)
    //     .then((res) => res)
    //     .catch((err) => err?.response);

    //   if (r !== undefined && r.status === 200) {
    //     setSending(false);
    //     if (r.data.status === 0) {
    //       setMsg(r.data.message);
    //     }
    //     setUsuarios(r.data.usuarios);
    //     if (r.data.usuarios.length === 0) {
    //       setMsg(r.data.message);
    //     }
    //   } else {
    //     setSending(false);
    //     setMsg("Erro: " + r?.status);
    //   }
    // }
  };

  const handleSearch = (e) => {
    //e.preventDefault();
    if (e.key === "Enter") {
      list();
    }
  };

  useEffect(() => {
    list();
  }, []);
  return (
    <>
      <Breadcrumb title="Usuários" icon={<FaUser />} />
      <Row>
        <Col sm={12}>
          <Card className="shadow">
            <Card.Body>
              <Row>
                <Col sm={12}>
                  <Row>
                    <Col sm={4} className="mb-3">
                      <InputGroup>
                        <Form.Control
                          type="text"
                          placeholder="Buscar"
                          aria-label="Buscar"
                          size="sm"
                          onKeyDown={handleSearch}
                          onChange={(e) =>
                            setSearch(e.target.value.toUpperCase())
                          }
                          defaultValue={search}
                          autoFocus={true}
                          style={{ textTransform: "uppercase" }}
                        />
                        <Button variant="primary" size="sm" onClick={list}>
                          <FaSearch />
                        </Button>
                      </InputGroup>
                    </Col>
                    <Col sm={8} className="mb-3">
                      {sending && (
                        <Spinner
                          animation="border"
                          size="sm"
                          className="float-start mt-2"
                        />
                      )}
                      {msg !== null && (
                        <span className="text-danger float-start mt-1">
                          {msg}
                        </span>
                      )}
                      <Button
                        variant="primary"
                        size="sm"
                        className="float-end"
                        onClick={() => {
                          setNewEdit(true);
                          setTitle("Novo Usuário: ");
                          setId(0);
                        }}
                      >
                        <FaUserPlus />
                        &nbsp;Novo Usuário
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <Table striped hover size="sm">
                    <thead>
                      <tr>
                        <th scope="col" className="ps-2">
                          Nome
                        </th>
                        <th scope="col">Login</th>
                        <th scope="col">Tipo</th>
                        <th scope="col">Ativo</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {usuarios.map((u, index) => (
                        <tr key={index}>
                          <td className="ps-2">{u.Name}</td>
                          <td>{u.Login}</td>
                          <td>{u.Type}</td>
                          <td>
                            {u.IsActive ? (
                              <Badge bg="success">Sim</Badge>
                            ) : (
                              <Badge bg="danger">Não</Badge>
                            )}
                          </td>
                          <td>
                            <Link
                              onClick={() => {
                                setId(u.UserId);
                                setNewEdit(true);
                                setTitle("Usuário: ");
                              }}
                            >
                              <FaEye color="#003768" />
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {newEdit ? (
        <UsersNewEdit
          show={newEdit}
          close={setNewEdit}
          id={id}
          setId={setId}
          title={title}
          list={list}
        />
      ) : null}
    </>
  );
};

export default Users;
